import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Grid, Button } from '@material-ui/core';
import DialogAlertMain from "components/common/DialogAlertMain";
import LoadingButton from "@mui/lab/LoadingButton";


const SetupForm = ({ path, btnVisiblility, resetData, addOrdersWithNewCard }) => {
  const stripe = useStripe();
  const elements = useElements();
  // let navigate = useNavigate();

  const [backendCall, setBackendCall] = useState(false);

  const [errorMessage, setErrorMessage] = useState({ showAlert: false, severity: "success", message: "", });
  const [errorStatus, seterrorStatus] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(btnVisiblility){
      sessionStorage.setItem("newCardAdded", true)
      await addOrdersWithNewCard();
     }


    // We don't want to let default form submission happen here,
    // which would refresh the page.
    seterrorStatus(false);

    setBackendCall(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${path}`, // payment success url
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)

      setTimeout(() => {
        setErrorMessage({ showAlert: false, severity: "error", message: "", });
        sessionStorage.removeItem("newCardAdded");
      }, 4000);

      setTimeout(() => {
        seterrorStatus(true);
        setErrorMessage({showAlert: true, severity: "error", message: error.message,});
        sessionStorage.removeItem("newCardAdded");
      }, 500);
      seterrorStatus(false);


    } else {
      seterrorStatus(false);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        // spacing={1}
        alignContent='center'
        alignItems='center'
        justifyContent='center'
        direction='column'
      >
        <Grid container item alignItems='center' justifyContent='center' xs={12} md={12} lg={12}>
          <Grid
            item
            // style={{ alignContent: 'center' }}
            xs={12}
            sm={btnVisiblility ? 12 : 8}
            md={btnVisiblility ? 12 : 7}
            lg={btnVisiblility ? 12 : 7}
          >
            <PaymentElement id="card-element"/>
          </Grid>

        </Grid>
        {stripe && elements && (
          <Grid container item alignItems='center' justifyContent='center' direction="column" xs={12} sm={12} md={12} lg={12}>
            <Grid item xs={12} sm={12} md={12} lg={12}>


              <LoadingButton
                loading={!errorStatus && backendCall}
                sx={{
                  minWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "#2cca5c",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#2cca5c",
                    color: "white",
                  },
                }}
                variant="contained"
                disabled={!stripe}
                type="submit"
                size="large"
              >
                Submit
              </LoadingButton>
            </Grid>


            <Grid item xs={12} sm={12} md={12} lg={12}>

              <Button
                style={{ display: btnVisiblility ? "none" : "" }}
                sx={{
                  minWidth: "100%",
                  textAlign: "center",
                  backgroundColor: "#2cca5c",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#2cca5c",
                    color: "white",
                  },
                }}
                variant="text"
                onClick={() => resetData()}
              >
                Cancel
              </Button>
            </Grid>


            {/* Show error message to your customers */}
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "1rem", }}>

              {errorMessage && (
                // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
                <DialogAlertMain setAlert={setErrorMessage} alert={errorMessage} />
          // {alert.message} </Alert> 
        )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default SetupForm;
