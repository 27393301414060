import React from 'react'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main:{
    backgroundColor:"#EEEEEE"
  },
  title: {
    font: "Lato,sans-serif",
    fontSize: "2rem",
    color: "#35BFFF",
    textAlign:'center',
    fontWeight:'initial'
  },


  para: {
    font: "Lato,sans-serif",
    textAlign: "center",
    color: "#666",
    fontWeight:'bold',
    fontSize: "0.9rem",
  },

  email:{
    font: "Lato,sans-serif",
    fontSize: "0.9rem",
    color: "#6590A5",
    textAlign:'center',
    fontWeight:'bold',
  },

}));


export default function ServiceAlert({ setServicveAlert, servicveAlert }) {

  const classes = useStyles();

  return (

    <Dialog
      open={servicveAlert}
      onClose={() => {
        setServicveAlert(false)
      }
      }
      fullWidth={'sm'}
      maxWidth={'sm'}
      PaperProps={{
        style: {
          backgroundColor: "#EEEEEE",
          padding:"10px",
        },
      }}
      
    >
      <DialogTitle id='alert-dialog-title'
        className={classes.title}
       >We're not in your area yet 🥺</DialogTitle>

      <DialogContent>
        <DialogContentText
          id='alert-dialog-description'>
         <p className={classes.para}>I gotta get Chris to buy more trucks! Don't worry though, we'll shoot you an email as soon as we're up and running! And, as thanks for being patient, keep on a lookout for a couple bucks off your first load 😊</p>   
         <p className={classes.para}>
      Feel free to email{" "}
          <a href="mailto:general@lifewithoutlaundry.com" className={classes.email}>
            general@lifewithoutlaundry.com
          </a>
          {" "}if you think there may be an error or would like more information. Thanks for your interest!
        </p>
      </DialogContentText>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center", paddingBottom:'30px' }}>
        <Button
          onClick={() => {
            setServicveAlert(false)

          }
          }
          variant='contained'
          style={{
            backgroundColor:  "#195C79" ,
            color: "white",
          }}
        >
          Try Again
        </Button>
      </DialogActions>
    </Dialog>

  )
}
