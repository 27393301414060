import { Button, Typography, Modal } from "@material-ui/core";
// import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { ReactComponent as CalendarIcon } from "utils/assets/calendarIcon.svg";
import { ReactComponent as ClockIcon } from "utils/assets/clockIcon.svg";
import { useStyles } from "./style";



const SinglePayment = ({ payment }) => {

  const axios = useAxiosPrivate();

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [isWashDryFoldAvailable, setIsWashDryFoldAvailable] = useState(false);

  useEffect(() => {
    let washDryFoldPrice = parseFloat((orderDetails?.totalPrice + orderDetails?.discount) - (orderDetails?.processingFee + orderDetails?.specialCarePrice + orderDetails?.extra + calculateTotalExtra(orderDetails?.extraCharges))).toFixed(2) 
    if(washDryFoldPrice > 0){
      setIsWashDryFoldAvailable(true)
    }else{
      setIsWashDryFoldAvailable(false)
    }
  },[orderDetails])

  const calculateTotalExtra = (array) => {
    if(array?.length > 0){
          let totalExtra = 0;

    for (let i = 0; i < array.length; i++) {
      totalExtra += array[i].extra;
    }

     return totalExtra;

    }else{
        return 0;
    }

}

  const handleOpen = async (orderId) => {
    try {
      const { data } = await axios.get("/order/orderdetails/" + orderId)
      setOrderDetails(data)
      setOpen(true);

    } catch (err) {
      if (err.response.status === 403) {
        console.log(err)
      }

    }
  };

  const handleClose = () => {
    setOpen(false);
    setOrderDetails({});
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.dividerStyles}>
        <Table aria-label="simple table">
          <TableBody>

            <TableRow>

              <TableCell rowSpan={2} align="left">
                <Typography className={classes.dateStyles}>
                  {/* {monthName + " " + day + abbr + "," + year + " " + time} */}
                  Date : {new Date(payment?.createdAt).toLocaleDateString('en-US')}, 
                  {new Date(payment?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).split(' ')[0]}
                </Typography>

                <Typography className={classes.dateStyles}>
                  Reference : {payment.order.orderId}
                </Typography>
              </TableCell>

              <TableCell rowSpan={2} align="right">
                <Typography className={classes.paymentStyle}>
                  ${Number(payment?.amount ? payment?.amount : 0).toFixed(2)}
                </Typography>
              </TableCell>


            </TableRow>
            {/* <TableRow>
              <TableCell rowSpan={2} align="center">
                <Button className={classes.viewbtn} onClick={() => handleOpen(payment.order.orderId)} >View Order</Button>
              </TableCell>
            </TableRow> */}

          </TableBody>
        </Table>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell rowSpan={2} align="center">
                <Button className={classes.viewbtn} onClick={() => handleOpen(payment.order.orderId)} >View Order</Button>
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{ classes: { root: classes.backdrop } }}
        classes={classes.modal}
      >
        <div className={classes.modalWrapper}>
        <div className={classes.modalContent}>
          <div className={classes.modalHeading} id="alert-dialog-title">Order Details - {orderDetails.orderId}</div>
          <div className={classes.invoiceSec}>
            <table className={classes.table}>
              <thead>
                <tr className={classes.tableHeader}>
                  <th className={classes.tableHeaderCell}>Reason</th>
                  <th className={classes.tableHeaderCell}>Amount</th>
                </tr>
              </thead>
              <tbody>
                {
                  isWashDryFoldAvailable && 
                  <tr className={classes.tableRow}>
                  <td className={classes.tableCell}>Wash/Dry/Fold</td>
                  <td className={classes.tableCell}>${orderDetails.servicePrice ? parseFloat( (orderDetails?.totalPrice + orderDetails?.discount) - (orderDetails?.processingFee + orderDetails?.specialCarePrice + orderDetails?.extra + calculateTotalExtra(orderDetails?.extraCharges))).toFixed(2) : 0}</td>
                </tr>
                }
                {
                  orderDetails?.specialCarePrice > 0 &&
                <tr className={classes.tableRow}>
                  <td className={classes.tableCell}>Special Care Price</td>
                  <td className={classes.tableCell}>${orderDetails.specialCarePrice ? parseFloat(orderDetails.specialCarePrice).toFixed(2) : 0}</td>
                </tr>
                }
                <tr className={classes.tableRow}>
                  <td className={classes.tableCell}>Processing Fee</td>
                  <td className={classes.tableCell}>${orderDetails.processingFee ? parseFloat(orderDetails.processingFee).toFixed(2) : 0}</td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.tableCell}>Something Extra</td>
                  <td className={classes.tableCell}>${orderDetails.extra === 0 ? 0 : parseFloat(orderDetails.extra).toFixed(2)}</td>
                </tr>
                <tr className={classes.tableRow}>
                  <td className={classes.tableCell}>Reward Discount</td>
                  <td className={classes.tableCell}>${orderDetails.discount ? parseFloat(orderDetails.discount).toFixed(2) : 0}</td>
                </tr>
                {
                orderDetails.extraCharges &&
                orderDetails.extraCharges.map((item) => { 
                  const reasonCapitalize = item.reason.charAt(0).toUpperCase() + item.reason.slice(1);
                 return <tr className={classes.tableRow}>
                    <td className={classes.tableCell}>{reasonCapitalize}</td>
                    <td className={classes.tableCell}>${item.extra ? parseFloat(item.extra).toFixed(2) : 0}</td>
                  </tr>
                })
               }
              </tbody>
              <thead>
                <tr className={classes.tableRow}>
                  <th className={classes.tableSubCell}>Subtotal</th>
                  <th className={classes.tableSubCell}>${parseFloat(orderDetails.totalPrice).toFixed(2)}</th>
                </tr>
              </thead>
            </table>
            <div className={classes.row}>
              <div className={classes.title}>Pickup: </div>
              <div style={{display:'flex', flexWrap:'wrap', marginTop:'12px'}}>
              <div className={classes.detail}><CalendarIcon className={classes.icoCal}/>{orderDetails.pickupDate}</div>
              <div className={classes.detailClock}><ClockIcon className={classes.icoClo}/>{orderDetails.pickupTimeSlot}</div>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.title}>Delivery: </div>
              <div style={{display:'flex', flexWrap:'wrap', marginTop:'12px'}}>
              <div className={classes.detail}><CalendarIcon className={classes.icoCal}/>{orderDetails.diliverDate}</div>
              <div className={classes.detailClock}><ClockIcon className={classes.icoClo}/>{orderDetails.diliverTimeSlot}</div>
              </div>
            </div>
            {orderDetails.specialDate && 
            <div className={classes.row}>
              <div className={classes.title} style={{width:'150px'}}>Special Care: </div>
              <div style={{display:'flex', flexWrap:'wrap', marginTop:'12px'}}>
              <div className={classes.detail}><CalendarIcon className={classes.icoCal}/>{orderDetails.specialDate}</div>
              <div className={classes.detailClock}><ClockIcon className={classes.icoClo}/>{orderDetails.specialTimeSlot}</div>
              </div>
            </div> }
            <div className={classes.rowAdd}>
              <div className={classes.title}>Address:</div>
              <div className={classes.addressDetails}>{orderDetails.orderAddress}</div>
            </div>           
            {orderDetails.orderNote &&
              <div className={classes.column}>        
               <div className={classes.title}  style={{width:'150px'}}>Laundry Note: </div>
                <div className={classes.pickupDetail}>{orderDetails.orderNote}</div>
              </div>
            }
            {orderDetails.pickupNote &&
              <div className={classes.column}>        
               <div className={classes.title}  style={{width:'150px'}}>Pickup Note: </div>
                <div className={classes.pickupDetail}>{orderDetails.pickupNote}</div>
              </div>

            }
            {orderDetails.deliveryNote &&

              <div className={classes.column2}>        
              <div className={classes.title} style={{width:'150px'}}>Delivery Note: </div>
               <div className={classes.pickupDetail}>{orderDetails.deliveryNote}</div>
             </div>
            }

          </div>

          <div className={classes.rowBtn}>
            <Button className={classes.closeBtn} onClick={handleClose}>Close</Button>
          </div>
        </div>
        </div>
      </Modal>
    </>
  );
};

export default SinglePayment;
