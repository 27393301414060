import axiosInstance from "../lib/axios";
import { useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";

const useAxiosPrivate = () => {
    const dispatch = useDispatch();
    axiosInstance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            if (error?.response?.status === 401 && error?.response?.data === "jwt expired") {
                dispatch(logout());
                window.location.href = "/";
            }
            return Promise.reject(error);
        }
    );
    return axiosInstance;
};

export default useAxiosPrivate;
