import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";




const useStyles = makeStyles((theme) => ({
  foot: {
    height: "7rem",
    lineHeight: "1.4rem",
    color: "#84A1B5",
    borderTop: "1px solid #BCDCF2",
    backgroundColor: "white",
    marginTop: '5rem',
    position: "relative",
    // top: "15vh",
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      height: "12rem",
    },
  },

  navAlign: {
    textAlign: "center",
    fontSize: "1rem",
  },

  text: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      // fontSize: "0.9rem",
      paddingBottom: "10px",
    },
  },

  hideOnXS: {
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  showOnXS: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  // gridCenter: {
  //   display: "flex",
  //   justifyContent: "center",
  // },
  // divstructure: {
  //   display: "flex",
  //   justifyContent: "space-around",
  //   width: "50%",
  //   [theme.breakpoints.down("xs")]: {
  //     flexDirection: "column",
  //     alignItems: "center",
  //     width: "100%",
  //   },
  // },
  removeSpace: {
    textDecoration: "none",
    color: "#84A1B5",
    backgroundColor: "#FFFFFF",
    border: "none",
    padding: "1rem",
    cursor: "pointer",
    fontSize:"1rem",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "0.9rem",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: "1rem",
      paddingRight: "1rem",

    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
}));

 
export default function Footer() {
  const classes = useStyles(); 
  let token = useSelector((state) => state.auth.accessToken); 

  //eslint-disable-next-line
  const [zipCode, setZipCode] = useState("");

  let defaultZipCode =
    useSelector((state) => state.auth.defaultZipCode) ||
    sessionStorage.getItem("defaultZipCode");

  useEffect(() => {
    setZipCode(defaultZipCode);
  }, [defaultZipCode]);


  const redirectContact = () => {
    let path;

    if (token) {
      path =
        `${process.env.REACT_APP_CONTACT}?q=${zipCode}&token=${token}` ||
      `https://qa.lwls.live/contact-us?q=${zipCode}&token=${token}`;
      // path = `https://qa.lwls.live/contact-us?token=${token}`;
    } else {
      path = `${process.env.REACT_APP_CONTACT}`;
    }

    window.location.assign(path);
  };


  const redirectTerms = () => {
    let path;

    if (token) {
      path =
        `${process.env.REACT_APP_TERMS}?q=${zipCode}&token=${token}` ||
      `https://qa.lwls.live/terms-and-conditions?q=${zipCode}&token=${token}`;
      // path = `https://qa.lwls.live/terms-and-conditions?token=${token}`;
    } else {
      path = `${process.env.REACT_APP_TERMS}`;
    }

    window.location.assign(path);
  };
  

  const redirectAbout = () => {
    let path;

    if (token) {
      path =
        `${process.env.REACT_APP_ABOUT}?q=${zipCode}&token=${token}` ||
      `https://qa.lwls.live/about?q=${zipCode}&token=${token}`;
      // path = `https://qa.lwls.live/about?token=${token}`;
    } else {
      path = `${process.env.REACT_APP_ABOUT}`;
    }

    window.location.assign(path);
  };

  



  return (

    <Grid container className={classes.foot} >

      <Grid container item justifyContent="center" alignItems="center" alignContent="center" >

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <p className={classes.text}>
            © {new Date().getFullYear()} Life Without Laundry, All Rights Reserved.
          </p>
        </Grid>

        {/* Start LG to SM devices footer menu */}

        {/* <Grid className={classes.hideOnXS} item lg={6} md={6} sm={12} xs={12}>
          <nav className={classes.navAlign}>
            <button onClick={redirectAbout}
              className={classes.removeSpace} > About </button>

            <button onClick={redirectTerms}
              className={classes.removeSpace} > {" Terms & Conditions"} </button>

            <button onClick={redirectContact} className={classes.removeSpace}> Contact</button>
          </nav>
        </Grid> */}
        {/* END LG to SM devices footer menu */}

        {/* Start XS devices footer menu */}
        {/* <Grid className={classes.showOnXS} item lg={6} md={6} sm={12} xs={12}>
          <nav className={classes.navAlign}>
            <button onClick={redirectAbout}
              className={classes.removeSpace} > About </button><br></br>

            <button onClick={redirectTerms}
              className={classes.removeSpace} > {" Terms & Conditions"} </button><br></br>

            <button onClick={redirectContact} className={classes.removeSpace}> Contact</button>
          </nav>
        </Grid> */}
        {/* END XS devices footer menu */}

      </Grid>


    </Grid>
  );
}
