import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import BeenhereIcon from '@mui/icons-material/Beenhere';

const itemList = [
    {
      id: 1,
      name: 'Free Pick-up and Next Day Delivery',
      image: `/washDryFoldImages/free-pick-up-next-day-delivery.jpg`,
      description: 'Free pick-up and NEXT DAY delivery'
    },
    {
      id: 2,
      name: 'Proprietary cold wash technology',
      image: `/washDryFoldImages/proprietary-cold-wash-technology.jpg`,
      description: 'Proprietary cold wash technology'
    },
    {
      id: 3,
      name: 'Separation of Colors and Whites',
      image: `/washDryFoldImages/separation-of-colors-and-whites.jpg`,
      description: 'Separation of Colors and Whites'
    },
    {
      id: 4,
      name: 'Chlorine Free, Free & Clear Bleach for whites',
      image: `/washDryFoldImages/Chlorine-Free-clear-bleach-for-whites.jpg`,
      description: 'Chlorine Free, Free & Clear Bleach for whites'
    },
    {
      id: 5,
      name: 'Professional Folding',
      image: `/washDryFoldImages/professional-folding.jpg`,
      description: 'Professional Folding'
    },
    {
      id: 6,
      name: 'Delicate wash',
      image: `/washDryFoldImages/delicate-wash.jpg`,
      description: 'Delicate wash'
    },
    {
      id: 7,
      name: 'Special Formulated Cold Wash Detergent',
      image: `/washDryFoldImages/special-formulated-cold-wash-detergent.jpg`,
      description: 'Special Formulated Cold Wash Detergent'
    },
    {
      id: 8,
      name: 'Cold Wash Fabric softener',
      image: `/washDryFoldImages/cold-wash-fabric-softener.jpg`,
      description: 'Cold Wash Fabric softener'
    },
    {
      id: 9,
      name: 'Free and Clear dryer sheets',
      image: `/washDryFoldImages/free-and-clear-dryer-sheets.jpg`,
      description: 'Free and Clear dryer sheets'
    },
    {
        id: 9,
        name: 'Custom Detergent / Softener Option (must send out with order) Individual garment Stain Removal',
        image: `/washDryFoldImages/custom-detergent-stain-removal.jpg`,
        description: 'Custom Detergent / Softener Option (must send out with order) Individual garment Stain Removal'
    },
    {
        id: 9,
        name: 'In-wash Detergent Booster (more CLEANING POWER)',
        image: `/washDryFoldImages/in-wash-detergent-booster.jpg`,
        description: 'In-wash Detergent Booster (more CLEANING POWER)'
      },
  ];

const ItemList = () => {
  return (
    <Grid container spacing={3}>
      {itemList.map(item => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={item.id}>
          <Card style={{ height: '100%' }}>
            <CardMedia image={item.image} title={item.name} style={{ height: 0, paddingTop: '56.25%' }} />
            <CardContent style={{ height: '100%', overflow: 'hidden' }}>
                      < BeenhereIcon
                        color='success'
                          style={{
                                textAlign: 'center',
                                fontSize: "2rem",
                                marginBottom: "1rem",
                            }}/>
              <Typography variant="body2">{item.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default ItemList;
