import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import FlagIcon from "@mui/icons-material/Flag";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PaymentIcon from "@mui/icons-material/Payment";
import { makeStyles } from "@material-ui/core/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RewardpointBOx from './rewardPoints/rewardPointsBox';
import { useDispatch } from 'react-redux';
import { reset } from '../../store/actions/orderAction';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

  topSideNavBtnSection: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20rem",
      minWidth: "20rem",
      // display: "none",
    },
    [theme.breakpoints.down(325)]: {
      maxWidth: "15rem",
      minWidth: "15rem",
    },
  },

  rewardModule: {
    color: "white",
    borderRadius: "0.5rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20rem",
      minWidth: "20rem",
    },
    [theme.breakpoints.down(325)]: {
      maxWidth: "15rem",
      minWidth: "15rem",
    },
  },

}));

const StyledButton = styled(Button)({
  width: "100%",
  backgroundColor: "#35bfff",
  textTransform: "none",
  textAlign: "left",
  justifyContent: "left",

  "&:hover": {
    backgroundColor: "#00a8f6",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#00a8f6",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const OrderButton = styled(Button)({
  width: "100%",
  color: "#fff",
  padding: "1rem",
  backgroundColor: "#2cca5c",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#23a24a",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function SideNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [value, setValue] = useState(false);

  const DirectToOrder = () => {
    dispatch(reset());
    navigate("/order");
  };



  useEffect(() => {

    setValue(location.state?.value);

  }, [location.state?.value]);

  return (
    <Grid container alignItems="center"
      spacing={1}
      className={classes.root}
      justifyContent="center"
    >
      <Grid container item className={classes.topSideNavBtnSection} spacing={1}>

        <Grid item xs={12} md={12} >
          <OrderButton
            variant="contained"
            color="success"
            onClick={() => DirectToOrder()}
            startIcon={<ShoppingCartIcon />}
            data-testid="start-order-nav-btn"
          >
            Start an Order!
          </OrderButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => navigate("/myaccount/upcoming")}
            startIcon={<PersonIcon />}
          >
            Upcoming Services
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => navigate("/myaccount/completedservices")}
            startIcon={<FlagIcon />}
          >
            Completed Services
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => navigate("/myaccount/mywallet")}
            startIcon={<AccountBalanceWalletIcon />}
          >
            My Wallet
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => navigate("/myaccount/manage")}
            startIcon={<SettingsApplicationsIcon />}
          >
            My Account Details
          </StyledButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            variant="contained"
            color="primary"
            onClick={() => navigate("/myaccount/paymenthistory")}
            startIcon={<PaymentIcon />}
          >
            Payment History
          </StyledButton>
        </Grid>
      </Grid>

      {/*Start Reward Module */}
      <RewardpointBOx />
      {/*END Reward Module */}

    </Grid>


  );
}
