import React, { useState } from "react";
import { Grid, Card, CardContent, CardActions, Button, InputAdornment, IconButton, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate"; 
import DialogAlertMain from "components/common/DialogAlertMain";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  gridMargin: { marginTop: "12rem", marginBottom: "1rem",
    [theme.breakpoints.down("md")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("sm")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("xs")]: { marginTop: "10rem", marginBottom: "0rem", },
  },

  fieldWidth: { width: "25vw",
    [theme.breakpoints.down("md")]: { width: "35vw", },
    [theme.breakpoints.down("sm")]: { width: "50vw", },
    [theme.breakpoints.down("xs")]: { width: "70vw", },
  },

  center: { textAlign: "center", color: "#35BFFF", fontSize: "2em", boxShadow: "5px", fontWeight: "300",
    lineHeight: "1em", marginTop: 0, marginBottom: "1rem", fontFamily: '"Lato",sans-serif', },
  padding: {
    paddingTop: "5rem", paddingBottom: "5rem", width: "42vw", height: "auto", boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: { width: "65vw", },
    [theme.breakpoints.down("sm")]: { width: "70vw", },
    [theme.breakpoints.down("xs")]: { width: "90vw", }, },
  button: { backgroundColor: "#2cca5c", color: "white", width: "100%",
    "&:hover": { backgroundColor: "#2cca5c", color: "white", }, },
}));

let initialValues = { currentPassword: "", newPassword: "", confirmPassword: "", };
let SignUpSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required!')
      .min(8, 'Min length for the Password is 8'),
    newPassword: Yup.string()
      .required('New Password is required!')
      .matches(/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/,
        "One Uppercase, One Lowercase, One Number,one special case Character and Must Contain 8 Characters")
      .min(8, "Must Contain 8 Characters"),
    confirmPassword: Yup.string()
      .required('Confirm Password is required!')
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});
  
function ChangePassword() {
  const axios = useAxiosPrivate();
  const classes = useStyles();
  const [currentPass, setCurrentPass] = useState(false);
  const [newPass, setNewPass] = useState(false);
  const [consfirmPass, setConfirmPass] = useState(false);

  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  let navigate = useNavigate();
  const handleMouseDownPassword = (event) => { event.preventDefault(); };
  const submit = async (e) => {
    try {
      await axios.put("/auth/change-password", e);
      setAlert({ showAlert: true, severity: "success", message: "Password Changed Successfully", });
      setTimeout(() => { setAlert({ showAlert: false, severity: "Password Changed Successfully", message: "", }); }, 3000);
      navigate("/myaccount/manage");
    } catch (error) {
      if (error.response.status === 401) { setAlert({ showAlert: true, severity: "error", message: "Unauthorized!", }); }
      else if (error.response.status === 501) { setAlert({ showAlert: true, severity: "error", message: "You are temporary block. Please contact your administrator!", }); }
      else if (error.response.status === 403) { setAlert({ showAlert: true, severity: "error", message: "Current password is wrong!", }); }
      else { setAlert({ showAlert: true, severity: "error", message: error.response.data ?? "Server error!", }); }
    }
  };

  return (
    <Grid container className={classes.gridMargin} lg={12} justifyContent="center" alignItems="center" >
      <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center">
        <Card className={classes.padding} variant="outlined" sx={{ boxShadow: 3, }} >
          <Grid container alignItems="center" justifyContent="center">
            <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center">
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="h1" className={classes.center}>
                  Change Password
                </Typography>
              </Grid>
              <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} >
                {({ dirty, isValid }) => {
                  return (
                    <Form> 
                      <CardContent> 
                        <Grid item lg={12} md={12} sm={12}>
                          <Field required className={classes.fieldWidth} name="currentPassword" label="Current Password"
                            component={TextField} variant="outlined" fullWidth margin="dense" type={currentPass ? "text" : "password"}
                            InputProps={{ style: { fontFamily: "Overpass, sans-serif" },
                              endAdornment: ( <InputAdornment position="end">
                                  <IconButton onClick={() => setCurrentPass(!currentPass)} onMouseDown={handleMouseDownPassword} >
                                    {currentPass ? ( <Visibility /> ) : ( <VisibilityOff /> )}
                                  </IconButton>
                                </InputAdornment> ), }} ></Field>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                          <Field required className={classes.fieldWidth} name="newPassword" label="New Password" component={TextField} variant="outlined"
                            fullWidth margin="dense" type={newPass ? "text" : "password"}
                            InputProps={{ style: { fontFamily: "Overpass, sans-serif" },
                              endAdornment: ( <InputAdornment position="end">
                                  <IconButton onClick={() => setNewPass(!newPass)} onMouseDown={handleMouseDownPassword} >
                                    {newPass ? ( <Visibility /> ) : ( <VisibilityOff /> )}
                                  </IconButton>
                                </InputAdornment> ), }}
                          ></Field>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                          <Field className={classes.fieldWidth} name="confirmPassword" label="Confirm Password" component={TextField}
                            variant="outlined" fullWidth margin="dense" type={consfirmPass ? "text" : "password"} required
                            InputProps={{ style: { fontFamily: "Overpass, sans-serif" },
                              endAdornment: ( <InputAdornment position="end">
                                  <IconButton onClick={() => setConfirmPass(!consfirmPass) } onMouseDown={handleMouseDownPassword} >
                                    {consfirmPass ? ( <Visibility /> ) : ( <VisibilityOff /> )}
                                  </IconButton>
                                </InputAdornment> ), }} ></Field>
                        </Grid>
                      </CardContent>
                      <Grid item lg={12} md={12} sm={12}>
                        <CardActions>
                          <Button variant="contained" className={classes.button} disabled={!dirty || !isValid} type="submit" size="large" >
                            Change Password </Button>
                        </CardActions>
                      </Grid>
                    </Form> ); }}
              </Formik>
            </Grid>
          </Grid>
        </Card>
      </Grid >
      <Grid container item alignItems="center" justifyContent="center" lg={12} md={12} sm={12} style={{ marginTop: "1rem" }}>
        {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
      </Grid>
    </Grid >
  );
}
export default ChangePassword;
