import React, { useEffect,useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import DialogAlertMain from "components/common/DialogAlertMain";
import MyAccount from "screens/myAccount/MyAccount";
import { makeStyles } from "@material-ui/core/styles";
import CompletedS from "./Completed";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
// import SpecialCare from "./Special";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "0rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  headerTypo: {
    color: "#457FC2",
    fontWeight: "700",
    fontSize: "1.2rem",
    fontFamily: '"Lato",sans-serif',
    marginBottom: "1rem",
  },

  headerTypo2: {
    color: "#457FC2",
    fontWeight: "700",
    fontSize: "1.2rem",
    fontFamily: '"Lato",sans-serif',
    marginBottom: "2rem",
    marginTop: "2rem",
  },
}));

export default function Completed() {
  const classes = useStyles();
  const axios = useAxiosPrivate();
  const [loadingData, setLoadingData] = useState(false);

  const [orders, setOrders] = React.useState([]);
  // const [specialCare, setSpecialCare] = React.useState([]);
  // const [normal, setNormal] = React.useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const getData = async () => {
    setLoadingData(true)
    try {
      const { data } = await axios.get("/customer/completed-services");

      setOrders(data);
      setLoadingData(false)
      // const special = [];
      // const normal = [];

      // data.forEach((element) => {
      //   if (element?.specialDate) {
      //     special.push(element);
      //   } else {
      //     normal.push(element);
      //   }

      //   setSpecialCare(special);
      //   setNormal(normal);
      // });
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Something went wrong",
          message: "",
        });
      }, 3000);
    }
  };

  const isTrackOrderBtnClicked = async () => {
    await getData();
  }

  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <MyAccount>
      <Grid container >
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" className={classes.headerTypo}>
            Completed Services
          </Typography>
          <CompletedS 
            completedS={orders}
            loadingData={loadingData}
            isTrackOrderBtnClicked={isTrackOrderBtnClicked}
           />
        </Grid>
      </Grid>
      {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
    </MyAccount>
  );
}
