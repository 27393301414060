import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TemporaryDrawer from "./Drawer";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import AlertBanner from "components/common/AlertBanner";
import { ReactComponent as Home } from "../../../src/utils/assets/home.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margineBottom: "1rem",
  },
  title: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
  },
  showbar: {
    [theme.breakpoints.down(960)]: {
      display: "none",
    },
  },
  showbar2: {
    display: "none",
    [theme.breakpoints.down(960)]: {
      display: "block",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  buton1: {
    background: "#0073A8",
    marginRight: 7,
    marginLeft: 10,
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.4rem",
    fontFamily: '"Lato",  sans-serif',
    "&:hover": {
      backgroundColor: "#035a82",
      opacity: "0.9",
      color: "#fff",
    },
  },
  buton2: {
    background: "#0073A8",
    marginRight: 7,
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
    "&:hover": {
      backgroundColor: "#035a82",
      opacity: "0.9",
      color: "#fff",
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    textDecoration: "none",
    color: "white",
    textTransform: "capitalize",
    fontSize: "1rem",
    lineHeight: "1.4rem",
    fontWeight: "400",
    fontFamily: '"Lato",  sans-serif',
    "&:hover": {
      backgroundColor: "#28394a",
      opacity: "0.9",
      color: "#fff",
    },
  },
  img1: {
    maxWidth: "60px",
    objectFit: "contain",
    // marginLeft: "7rem",
  },
  img2: {
    maxWidth: "270px",
    maxHeight: "30.17px",
    objectFit: "contain",
  },
}));

export default function NavBar() {

  let dispatch = useDispatch();
  let token = useSelector((state) => state.auth.accessToken);
  let navigate = useNavigate();

  const classes = useStyles();

  //eslint-disable-next-line
  const [zipCode, setZipCode] = useState("");
  const [migrateBanner, setMigrateBanner] = useState({
    defaultAddress: "",
    lastName: "",
    firstName: "",
    defaultZipCode: "",
    phone: "",
    isPaymentAvailable:""
  });
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

  let accessToken =
    useSelector((state) => state.auth.accessToken) ||
    sessionStorage.getItem("accessToken");

  let defaultAddress =
    useSelector((state) => state.auth.defaultAddress) ||
    sessionStorage.getItem("defaultAddress");

  let lastName =
    useSelector((state) => state.auth.lastName) ||
    sessionStorage.getItem("lastName");

  let firstName =
    useSelector((state) => state.auth.firstName) ||
    sessionStorage.getItem("firstName");

  let defaultZipCode =
    useSelector((state) => state.auth.defaultZipCode) ||
    sessionStorage.getItem("defaultZipCode");

  let phone =
    useSelector((state) => state.auth.phone) ||
    sessionStorage.getItem("phone");

  let isPaymentAvailable =
    useSelector((state) => state.auth.isPaymentAvailable) ||
    sessionStorage.getItem("isPaymentAvailable");
  //check if customer did update the info when migrating
  useEffect(() => {
    if (accessToken) {
      !defaultAddress || defaultAddress === "undefined" ? setMigrateBanner(oldVal => ({ ...oldVal, defaultAddress: "Default Address, " })) : setMigrateBanner(oldVal => ({ ...oldVal, defaultAddress: "" }))
      !lastName || lastName === "undefined" ? setMigrateBanner(oldVal => ({ ...oldVal, lastName: "Last Name, " })) : setMigrateBanner(oldVal => ({ ...oldVal, lastName: "" }))
      !firstName || firstName === "undefined" ? setMigrateBanner(oldVal => ({ ...oldVal, firstName: "First Name, " })) : setMigrateBanner(oldVal => ({ ...oldVal, firstName: "" }))
      !defaultZipCode || defaultZipCode === "undefined" ? setMigrateBanner(oldVal => ({ ...oldVal, defaultZipCode: "Default ZipCode, " })) : setMigrateBanner(oldVal => ({ ...oldVal, defaultZipCode: "" }))
      !phone || phone === "undefined" ? setMigrateBanner(oldVal => ({ ...oldVal, phone: "Phone Number, " })) : setMigrateBanner(oldVal => ({ ...oldVal, phone: "" }))
      isPaymentAvailable===false || isPaymentAvailable==="false" ? setMigrateBanner(oldVal => ({ ...oldVal, isPaymentAvailable: "Payment Method, " })) : setMigrateBanner(oldVal => ({ ...oldVal, isPaymentAvailable: "" }))
    }

  }, [defaultAddress, lastName, firstName, defaultZipCode, phone, accessToken, isPaymentAvailable])

  useEffect(() => {
    if (migrateBanner.defaultAddress === "Default Address, " ||
        migrateBanner.defaultZipCode === "Default ZipCode, " ||
        migrateBanner.firstName === "First Name, " ||
        migrateBanner.lastName === "Last Name, " ||
        migrateBanner.isPaymentAvailable === "Payment Method, " ||
      migrateBanner.phone === "Phone Number, ") {
      
      setAlert({ showAlert: true, severity: "error", message: migrateBanner, });
    } else {
      setAlert({ showAlert: false, severity: "error", message: "", });
    }
  }, [migrateBanner])

  useEffect(() => {
    setZipCode(defaultZipCode);
  }, [defaultZipCode]);

  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const redirectAbout = () => {
    let path;

    if (token) {
      path =
        `${process.env.REACT_APP_ABOUT}?q=${zipCode}&token=${token}` ||
        `https://qa.lwls.live/about?q=${zipCode}&token=${token}`;
      // path = `https://qa.lwls.live/about?token=${token}`;
    } else {
      path = `${process.env.REACT_APP_ABOUT}`;
    }

    window.location.assign(path);
  };

  const redirectServices = () => {
    let path;

    if (token) {
      path =
        `${process.env.REACT_APP_SERVICES}?q=${zipCode}&token=${token}` ||
        `https://qa.lwls.live/services?q=${zipCode}&token=${token}`;

      // path = `http://localhost:3000/services?q=${zipCode}&token=${token}`;
    } else {
      path =
        `${process.env.REACT_APP_SERVICES}` || "https://qa.lwls.live/services";
    }

    window.location.assign(path);
  };

  const redirectFaq = () => {
    let path;
    if (token) {
      // path = `http://localhost:3000/faq?token=${token}`;
      path =
        `${process.env.REACT_APP_FAQ}?q=${zipCode}&token=${token}` ||
        `https://qa.lwls.live/faq?q=${zipCode}&token=${token}`;
    } else {
      path = process.env.REACT_APP_FAQ || "https://qa.lwls.live/faq";
    }

    window.location.assign(path);
  };

  //redirect to HOMEPAGE
  const redirectHome = () => {
    let path;
    if (token) {
      path =
        `${process.env.REACT_APP_HOME}?q=${zipCode}&token=${token}` ||
        `https://qa.lwls.live/?q=${zipCode}&token=${token}`;
    } else {
      path = process.env.REACT_APP_HOME || "https://qa.lwls.live";
    }

    window.location.assign(path);
  };



  return (
    <div className={classes.root}>
      <AppBar position="fixed" style={{ backgroundColor: "#101d27" }}>
        <Toolbar>
          <Grid container alignItems="center" className={classes.showbar2}>
            <Grid container lg={12} item sm={12} xs={12} >
              <Grid item sm={2} xs={2} className={classes.item}>
                <TemporaryDrawer />
              </Grid>
              <Grid item sm={8} xs={8} className={classes.center}>
                <img
                  className={classes.img1}
                  src="/logo-lwl.svg"
                  alt="Life Without Laundry Logo"
                  onClick={redirectHome}
                  style={{ cursor: "pointer", }}

                />
              </Grid>

            </Grid>
          </Grid>
          <Grid container alignItems="center" className={classes.showbar}>
            <Grid item lg={6} md={5} sm={5} >
              <div className={classes.center}>
                <img
                  className={classes.img2}
                  src="/logo-main.svg"
                  alt="Life Without Laundry Logo"
                  onClick={redirectHome}
                  style={{ cursor: "pointer", }}
                />
              </div>
            </Grid>
            <Grid item lg={6} md={7} sm={7} className={classes.center} >
              <>
                <Button className={classes.btn} onClick={() => navigate('/about')}>
                  About
                </Button>
                <Button className={classes.btn} onClick={() => navigate('/services')}>
                  Services
                </Button>
                <Button className={classes.btn} onClick={() => navigate('/faq')}>
                  FAQ
                </Button>
                <Button className={classes.btn} onClick={() => navigate('/contact-us')}>
                  Contact
                </Button>
                {token ? (
                  <>
                    <Button
                      className={classes.buton1}
                      onClick={() => navigate("/myaccount")}
                    >
                      <Home style={{margin:"0px 5px 1px 0px"}}/>
                       Home
                    </Button>
                    <Button className={classes.buton1} onClick={onLogout}>
                      <PowerSettingsNewIcon /> Logout
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className={classes.buton1} onClick={() => navigate('/')}> Login</Button>

                    <Button
                      className={classes.buton2}
                      onClick={() => navigate("/signup")}
                    >
                      {" "}
                      Signup
                    </Button>
                  </>
                )}
              </>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {alert.showAlert && accessToken && <Grid item lg={12} md={12} sm={12} xs={12} >
        <AlertBanner setAlert={setAlert} alert={alert} />
      </Grid>}
    </div>
  );
}
