import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

  borderShow: { 
    borderStyle: 'ridge',
    borderRight: "0px",
    borderBottom: "0px",
    [theme.breakpoints.down('xs')]: {
      borderLeft: "0px",
    },
  },

  parad: {
    textAlign: 'center',
    padding: '0.8rem',
    fontSize: '1rem',
    lineHeight: '1.37rem',
    color: '#666',
    fontWeight: '400',
  },
  contain: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));
