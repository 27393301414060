
import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Grid, Card, CardContent, CardHeader } from "@material-ui/core";
import { useNavigate, useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

    button: { background: "#35BFFF", marginBottom: "2rem", color: "#FFFFFF", },
    title: {
        fontFamily: "Lato,sans-serif", textAlign: "left", fontSize: "2rem", lineHeight: "1em", fontWeight: "300",
        background: "#35BFFF", color: "#FFFFFF", paddingLeft: "1rem",
        [theme.breakpoints.down("sm")]: { fontSize: "1.6rem", },
        [theme.breakpoints.down("xs")]: { fontSize: "1.6rem", },
    },
    img: {
        maxWidth: "13rem", position: "absolute", right: "2rem", top: "7rem",
        [theme.breakpoints.down("sm")]: { position: "initial", right: "2rem", top: "7rem", },
    },
    hideOnSm: {
        display: "flex",
        [theme.breakpoints.down("sm")]: { display: "none", },
    },
    hideOnLg: {
        display: "none",
        [theme.breakpoints.down("sm")]: { display: "flex", },
    },
    topParaText: {
        textAlign: "left", marginLeft: "1rem", color: "#666", fontSize: "1.02rem",
        [theme.breakpoints.down(1280)]: { marginRight: "13rem", },
        [theme.breakpoints.down("sm")]: { marginRight: "3rem", },
        [theme.breakpoints.down("xs")]: { marginRight: "1rem", fontSize: "0.9rem", },
    },
    botParaText: {
        textAlign: "left", marginLeft: "1rem", marginBottom: "2rem", color: "#666",
        letterSpacing: "0.02rem", fontSize: "1.02rem",
        [theme.breakpoints.down(1280)]: { marginRight: "5rem", },
        [theme.breakpoints.down("sm")]: { marginRight: "3rem", fontSize: "1rem", },
        [theme.breakpoints.down("xs")]: { marginRight: "1rem", fontSize: "0.9rem", },
    },
    cardStyle: {
        textAlign: "center", paddingTop: "5rem", paddingBottom: "4rem", position: "relative",
        marginLeft: "30rem", marginRight: "30rem",
        [theme.breakpoints.down(1684)]: { marginLeft: "20rem", marginRight: "20rem" },
        [theme.breakpoints.down(1433)]: { marginLeft: "15rem", marginRight: "15rem" },
        [theme.breakpoints.down(1280)]: { marginLeft: "11rem", marginRight: "11rem" },
        [theme.breakpoints.down(1043)]: { marginLeft: "6rem", marginRight: "6rem" },
        [theme.breakpoints.down("sm")]: { marginLeft: "3rem", marginRight: "3rem" },
        [theme.breakpoints.down("xs")]: { marginLeft: "2rem", marginRight: "2rem" },
    },
}));

function About() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { token, zipCode } = useMemo(() => {
        return {
          token: searchParams.get("token"),
          zipCode: searchParams.get("q"),
        }
      }, [searchParams]);

    const [nextToken, setsessionToken] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        if (token) {
            setItem('accessToken', token);
            setZipcode('defaultZipCode', zipCode);
        }
        const value = getItem('accessToken');
        setsessionToken(value);
        // eslint-disable-next-line 
    }, [token])

    const redirectSignup = () => {
        navigate("/signup");
    };
    
    const setItem = (key, value) => {
        if (typeof window !== 'undefined') { window.sessionStorage.setItem(key, value); }
    }
    
    const getItem = (key) => {
        let value = {};
        if (typeof window !== 'undefined') { value = window.sessionStorage.getItem(key); }
        return value;
    }
    
    //zipcode
    const setZipcode = (key, value) => {
        if (typeof window !== 'undefined') { window.sessionStorage.setItem(key, value); }
    }

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid container item lg={12} className={classes.cardStyle} justifyContent="center">
                <Card>
                    <CardHeader titleTypographyProps={{ variant: 'h1' }} style={{ height: "5rem" }} className={classes.title}
                        classes={{ title: classes.title }} title="About Life Without Laundry" />
                    <CardContent className={classes.hideOnSm} >
                        <Grid container item>
                            <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                                <p>Hey friend,</p>
                                <p>You know, I loved college. Like, REALLY loved college.</p>
                                <p>The atmosphere, the "scenery", the life in general.  But I always had this one thing that would nag me: Laundry Day. </p>
                                <p>Despised it! Hated it like Indiana Jones hates snakes.</p>
                            </Grid>
                            {/* start Hide on Large screens */}
                            <Grid className={classes.showOnLG} item lg={4} md={4} sm={12} xs={12}>
                                {
                                    // eslint-disable-next-line 
                                }<img
                                    className={classes.img}
                                    src="/lwl-logo.png"
                                    alt="Life Without Laundry character logo" />
                            </Grid>
                            {/* END Hide on Large screens */}
                            <Grid item lg={10} md={10} sm={12} xs={12} className={classes.botParaText}>
                                <p>See, I don't know about you, but I just had one too many times dragging that cheapo hamper from orientation down four flights of stairs,
                                    pockets stuffed with quarters, just to find that all the darn machines were being used!</p>
                                <p>Then, whenever we actually do get to use the machines, we both a. lose a single sock,
                                    and b. we have to deal with folding afterwards! And all this while taking up 1 to 1½ hours of our days. Ridiculous.</p>
                                <p> So, while my automatically-laundry-folding super-high-capacity washer-dryer is being built, thought I might start a laundry service.
                                    And I present to you, "Life Without Laundry".</p>
                                <p>Never again will a friend ask "what's that smell?" while visiting your room. Never again will your girlfriend, boyfriend, husband, wife,
                                    or confused cousin refuse to show affection for you because your 3 week old gym socks are sitting in that odor basket at the foot of your bed.</p>
                                <p>Instead, check us out. We're unlike anything out there because,
                                    well we’re AWESOME! For as low as 2.50 per lb you can have your laundry picked-up and delivered back to you all nice and lovely.</p>
                                <p> Oh and we can pick up and deliver your dry cleaning too!</p>
                                <p>How did you ever live without us?</p>
                                <p>Warm regards, Alex 🙂</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {!nextToken && (<Button onClick={redirectSignup} className={classes.button} variant="contained"> Get Started! </Button>)}
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardContent className={classes.hideOnLg} >
                        <Grid container item>
                            {/* start Hide on Large screens */}
                            <Grid className={classes.showOnLG} item lg={4} md={4} sm={12} xs={12}>
                                {
                                    // eslint-disable-next-line 
                                }<img
                                    className={classes.img}
                                    src="/lwl-logo.png"
                                    alt="Life Without Laundry character logo" />
                            </Grid>
                            {/* END Hide on Large screens */}
                            <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                                <p>Hey friend,</p>
                                <p>You know, I loved college. Like, REALLY loved college.</p>
                                <p>The atmosphere, the "scenery", the life in general.  But I always had this one thing that would nag me: Laundry Day. </p>
                                <p>Despised it! Hated it like Indiana Jones hates snakes.</p>
                            </Grid>
                            <Grid item lg={10} md={10} sm={12} xs={12} className={classes.botParaText}>
                                <p>See, I don't know about you, but I just had one too many times dragging that cheapo hamper from orientation down four flights of stairs,
                                    pockets stuffed with quarters, just to find that all the darn machines were being used!</p>
                                <p>Then, whenever we actually do get to use the machines, we both a. lose a single sock,
                                    and b. we have to deal with folding afterwards! And all this while taking up 1 to 1½ hours of our days. Ridiculous.</p>
                                <p> So, while my automatically-laundry-folding super-high-capacity washer-dryer is being built, thought I might start a laundry service.
                                    And I present to you, "Life Without Laundry".</p>
                                <p>Never again will a friend ask "what's that smell?" while visiting your room. Never again will your girlfriend, boyfriend, husband, wife,
                                    or confused cousin refuse to show affection for you because your 3 week old gym socks are sitting in that odor basket at the foot of your bed.</p>
                                <p>Instead, check us out. We're unlike anything out there because,
                                    well we’re AWESOME! For as low as 2.50 per lb you can have your laundry picked-up and delivered back to you all nice and lovely.</p>
                                <p> Oh and we can pick up and deliver your dry cleaning too!</p>
                                <p>How did you ever live without us?</p>
                                <p>Warm regards, Alex 🙂</p>
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {!nextToken && (<Button onClick={redirectSignup} className={classes.button} variant="contained"> Get Started! </Button>)}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
export default About;
