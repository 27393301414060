import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from './SetupForm';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";

// const PUBLIC_KEY = window.sessionStorage.getItem('publishableKey');
// const stripePromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer({ setupIntent, path, resetData }) {
  const axios = useAxiosPrivate();
  const [stripePromise, setStripePromise] = useState(null);

  const getPublishableKey = async () => {
    try {
      const { data } = await axios.get('/customer/publishable-key');
      const publishableKey = loadStripe(data.publishableKey);
      setStripePromise(publishableKey);
      window.sessionStorage.setItem('publishableKey', data.publishableKey);
    } catch (error) {
      // please handle errors
    }
  };

  useEffect(() => {
    getPublishableKey();
    //eslint-disable-next-line
  }, []);

  const options = {
    // passing the client secret obtained from backend
    clientSecret: setupIntent,
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      {' '}
      <SetupForm resetData={resetData} path={path} />{' '}
    </Elements>
  );
}
