import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: 20,
    padding: '5rem',
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: "3rem",
    [theme.breakpoints.down('sm')]: {
      padding: '5%',
      paddingTop: 0,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      padding: '2%',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  header: {
    fontSize: '1.5rem',
    fontFamily: '"Lato",sans-serif',
    lineHeight: '1.37rem',
    color: '#222',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  bilingHeader: {
    fontSize: '1.5rem',
    fontFamily: '"Lato",sans-serif',
    lineHeight: '1.37rem',
    color: '#222',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: "3rem",
    },
  },

  bilingCard: {
    textAlign: 'left',
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  bilingBackto: {
    marginTop: "1rem",
    marginBottom: "1rem",
    cursor: "pointer",
    color: "#008EFF",
    textDecoration: "underline",
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: "2rem",

    },
  },

  box: {
    display: 'flex',
    alignItems: 'center',
  },
  mainGrid2: {
    [theme.breakpoints.down('xs')]: {
      padding: '5%',
    },
  },
  pdate: {
    fontSize: '1rem',
    fontFamily: '"Lato",sans-serif',
    lineHeight: '1.37rem',
    color: '#0083ff',
    fontWeight: '700',
  },
  pdetail: {
    fontSize: '0.87rem',
    fontFamily: '"Lato",sans-serif',
    lineHeight: '1.37rem',
    color: '#263347',
    fontWeight: '400',
  },
  para6: {
    fontSize: '1rem',
    lineHeight: '1.37rem',
    color: '#222222',
    fontWeight: '700',
  },
  continue3: {
    backgroundColor: '#3ab961',
    width: '30%',
    height: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '1.2rem',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#58A846',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  container: {
    backgroundColor: 'white',
    margin: '2rem',
    marginTop: '1rem',
    padding: '1rem',
    marginBottom: '0',
  },
  para1: {
    color: '#222222',
    fontSize: '1.18rem',
    fontWeight: 700,
    lineHeight: '1.37rem',
    marginBottom: '0.5rem',
  },
  divContain: {
    backgroundColor: 'white',
    padding: 5,
    marginTop: '1rem',
  },
  divContain1: {
    backgroundColor: 'white',
    padding: '0.5rem',
    marginTop: '0.5rem',
  },

  total: {
    color: '#222222',
    fontSize: '1.18rem',
    fontWeight: 700,
    lineHeight: '1.37rem',
    marginBottom: '0.3rem',
    marginTop: '1.5rem',
  },

  //Order calculation section table
  borderRowstyle: {
    borderTop: "0rem",
    borderBottom: "1rem",
    borderLeft: "0rem",
    borderRight: "0rem",
    borderColor: "#E0E0E0",
    borderStyle: "solid",
  },

  //price table hide on MD 
  hideOnMd: {
    display: "flex",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },

  //price table hide on LG
  showOnMd: {
    display: "none",
    [theme.breakpoints.down('md')]: {
      display: "flex",
    },
  },

}));
