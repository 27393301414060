import React, { useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";

import { useNavigate } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector, useDispatch } from "react-redux";
import { setZipCode } from "../../../store/actions/authActions";
import { setDefaultAddress, setDefaultLocation } from "../../../store/actions/authActions";

import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

const StyledButton = styled(Button)({
  backgroundColor: "#2cca5c", textTransform: "none", color: "white",
  "&:hover": { backgroundColor: "#23a24a", },
  "&:active": { boxShadow: "none", },
  "&:focus": { boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)", },
});

const useStyles = makeStyles((theme) => ({
  lgAddBtnGrid: { display: "inline", [theme.breakpoints.down(960)]: { display: "none", }, },
  smAddBtnGrid: { display: "none", [theme.breakpoints.down(960)]: { marginTop: "1rem", display: "inline", }, },
  lgAddBtnRender: { display: "flex", [theme.breakpoints.down(960)]: { display: "none", width: "100%", }, },
  smAddBtnRender: { display: "none", [theme.breakpoints.down(960)]: { display: "flex", width: "100%", }, },
  locationContain: { [theme.breakpoints.down(960)]: { marginTop: "3rem", }, },
}));

export default function MyLocation({ classes, location, getData }) {
  const axios = useAxiosPrivate();
  const btnclasses = useStyles();

  let id = useSelector((state) => state.auth.id);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });

  const updateDefaultLocation = async (id) => {
    try {
      const { data } = await axios.put( `/customer/update-default-location/${id}` );
      dispatch(setZipCode(data.defaultZipCode));
      dispatch(setDefaultAddress(data.defaultAddress));
      dispatch(setDefaultLocation(data.defaultLocation));
      setAlert({ showAlert: true, severity: "success", message: "Default location Changed Successfully", });
      getData();
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: "Default location Change Failed", });
    }
  };

  const deleteLocation = async (id) => {
    try {
      const res = await axios.put(`/customer/removeLocation/${id}`);
      if (res.status === 200) {
        getData();
        setAlert({ showAlert: true, severity: "success", message: "Location Deleted Successfully", });}
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: err.message, }); 
    }
  };

  const moveDefaultToFirst = (array) => {
    const newArray = [...array];
  
    const defaultIndex = newArray.findIndex(item => item.isDefault === true);
  
    if (defaultIndex !== -1) {
      const defaultElement = newArray.splice(defaultIndex, 1)[0];
      newArray.unshift(defaultElement);
    }
  
    return newArray;
  }
  

  return (
    <Grid container spacing={1} style={{ width: "100%" }}>
      <Grid container item sm={12} md={12} lg={12} className={classes.header}>
        <Grid item xs={12} sm={12} md={6} lg={6} className={btnclasses.locationContain} >
          <Typography variant="h4" className={classes.headerTypo}> My Locations </Typography>
        </Grid>
        <Grid container item lg={6} md={6} sm={12} xs={12} >
          {/*START render on large device */}
          <Grid item className={btnclasses.lgAddBtnGrid} justifyContent="flex-end" >
            <StyledButton className={btnclasses.lgAddBtnRender} variant="contained" startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addlocation", { state: { id: id, from: "settings" }, }) } >
              Add </StyledButton>
          </Grid>
          {/*END render on large device */}
          {/*START render on small device */}
          <Grid item lg={6} md={6} sm={12} xs={12} className={btnclasses.smAddBtnGrid} justifyContent="center" >
            <StyledButton className={btnclasses.smAddBtnRender} variant="contained" size="small" startIcon={<AddCircleIcon />}
              onClick={() => navigate("/addlocation", { state: { id: id, from: "settings" }, }) } > Add </StyledButton>
          </Grid>
          {/*END render on small device */}
        </Grid>
      </Grid>
    <div style={{maxHeight:"500px", overflowY:"auto", overflowX:"hidden",}}>
      {moveDefaultToFirst(location)?.map((ele) => {
        return (
          <Grid container style={{ padding: "0.5rem", border: "0.11rem solid #E8E8E8", borderLeft: 0, borderRight: 0, borderTop: 0, }} >
            <Grid container item direction="column" alignItems={{ lg: "left", md: "center", sm: "center", xs: "center", }}
              justifyContent={{ lg: "left", md: "center", sm: "center", xs: "center", }} >
              <Typography sx={{ textAlign: { lg: "left", md: "center", sm: "center", xs: "center", } }} >
                {ele.address} </Typography>
              {ele?.isDefault ? ( <span style={{ color: "green" }}> - Default</span> ) : (
                <Grid item>
                  <Button>
                    <Typography style={{ color: "red", textTransform: "initial", fontSize: "0.9rem" }} onClick={() => deleteLocation(ele._id)}
                    >Delete</Typography>
                  </Button>
                  <Button onClick={() => updateDefaultLocation(ele._id)} >
                    <Typography style={{ color: "green", textTransform: "initial", fontSize: "0.9rem" }}>Make default</Typography>
                  </Button>
                </Grid> )}
            </Grid>
          </Grid>);
      })}
        </div>
    </Grid>
  );
}
