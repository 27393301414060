import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import DialogAlertMain from "components/common/DialogAlertMain";
import MyAccount from 'screens/myAccount/MyAccount';
import { makeStyles } from '@material-ui/core/styles';
import CouponsList from './coupons';
import CouponsSubMenu from './couponsSubMenu';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  headerTypo: {
    color: '#457FC2',
    fontWeight: '700',
    fontSize: '1.2rem',
    fontFamily: '"Lato",sans-serif',
    marginBottom: '1rem',
  },

  headerTypo2: {
    color: '#457FC2',
    fontWeight: '700',
    fontSize: '1.2rem',
    fontFamily: '"Lato",sans-serif',
    marginBottom: '2rem',
    marginTop: '2rem',
  },
}));

export default function Upcoming() {
  const axios = useAxiosPrivate();
  const classes = useStyles();
  const location = useLocation();

  const [backendCall, setBackendCall] = useState(false);

  const [listRewards, setRewards] = React.useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: 'success',
    message: '',
  });

  const alertSetter = (showAlert, severity, message, time) => {
    if (time) {
      alertSetter(showAlert, severity, message);
      setTimeout(() => {
        alertSetter(false, severity, '');
      }, time);
    } else {
      setAlert({
        showAlert: showAlert,
        severity: severity,
        message: message,
      });
    }
  };

  // redeemBtn trigger
  const [redeemBtn, setredeemBtn] = useState(false);
  const [redeemBtnId, setredeemBtnId] = useState('');
  const [points, setPoints] = useState(0);

  const getData = async () => {
    setBackendCall(true)
    try {
      const { data } = await axios.get('/customer/coupons');
      setRewards(data);
      setBackendCall(false);
    } catch (err) {
      setBackendCall(false);
      alertSetter(true, 'error', 'Something went wrong', 3000);
    } finally {
      setredeemBtn(false);
    }
  };

  const redeem = async (id, points) => {

    setBackendCall(true);

    try {
      const coupon = {
        couponId: id,
        points,
      };
      const { data } = await axios.post(`/customer/redeem-coupon`, coupon);


      alertSetter(true, 'success', data.message);
      setTimeout(() => {
        setredeemBtn(false);
        alertSetter(false, '', '');
      }, 3000);
      //for refresh on confirm
      location.state = { value: (location.state?.value) ? false : true };
    } catch (err) {
      setBackendCall(false);
      //for refresh on confirm
      location.state = { value: (location.state?.value) ? false : true };
      if (err.response.status === 404) {
        alertSetter(true, 'error', err.response.data.message);
        setTimeout(() => {
          setredeemBtn(false);
          alertSetter(false, '', '');
        }, 3000);
      } else {
        alertSetter(true, 'error', 'Something went wrong');
        setTimeout(() => {
          setredeemBtn(false);
          alertSetter(false, '', '');
        }, 3000);
      }
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  function redeemBtnCng(id, vlaue, points) {
    if (points) {
      setPoints(points);
    }
    setBackendCall(false);
    setredeemBtn(vlaue);
    setredeemBtnId(id);
  }

  return (
    <MyAccount>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant='h4' className={classes.headerTypo}>
            Rewards
          </Typography>
          {/* Start conditinaly rendering coupons submenu*/}

          {!redeemBtn && (
            <CouponsList rewards={listRewards} redeemClick={redeemBtnCng} />
          )}

          {redeemBtn && (
            <CouponsSubMenu
              backendCall={backendCall}
              setstateonreload={() => setredeemBtn(false)}
              couponArr={listRewards}
              slectedCouponId={redeemBtnId}
              points={points}
              redeem={() => {
                redeem(redeemBtnId, points);
              }}
            />
          )}

          {/* END conditinaly rendering coupons submenu*/}
        </Grid>
      </Grid>
      {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
    </MyAccount>
  );
}
