import { LOGIN, LOGOUT, PAYMENTCARDLIST } from "./types";
import axios from "../../utils/lib/axios";


 //check if paymentMethod Available
 const getCardList = async (dispatch) => {
  const PaymentData = await axios.get(`/customer/payment-methods`);
   sessionStorage.setItem("isPaymentAvailable", PaymentData.data.cardList.length > 0 ? true : false);  
  
   dispatch({
    type: PAYMENTCARDLIST,
    payload: {
      isPaymentAvailable: PaymentData.data.cardList.length > 0 ? true : false
    },
  });
}


export const login = (accessToken, id, defaultZipCode, defaultAddress, firstName, lastName, defaultLocation, phone) => (dispatch) => {
  try {
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    axios.interceptors.request.use(
      function (config) {
        config.headers["Authorization"] = "Bearer " + accessToken;
        return config;
      },
      null,
      { synchronous: true }
    );



    // save to the session storage
    sessionStorage.setItem("accessToken", accessToken);
    sessionStorage.setItem("id", id);
    sessionStorage.setItem("defaultZipCode", defaultZipCode);
    sessionStorage.setItem("defaultAddress", defaultAddress);
    sessionStorage.setItem("firstName", firstName);
    sessionStorage.setItem("lastName", lastName);
    sessionStorage.setItem("phone", phone);
    sessionStorage.setItem("defaultLocation", JSON.stringify(defaultLocation));
    getCardList(dispatch);

    dispatch({
      type: LOGIN,
      payload: {
        accessToken,
        id: id,
        defaultZipCode: defaultZipCode,
        defaultAddress: defaultAddress,
        firstName,
        lastName,
        defaultLocation,
        phone
      },
    }); 
  } catch (error) { }
};


export const paymentCardsList = (data) => (dispatch) => {
      sessionStorage.setItem("isPaymentAvailable", data.cardList.length > 0 ? true : false);
      dispatch({
        type: PAYMENTCARDLIST,
        payload: {
          isPaymentAvailable: data.cardList.length > 0 ? true : false
        },
      });

};


export const logout = () => (dispatch) => {
  axios.interceptors.request.use(
    (config) => {
      // Do something before request is sent

      delete config.headers["Authorization"];
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  // remove items from session storage
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("combinedPickupNote");
  sessionStorage.removeItem("defaultLocation");
  sessionStorage.removeItem("id");
  sessionStorage.removeItem("defaultZipCode");
  sessionStorage.removeItem("defaultAddress");
  sessionStorage.removeItem("firstName");
  sessionStorage.removeItem("lastName");
  sessionStorage.removeItem("phone");
  sessionStorage.removeItem("isPaymentAvailable");
  sessionStorage.removeItem("isRetry");
  sessionStorage.removeItem("orderId");
  sessionStorage.removeItem("retryOrderID");
  

  // axios.defaults.headers.common.Authorization = null;

  setTimeout(() => {
    dispatch({
      type: LOGOUT,
    });
  }, 70);
};

export const setZipCode = (defaultZipCode) => (dispatch) => {
  dispatch({
    type: "ZIPCODE",
    payload: {
      defaultZipCode,
    },
  });
};


export const setDefaultAddress = (defaultAddress) => (dispatch) => {
  dispatch({
    type: "DEFAULTADRESS",
    payload: {
      defaultAddress,
    },
  });
};


export const setDefaultLocation = (defaultLocation) => (dispatch) => {
  dispatch({
    type: "DEFAULTLOCATION",
    payload: {
      defaultLocation,
    },
  });
};
