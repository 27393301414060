import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CouponsList from './coupons';

const useStyles = makeStyles((theme) => ({
  btnSpaceing: {
    marginTop: '1rem',
  },

  confirmButtonStyle: {
    textTransform: 'capitalize',
    background: '#2cca5c',
    border: 'none',
    padding: '1rem 2.3rem',
    fontSize: '1.2em',
    borderRadius: '0.3rem',

    '&:hover': {
      backgroundColor: '#1ca144',
      opacity: '0.9',
    },
  },

  cancelButtonStyle: {
    textTransform: 'capitalize',
    border: 'none',
    padding: '1rem',
    fontSize: '1.2em',
    borderRadius: '0.3rem',
  },

  hideOnLg: {
    display: 'none',
    [theme.breakpoints.down(960)]: {
      display: 'flex',
    },
  },

  hideOnSm: {
    display: 'inline',
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
}));

export default function Couponconfirm({
  couponArr,
  slectedCouponId,
  setstateonreload,
  redeem,
  points,
  backendCall
}) {
  const classes = useStyles();

  // get relvent Coupon
  function retriveIdmatchedCoupons() {
    return couponArr.filter((oldVal) => oldVal.id === slectedCouponId);
  }

  return (
    <>
      <Grid container>
        <Grid container item lg={12} md={12} sm={12} xs={12} spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {/* coupon filter */}
            {
              <CouponsList
                btnVisiblility='none'
                rewards={retriveIdmatchedCoupons()}
              />
            }
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h2>Please confirm the redemption of this reward:</h2>

            <Divider
              style={{
                marginTop: '1rem',
                marginBottom: '1rem',
              }}
            />

            <p style={{ wordBreak: "break-all", }}>
              Please note that you are about to redeem this reward and use{' '}
              {points} points. Once redeemed these points will be deducted from
              your account. If you would like to redeem the item showm click the
              button below.
            </p>
          </Grid>

          {/* start button load on LG */}
          <Grid container className={classes.hideOnSm}>
            <Grid
              container
              item
              className={classes.btnSpaceing}
              lg={12}
              md={12}
            >
              <Grid item lg={3} md={3}>
                <Button
                  disabled={backendCall}
                  color='primary'
                  variant='contained'
                  className={classes.confirmButtonStyle}
                  onClick={() => redeem()}
                >
                  Confirm
                </Button>
              </Grid>

              <Grid item lg={3} md={3}>
                <Button
                   disabled={backendCall}
                  color='primary'
                  variant='text'
                  className={classes.cancelButtonStyle}
                  onClick={() => setstateonreload()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {/* END button load on LG */}

          {/* start button load on SM */}

          <Grid
            container
            className={classes.hideOnLg}
            alignItems='center'
            justifyContent='center'
          >
            <Grid
              container
              item
              className={classes.btnSpaceing}
              sm={9}
              alignItems='center'
              justifyContent='center'
            >
              <Grid item lg={3} md={3} sm={6} xs={6}>
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.confirmButtonStyle}
                  onClick={() => redeem()}
                >
                  Confirm
                </Button>

                <Button
                  color='primary'
                  variant='text'
                  className={classes.cancelButtonStyle}
                  onClick={() => setstateonreload()}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* start button load on SM */}
        </Grid>
      </Grid>
    </>
  );
}
