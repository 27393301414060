import React from 'react';
// import { isMobile } from 'react-device-detect';

import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#008EFF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#008EFF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 4,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#008EFF',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#008EFF',
    zIndex: 1,
    fontSize: 30,
  },
  '& .QontoStepIcon-circle': {
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));


const ProgressBar = ({ text }) => {

  const orderNumber = JSON.parse(sessionStorage.getItem('orderNumber'));

  //map progress
  let activeStep;
    switch (text) {
      case "PICKUP":
        activeStep = 1;
        break;
        case "DELIVERY":
          activeStep = 2;
        break;
        case "singleSPECIAL":
          activeStep = 2;
          break;
        case "SPECIAL":
          activeStep = 3;
        break;
      default:
        break;
  }

  // const Parentdiv = {
  //   height: height,
  //   width: '50%',
  //   backgroundColor: 'whitesmoke',
  //   borderRadius: 40,
  //   margin: 20,
  // };

  // const Parentdiv2 = {
  //   height: height,
  //   width: '90%',
  //   backgroundColor: 'whitesmoke',
  //   borderRadius: 40,
  //   margin: 20,
  // };

  // const Childdiv = {
  //   height: '100%',
  //   width: `${progress}%`,
  //   backgroundColor: bgcolor,
  //   borderTopLeftRadius: 40,
  //   borderBottomLeftRadius: 40,
  //   textAlign: 'right',
  //   display: 'flex',
  //   justifyContent: 'flex-end',
  //   alginItems: 'center',
  //   fontSize: '0.8rem',
  //   fontFamily: '"Lato",sans-serif',
  //   lineHeight: '1rem',
  // };

  // const progresstext = {
  //   padding: 5,
  //   color: 'white',
  //   fontWeight: 400,
  // };

  function QontoStepIcon(props) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = ['Select Services', 'Select a Pickup Date', 'Select a Delivery Date'];
  const stepSpecial = ['Select Services', 'Select a Pickup Date', 'Select a Special care Delivery Date'];
  const combinedStepSpecial = ['Select Services', 'Select a Pickup Date', 'Select a Delivery Date', 'Select a Special care Delivery Date'];


  return (
    // <div style={isMobile ? Parentdiv2 : Parentdiv}>
    //   <div style={Childdiv}>
    //     <span style={progresstext}>{`${text}`}</span>
    //   </div>
    // </div>

    <Stack sx={{ width: '100%', marginTop:"3rem", marginBottom:"3rem"}}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>

        {(orderNumber.orderType==="9" || orderNumber.orderType === "5" || orderNumber.orderType === "7") ? combinedStepSpecial.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
        </Step>)
        
        ) : (orderNumber.orderType === "4") ?
          (stepSpecial.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>))
              
      ) : ( steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>))
      )}
    </Stepper>
  </Stack>
    
  );
};

export default ProgressBar;
