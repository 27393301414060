import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import DiamondIcon from '@mui/icons-material/Diamond';
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

// import Alert from "@material-ui/lab/Alert";



const useStyles = makeStyles((theme) => ({

  topSideNavBtnSection: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  rewardModule: {
    color: "white",
    borderRadius: "0.7rem",
    marginTop: "1rem",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "20rem",
      minWidth: "20rem",
    },
    [theme.breakpoints.down(325)]: {
      maxWidth: "15rem",
      minWidth: "15rem",
    },
  },

}));


const StyledButton2 = styled(Button)({
  width: "100%",
  textTransform: "none",
  textAlign: "center",
  color: "white",
  backgroundColor: "#315A89",
  paddingTop: "1.3rem",
  paddingBottom: "1.3rem",
  borderBottomLeftRadius: "0.5rem",
  borderBottomRightRadius: "0.5rem",

  "&:hover": {
    backgroundColor: "black",
    boxShadow: "none",
  },
});





export default function RewardpointSideNav() {
  const axios = useAxiosPrivate()
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();



  const [points, setpoints] = useState('');
  // eslint-disable-next-line
  const [value, setValue] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });



  const getData = async () => {
    try {
      const { data } = await axios.get("/customer/points");
      setpoints(data);

    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Something went wrong",
          message: "",
        });
      }, 3000);
    }
  };



  useEffect(() => {
    // if (location.state?.value) {
    //   setValue(location.state?.value);
    // } else {
    //   setValue(oldVal => !oldVal);
    setValue(location.state?.value);
    // } 
    getData()
    //eslint-disable-next-line
  }, [location.state?.value]);

  //  console.log(value)
  // useEffect(() => {
  //   getData()
  // }, [location.state])



  return (

    <Grid container alignItems="center" justifyContent="center">
      <Grid container item lg={12} md={12} sm={12} xs={6}
        className={classes.rewardModule}>

        <Grid item lg={12} md={12} sm={12} xs={12}
          style={{
            color: "white",
            backgroundColor: "#35bfff",
            borderTopLeftRadius: "0.7rem",
            borderTopRightRadius: "0.7rem",
            textAlign: "center",
            paddingTop: "1rem",


          }}
        >
          <DiamondIcon />
        </Grid>


        <Grid item lg={12} md={12} sm={12} xs={12}
          style={{
            color: "white",
            backgroundColor: "#35bfff",
            paddingBottom: "0.7rem",
          }} >

          <div data-testid="points" style={{
            textAlign: "center",
            fontSize: "3em"
          }}>
            {(alert && points?.points!==undefined) ? Math.round(points?.points) : <CircularProgress sx={{color:"white"}} />}
            {/* {alert && (Math.round(points?.points))} */}
          </div>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}
          style={{
            color: "white",
            backgroundColor: "#457FC2",
            padding: "0.7rem",
          }} >

          <div style={{ textAlign: "center", }}>
            Your Current Points
          </div>

        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}
          style={{
            color: "white",
            borderBottomLeftRadius: "0.7rem",
            borderBottomRightRadius: "0.7rem",
            backgroundColor: "#315A89",
          }}
        >
          <StyledButton2
            data-testid="redeemRewards"
            onClick={() => {
              navigate("/myaccount/getrewards");
              // window.location.reload(); 
            }}
          > Redeem Rewards!

          </StyledButton2>
        </Grid>
      </Grid>
    </Grid>
  );
}
