import React, { useRef, useEffect } from 'react';
import PageHeader from 'components/UI/PageHeader';
import { useStyles } from './classes';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { reset } from '../../store/actions/orderAction';
import OrderStatus from "components/StatusTracker/OrderStatus";
import { Grid } from '@material-ui/core';

export default function Complete() {
  const myRef = useRef(null);
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();


  const executeScroll = () => myRef.current.scrollIntoView();

  const pickup = JSON.parse(sessionStorage.getItem('pickup'));
  const delivery = JSON.parse(sessionStorage.getItem('delivery'));
  const delivery2 = JSON.parse(sessionStorage.getItem('delivery2'));
  const values = JSON.parse(sessionStorage.getItem('orderId'));


  const resOrder = {
    statusTracker: "placingOrder",
    orderId: values?.orderId,
    shippedPlace: values?.shippedPlace,
    specialDate: delivery2?.specialDate // setting specialcare delivery date when applicable
  }


  // const resOrder = {
  //   orderId: orderId,
  //   orderStatus: "Order is Placed",
  //   date: false,
  //   dateLabel: "Delivery Date",
  // }



  // const completeOrders = async () => {
  //   await dispatch(reset());
  //   navigate('/myaccount');
  // };

  useEffect(() => {
    setTimeout(() => {

      dispatch(reset());
      // navigate('/myaccount');
    }, 4000);

    executeScroll();
    //eslint-disable-next-line
  }, []);
  return (
    <div container ref={myRef} style={{ marginBottom: 50 }}>
      <PageHeader tittle={'Your Order Has Been Placed!'} />
      <Grid container
        alignItems='center'
        justifyContent='center'
        direction="column"
        style={{ marginTop: '1rem' }} >

        <Grid container item
          direction="column"
          alignItems='center'
          justifyContent='center'
        >

          <Grid item className={classes.divSize}>
            <p
              style={{
                color: '#222222',
                marginBottom: '1rem',
                lineHeight: '1.37rem',
              }}
            >
              Thank you for placing an order with Life Without Laundry!
            </p>
            <p
              style={{
                color: '#222222',
                marginBottom: '0.4rem',
                lineHeight: '1.37rem',
              }}
            >
              Your order has been successfully processed and added to your
              account. Below are our records of the services you have just
              scheduled.
            </p>

           {pickup?.pickupDate && pickup?.pickupTimeSlot && <div
              style={{
                backgroundColor: '#FFF',
                padding: '1rem',
                marginTop: '0.3rem',
              }}
            >
              <p
                style={{
                  color: '#222222',
                  marginBottom: '0.4rem',
                  lineHeight: '1.37rem',
                }}
              >
                Pick-up scheduled for:{' '}
                <span
                  style={{
                    color: '#222222',
                    lineHeight: '1.37rem',
                    fontWeight: '700',
                  }}
                >
                  {pickup?.pickupDate}
                </span>
              </p>
              <p
                style={{
                  color: '#222222',
                  marginBottom: '0.4rem',
                  lineHeight: '1.37rem',
                }}
              >
                {pickup?.pickupTimeSlot}
              </p>
            </div>}

            {delivery?.diliverDate && delivery?.diliverTimeSlot && <div
                           data-testid="deliveryText"

              style={{
                backgroundColor: '#FFF',
                padding: '1rem',
                marginTop: '0.3rem',
              }}
            >
              <p
                style={{
                  color: '#222222',
                  marginBottom: '0.4rem',
                  lineHeight: '1.37rem',
                }}
              >
                Delivery scheduled for:{' '}
                <span
                  style={{
                    color: '#222222',
                    lineHeight: '1.37rem',
                    fontWeight: '700',
                  }}
                >
                  {delivery?.diliverDate}
                </span>
              </p>
              <p
                style={{
                  color: '#222222',
                  marginBottom: '0.4rem',
                  lineHeight: '1.37rem',
                }}
              >
                {delivery?.diliverTimeSlot}
              </p>
            </div>}

            {delivery2 && (
              <div
              data-testid="specialText"

                style={{
                  backgroundColor: '#FFF',
                  padding: '1rem',
                  marginTop: '0.3rem',
                }}
              >
                <p
                  style={{
                    color: '#222222',
                    marginBottom: '0.4rem',
                    lineHeight: '1.37rem',
                  }}
                >
                  Special Care Delivery scheduled for:{' '}
                  <span
                    style={{
                      color: '#222222',
                      lineHeight: '1.37rem',
                      fontWeight: '700',
                    }}
                  >
                    {delivery2?.specialDate}
                  </span>
                </p>
                <p
                  style={{
                    color: '#222222',
                    marginBottom: '0.4rem',
                    lineHeight: '1.37rem',
                  }}
                >
                  {delivery2?.specialTimeSlot}
                </p>
              </div>
            )}


            <Grid item
              style={{
                marginBottom: "1rem",
                marginTop: "3rem",
                // overflowX: "scroll",
                // overflowY: "hidden",
                minHeight: "100%", 
              }}>

              <OrderStatus
                resOrder={resOrder} from={"placed"} />
            </Grid>

          </Grid>



        </Grid>
      </Grid>
      {/* <Grid container item
        alignItems='center'
        justifyContent='center'
        onClick={() => completeOrders()}
      >
        <Grid item className={classes.continue3}>
          <div className={classes.box}>
            <p style={{ cursor: 'pointer' }}>Complete</p>
          </div>
        </Grid>
      </Grid> */}
    </div>
  );
}
