import React, { useEffect } from 'react';
import { Card, CardHeader, CardContent, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import AnnouncementIcon from '@mui/icons-material/Announcement';

const useStyles = makeStyles({
    root: {
        width: "100%",
        height: "fit-content",
    },
    media: {
        height: 100,
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    title: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: 500,
        letterSpacing: '0.12rem',
        fontFamily: 'Popins, sans-serif',
        // textShadow: '2px 2px #000',
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const ExampleCard = ({ dataList, category }) => {
    const classes = useStyles();
    return (
        <Card className={classes.root} elevation={5}>
            <CardContent>
                <div className={classes.media} style={{ backgroundImage: `url("/productCategoriesImg/${category}.jpg")` }}>
                    <div className={classes.overlay}></div>
                    <Typography className={classes.title} variant="h2">{category === "FullBody" ? "Full Body" : category}</Typography>
                </div>
                {dataList.length > 0 ? (<List>
                    {dataList.map((e, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            <ListItemText primary={e?.itemName} />
                            <ListItemText style={{
                                textAlign: 'right',
                            }} primary={`$${e?.price?.toFixed(2)}`} />
                        </ListItem>
                    ))}
                </List>) : (
                    <>
                        <AnnouncementIcon
                            style={{
                                color: "#D6D6D6",
                                textAlign: 'center',
                                fontSize: "3rem",
                                marginTop: "1rem",
                            }}
                        />
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: "0.9rem",
                                marginTop: "1rem",
                                fontWeight: "600"
                                }}>{category === "FullBody" ? "Full Body" : category}{" "}
                                items not available...</Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default ExampleCard;
