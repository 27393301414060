import React, {useState, useEffect} from 'react';
import { useStyles } from './classes';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import { useSelector } from 'react-redux';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import CircularProgress from '@mui/material/CircularProgress';
import DialogAlertMain from "components/common/DialogAlertMain";

export default function Separation({ value, setValue }) {
  const axios = useAxiosPrivate();
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
    const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const orderNumber = JSON.parse(sessionStorage.getItem('orderNumber'));

  const [getData, setGetData] = useState([]);

  const loadData = async () => {
    try {
      const { data } = await axios.post(
        `/datepicker/serviceDays/${zipCode}`,
        {attended : orderNumber?.attended}
      ); 
      setGetData(data);
    } catch (err) {
      setAlert({ showAlert: true, severity: "error", message: err.response.data.msg ?? "Error", });
      setGetData([]);
    }
  };

  useEffect(() => { 
    loadData();
    //eslint-disable-next-line
  }, []); 

  const classes = useStyles();
  return (
    <>
      {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
      
    <div style={{ display: 'flex', justifyContent: 'center', width: '90%', marginTop:'2%' }}>
      {getData?.specialCareDays &&
      getData?.washDryDays
      ?
        (<div style={{ width: '40rem', borderRadius: 5, borderStyle: 'ridge', }} >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <WarningIcon style={{ color: '#EFB756', fontSize: '3.2rem', marginTop: '0.5rem', }} />
        </div>
        <p className={classes.parad}>
          Your order has both special care services as well as standard
          wash/dry/fold services, since special care items take a little longer
          to return, please choose how you would like them delivered:
        </p>
        <div className={classes.contain}>
            <div style={{ borderStyle: 'ridge', borderLeft:"0px", borderRight:"0px", borderBottom:"0px",}}>
                <p style={{ textAlign: 'center', marginTop: 5, fontSize: '1.5rem', lineHeight: '1.37rem',
                  color: '#333', fontWeight: '400', }} > Separate </p>
                <p style={{
                  textAlign: 'center', padding: '1rem', fontSize: '1rem',
                  lineHeight: '1.37rem', color: '#222', fontWeight: '400', }} >
                {`Wash/dry/fold ${getData?.washDryDays > 1 ? getData?.washDryDays : ""} 
                ${getData?.washDryDays > 1 ? `days after` : `next day`} &

               Special care ${getData?.specialCareDays > 1 ? getData?.specialCareDays : ""} 
               ${getData?.specialCareDays > 1 ? `days after` : `next day`} `}
                
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {value === '' || value === 'true' ? (
                <div
                  style={{ backgroundColor: '#35B666', borderRadius: 5, color: 'white', width: '50%',
                    marginBottom: '2rem', cursor: 'pointer', }} onClick={() => setValue('false')} >
                  <p style={{ padding: '1rem', textAlign: 'center', fontSize: '1rem', lineHeight: '1.37rem', fontWeight: '400', }} >
                    Select Option
                  </p>
                </div>
              ) : (
                <CheckIcon style={{ fontSize: '3rem', color: '#72896B', }} />
              )}
            </div>
          </div>
              <div className={classes.borderShow}>
            <p style={{ textAlign: 'center', marginTop: 5, fontSize: '1.5rem', lineHeight: '1.37rem',
                color: '#333', fontWeight: '400', }} > Together </p>
                <p style={{ textAlign: 'center', padding: '1rem', fontSize: '1rem',
                  lineHeight: '1.37rem', color: '#222', fontWeight: '400', }} >
              I don't mind waiting for everything to come all at once.
            </p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {value === '' || value === 'false' ? (
                <div
                  style={{
                    backgroundColor: '#35B666',
                    borderRadius: 5,
                    color: 'white',
                    width: '50%',
                    marginBottom: '2rem',
                  }}
                  onClick={() => setValue('true')}
                >
                  <p
                    style={{
                      padding: '1rem',
                      textAlign: 'center',
                      fontSize: '1rem',
                      lineHeight: '1.37rem',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    Select Option
                  </p>
                </div>
              ) : (
                <CheckIcon
                  style={{
                    fontSize: '3rem',
                    color: '#72896B',
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>):(
       
          <div style={{
            marginTop:"4rem"
          }}>
          <CircularProgress sx={{color:"#cfcccc"}} /> 
        </div>
      
      )}
      </div>
      
      </>
  );
}
