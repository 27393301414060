import React from "react";
import { Grid, Button, Typography } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";



const useStyles = makeStyles((theme) => ({
  discription: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "0rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "1rem",
      paddingLeft: "6rem",
      paddingRight: "6rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: "1rem",
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },

  container: {
    color: "#aaa",
    backgroundColor: "#efefef",
    padding: "2rem 1rem",
    fontSize: "1.2rem",
    borderRadius: "0.5rem",
  },

  pointNeed: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "1rem",
    },
  },


}));




export default function Rewards({ rewards, redeemClick, btnVisiblility }) {

  const classes = useStyles();


  const StyledButton = styled(Button)({
    display: btnVisiblility,
    width: "6.3rem",
    textTransform: "none",
    textAlign: "center",
    color: "white",
    backgroundColor: "#0073A8",
    paddingTop: "0.3rem",
    paddingBottom: "0.3rem",
    borderRadius: "0.3rem",
    boxShadow: "none",

    "&:hover": {
      backgroundColor: "#c4c4c4",
      boxShadow: "none"
    },
  });


  return (
    <>

      {rewards?.length > 0 ? (
        rewards.map((item, i) => {
          return (
            <Card key={i} sx={{
              marginTop: "1rem",
              borderWidth: "1px",
              borderColor: "#ddd",
              borderStyle: "solid"
            }}>

              <CardContent>

                <Grid container>
                  <Grid container item
                    alignItems="center"
                    justifyContent="center"
                    lg={12}>

                    <Grid item lg={2} md={2} sm={3} xs={6}>
                      <img alt="promo_logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                        }}
                        src={item?.image} />
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} xs={12}
                      className={classes.discription}
                    >
                      <h4>
                        <span style={{ fontSize: "1.13rem", fontWeight: "900" }}>

                          {
                            `${item?.discountType === "percentage" ?
                              `${item?.discountValue}%` :
                              `$${item?.discountValue}`}`}

                        </span>

                        <span style={{ fontWeight: "600" }}>

                          {` off ${item?.services.join(', ')} Coupon`}
                        </span>

                      </h4>

                      <p style={{ wordBreak: "break-all", }}>
                        {
                          `${item?.description}.
                          It is good for any ${item?.services.join(', ')} order.
                        This is a one time, one use coupon.`
                        }

                      </p>
                    </Grid>

                    <Grid item lg={3} md={3} sm={12} xs={12}
                      className={classes.pointNeed}
                    >
                      <h4>{`Points Needed ${item?.points}`}</h4>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12} >
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={() => redeemClick(item.id, true, item?.points)}>
                        Redeem
                      </StyledButton>
                    </Grid>

                  </Grid>

                </Grid>
              </CardContent>

            </Card>

          );
        })
      ) : (
        <Grid
          item container xs={12} md={12} lg={12} mt={5} justifyContent="center" p={5} className={classes.container} >
          <Typography variant="h6">
            No rewards available right now.
          </Typography>
        </Grid>
      )}
    </>
  );
}