import React, { useState } from "react";
import { Grid, Card, CardContent, CardActions, Button, Typography, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { login, setDefaultLocation } from "../../store/actions/authActions";
import { connect } from "react-redux";
import DialogAlertMain from "components/common/DialogAlertMain";
import MigrationDialogAlert from "components/common/MigrationDialogAlert";

import { useDispatch } from "react-redux";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LoadingButton from "@mui/lab/LoadingButton";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  separateIcon: { display: "flex", color: "white",
  [theme.breakpoints.down("390")]: { display: "none", },
  },

  gridMargin: { marginTop: "12rem", marginBottom: "1rem",
    [theme.breakpoints.down("md")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("sm")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("xs")]: { marginTop: "10rem", marginBottom: "0rem", },
  },

  fieldWidth: {
    width: "25vw",
    [theme.breakpoints.down("md")]: { width: "35vw", },
    [theme.breakpoints.down("sm")]: { width: "50vw", },
    [theme.breakpoints.down("xs")]: { width: "70vw", },
  },
  center: { textAlign: "center", color: "#35BFFF", fontSize: "2em", boxShadow: "5px",
    fontWeight: "300", lineHeight: "1em", marginTop: 0, marginBottom: "1rem", fontFamily: '"Lato",sans-serif', },
  padding: { paddingTop: "5rem", paddingBottom: "5rem", width: "42vw", height: "auto", boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: { width: "65vw", },
    [theme.breakpoints.down("sm")]: { width: "70vw", },
    [theme.breakpoints.down("xs")]: { width: "90vw", },
  },
  button: {
    backgroundColor: "#2cca5c", color: "white",
    "&:hover": { backgroundColor: "#2cca5c", color: "white", },
  },
  buttonLeft: {
    marginTop: "1rem", color: "#2cca5c", justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: { justifyContent: "center", },
    [theme.breakpoints.down("xs")]: { justifyContent: "center", },
  },
  buttonRight: {
    marginTop: "1rem", color: "#2cca5c", justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: { justifyContent: "center", },
    [theme.breakpoints.down("xs")]: { justifyContent: "center", },
  },
  btn1: { color: "#35bfff", fontWeight: "700", padding: "0.5rem 1rem", textTransform: "none",
    "&:hover": { backgroundColor: "#d6eef8", textShadow: "none", }, },
}));

let initialValues = { email: "", password: "", };

let SignUpSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required!"),
  password: Yup.string().required("Password is required!"), });

function Login(props) {
  const axios = useAxiosPrivate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  const [migrationAlert, setMigrationAlert] = useState({ showAlert: false, severity: "success", message: "", });
  
  let navigate = useNavigate();

  const submit = async (e) => {
    try {
      setBackendCall(true);
      const { data } = await axios.post("/auth/customer-login/", { email: e.email, password: e.password, });
      const { accessToken, id, location, defaultZipCode, firstName, lastName, defaultLocation, phone } = data;
      const defaultAddress = location?.find((item) => item.isDefault)?.address;
      dispatch(setDefaultLocation(defaultLocation));
      if (location?.length > 0) {
        props.login( accessToken, id, defaultZipCode, defaultAddress, firstName, lastName, defaultLocation, phone);
        navigate("/myaccount/upcoming");
      } else {
        navigate("/signup/addlocation", {
          state: { id: id, from: "login" },
        });
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({ showAlert: true, severity: "error", message: "Incorrect Login Credentials. Please check email / password!", });
      } else if (error.response.status === 400) { 
        setAlert({ showAlert: true, severity: "error", message: error.response.data.error ?? "Server error!", });
      }else if (error.response.status === 403) {
        setAlert({ showAlert: true, severity: "error", message: error.response.data ?? "Incorrect Login Credentials. Please check email / password!", });
      } else if (error.response.data.message.isMigration === true) {
        setAlert({ showAlert: true, severity: "error", message: "You are in Migration process, check your email and complete the migration!", });
      }  else if (error.response.status === 501) {
        setAlert({ showAlert: true, severity: "error", message: "You are temporary block. Please contact your administrator!", });
      } else {
        setAlert({ showAlert: true, severity: "error", message: error.response.data ?? "Server error!", });
      }
     
    } finally {
      setBackendCall(false);
    }
  };

  return (
    <Grid container className={classes.gridMargin} lg={12} justifyContent="center" alignItems="center" >
      <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center" >
        <Card className={classes.padding} variant="outlined" sx={{ boxShadow: 3 }} >
          <Grid container alignItems="center" justifyContent="center">
            <Grid container item lg={12} md={12} sm={12} alignItems="center" justifyContent="center" >
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="h1" className={classes.center} data-testid="main-title-login"> Login to Your Account </Typography>
              </Grid>
              <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} >
                {({ dirty, isValid }) => {
                  return (
                    <Form>
                      <CardContent>
                        <Grid item lg={12} md={12} sm={12}>
                          <Field className={classes.fieldWidth} name="email" label="Email" component={TextField} variant="outlined"
                            fullWidth margin="dense" data-testid="loginEmail"></Field>
                        </Grid>
                        <Grid lg={12} md={12} sm={12}>
                          <Field className={classes.fieldWidth} name="password" label="Password" component={TextField} variant="outlined"
                            fullWidth margin="dense" type="password" data-testid="loginPassword"></Field>
                        </Grid>
                      </CardContent>
                      <Grid item lg={12} md={12} sm={12}>
                        <CardActions data-testid="loginButton">
                          <LoadingButton loading={backendCall} sx={{ minWidth: "100%", textAlign: "center", backgroundColor: "#2cca5c", color: "white",
                              "&:hover": { backgroundColor: "#2cca5c", color: "white", }, }}
                            variant="contained" disabled={!dirty || !isValid} type="submit" size="large" > login </LoadingButton>
                        </CardActions>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
          <Grid container item alignItems="center" justifyContent="center" lg={12} md={12} sm={12} >
            <Grid item> <Button className={classes.btn1} onClick={() => navigate("/passwordhelp")} > I forgot my password </Button> </Grid>
            <Grid item className={classes.separateIcon}> <FiberManualRecordIcon style={{ color: "#D3D3D3", fontSize: "10px", }} /> </Grid>
            <Grid item> <Button className={classes.btn1} onClick={() => navigate("/signup")} > I need an account </Button> </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }} >
      {migrationAlert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <MigrationDialogAlert setAlert={setMigrationAlert} alert={migrationAlert} />
          // {alert.message} </Alert> 
        )}

      {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
      </Grid>
    </Grid>
  );
}
export default connect(null, { login })(Login);
