import React from 'react'
import { useNavigate } from "react-router-dom";

export default function AlertMain({ setAlert, alert, anchorOrigin }) {
  
let navigate = useNavigate();
  return (
      
      <div style={{backgroundColor: "#FFF2BF", padding: "1.5rem", color:"#29353E", textAlign:"Center",   boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"}}>
      We kindly ask that you update your{" "}
      <b>{alert?.message?.defaultAddress}{" "}{alert?.message?.defaultZipCode}{" "}
          {alert?.message?.firstName}{" "}{alert?.message?.lastName}{" "}{alert?.message?.phone}{" "}</b>
       {(alert?.message?.defaultAddress !== '' ||
        alert?.message?.defaultZipCode !== '' ||
        alert?.message?.firstName !== '' ||
        alert?.message?.lastName !== '' ||
        alert?.message?.phone !== ''
      ) && (<>in <span style={{ cursor: "pointer", color: "blue", fontWeight: "600" }}
        onClick={() => navigate("/myaccount/manage")}>Account settings</span></>)}
      
      {alert?.message?.isPaymentAvailable !== '' && <span> {" "}
        
      {(alert?.message?.defaultAddress !== '' ||
        alert?.message?.defaultZipCode !== '' ||
        alert?.message?.firstName !== '' ||
        alert?.message?.lastName !== '' ||
        alert?.message?.phone !== ''
        ) && "and"}
        
        {" "} <b>{alert?.message?.isPaymentAvailable}</b>{" "}
        in < span style={{ cursor: "pointer", color: "#2A76D2", fontWeight: "600", textDecoration: "underline" }}
      onClick={() => navigate("/myaccount/mywallet")}>My Wallet</span> </span>}
          
        </div>
        
  )
}
