import React from "react";
import { Grid, Card, CardContent, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "../../styles/ContactUs.module.scss";

const useStyles = makeStyles((theme) => ({

button: { background: "#35BFFF", marginBottom: "2rem", color: "#FFFFFF", textTransform: 'none', fontWeight:"500", fontSize:"1rem",
    [theme.breakpoints.down("sm")]: { fontSize: "0.95rem", lineHeight: "1.8rem", },
    [theme.breakpoints.down("xs")]: { lineHeight: "2rem", }, },
HeadParagraph: { color: "#666666", fontWeight: "800", font: "Lato,sans-serif", padding: "0px, 0px,16px",
    marginBottom: "4%", marginTop: "2%", fontSize: "1.2rem",
  [theme.breakpoints.down("sm")]: { fontSize: "1rem", }, },
title: { fontFamily: "Lato,sans-serif", textAlign: "left", fontSize: "2rem", lineHeight: "1em",
    fontWeight: "300", background: "#35BFFF", color: "#FFFFFF", paddingLeft: "1rem",
    [theme.breakpoints.down("sm")]: { fontSize: "1.8rem", lineHeight: "1.8rem", },
    [theme.breakpoints.down("xs")]: { fontSize: "1.7rem", lineHeight: "1.8rem", }, },
topParaText: { textAlign: "left", marginLeft: "1rem", color: "#666", fontSize: "1.02rem",
    [theme.breakpoints.down("xs")]: { marginLeft: "0.5rem", }, },
cardStyle: { textAlign: "center", paddingTop: "5rem", paddingBottom: "4rem", position: "relative",
    marginLeft: "30rem", marginRight: "30rem",
    [theme.breakpoints.down(1684)]: { marginLeft: "20rem", marginRight: "20rem"},
    [theme.breakpoints.down(1433)]: { marginLeft: "15rem", marginRight: "15rem"},
    [theme.breakpoints.down(1280)]: { marginLeft: "11rem", marginRight: "11rem"},
    [theme.breakpoints.down(1043)]: { marginLeft: "6rem", marginRight: "6rem"},
    [theme.breakpoints.down("sm")]: { marginLeft: "3rem", marginRight: "3rem"},
    [theme.breakpoints.down("xs")]: { marginLeft: "2rem", marginRight: "2rem"}, },
mobile:{ color: "#666", textDecoration: "none", },
}));

function ContactUs() {
  const classes = useStyles();

  return (

    <Grid container justifyContent="center" alignItems="center" >
      <Grid container item lg={12} className={classes.cardStyle} justifyContent="center">
        <Card>
          <CardHeader titleTypographyProps={{ variant: 'h1' }} style={{ height: "5rem" }} className={classes.title}
            classes={{ title: classes.title }} title="Questions or concerns? Contact us below!" />
          <CardContent>
            <Grid container item>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>General/Feedback Questions:</p>
                <Button className={classes.button}
                  onClick={() => (window.location.href = "mailto:general@lifewithoutlaundry.com")} variant="contained">
                  general@lifewithoutlaundry.com </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>Follow Us on Facebook!</p>
                <Button className={classes.button} variant="contained"
                  onClick={() => (window.location.href = "https://www.facebook.com/Life-Without-Laundry-146946790996/") } >
                  Life Without Laundry Fan Page </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>We're on Instagram too!</p>
                <Button variant="contained" className={classes.button}
                  onClick={() => (window.location.href = "https://www.instagram.com/lifewolaundry/") }>
                  @LifeWOLaundry </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>We're on Twitter Too!</p>
                <Button className={classes.button}
                  onClick={() => (window.location.href = "http://www.twitter.com/lifewolaundry") }
                  variant="contained" > @LifeWOLaundry </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>Claim Questions</p>
                <Button className={classes.button}
                  onClick={() => (window.location.href = "mailto:claims@lifewithoutlaundry.com")} variant="contained">
                  claims@lifewithoutlaundry.com </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>Advertiser Questions</p>
                <Button className={classes.button}  variant="contained"
                  onClick={() => (window.location.href = "mailto:advertising@lifewithoutlaundry.com") } >
                  advertising@lifewithoutlaundry.com </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <p className={classes.HeadParagraph}>Customer Support Questions</p>
                <p className={styles.paragraph}>Email :</p>
                <Button className={classes.button} variant="contained"
                  onClick={() => (window.location.href = "mailto:support@lifewithoutlaundry.com") } >
                  @LifeWOLaundry </Button>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12} className={classes.topParaText}>
                <parseInt className={styles.paragraph}>
                  {" "}
                  Or Call / Text Us 10am-10pm, 7 days a week! </parseInt>
                <p className={styles.paragraph}> <a className={classes.mobile} href="tel:617-768-7779"> 617-768-7779 </a> </p>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ContactUs;
