import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import StripeCardContainer from './StripeCardContainer';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from "react-redux";
import { paymentCardsList } from "../../store/actions/authActions";

const path = `${window.location.origin}/order/payments/setup-complete`;

export default function OrderCardElement({ btnVisiblility, addOrdersWithNewCard }) {
  const axios = useAxiosPrivate();
  const [setupIntent, setSetupIntent] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [publishableKey, setPublishableKey] = useState(null);
  const dispatch = useDispatch();

  const getSetupIntent = async () => {
    try {
      const { data } = await axios.get(
        `/customer/setup-intent/${window.sessionStorage.getItem('id')}`
      );
      setSetupIntent(data);
    } catch (error) {
      // please handle errors
    }
  };

  const getPublishableKey = async () => {
    try {
      const { data } = await axios.get('/customer/publishable-key');
      setTimeout(() => {
        setPublishableKey(data.publishableKey);
        window.sessionStorage.setItem('publishableKey', data.publishableKey);
      }, 100);
    } catch (error) { }
  };

  const getPaymentMethods = async () => {
    try {
      const { data } = await axios.get(`/customer/payment-methods`);
      setPaymentMethods(data.data);
      dispatch(paymentCardsList(data));
    } catch (error) {
      // please handle errors
    }
  };

  useEffect(() => {
    if (publishableKey === null) {
      getPublishableKey();
      getSetupIntent();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishableKey]);

  useEffect(() => {
    if (paymentMethods === null) {
      getPaymentMethods();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container xs={12} md={12} lg={12}>
      {setupIntent && publishableKey ? (
        <Grid container item justify='center' alignItems='center' xs={12} md={12} lg={12}>
          <StripeCardContainer
            btnVisiblility={true}
            setupIntent={setupIntent}
            publishableKey={publishableKey}
            path={path}
            addOrdersWithNewCard ={addOrdersWithNewCard}
          />
        </Grid>
      ) : (
        <Grid container item justify='center' alignItems='center'>
          <CircularProgress color='inherit' />
        </Grid>
      )}
    </Grid>
  );
}
