import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import StripeContainer from './StripeContainer';
import CardDuplicates from './CardDuplicates';
import ShowCards from './ShowCards';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { paymentCardsList } from "../../../store/actions/authActions";

const accountEmptylist = {
  textAlign: 'center',
  color: '#aaa',
  fontSize: '1.2em',
  padding: '40px 20px',
  background: '#efefef',
  marginBottom: '10px',
  borderRadius: '10px',
};

const path = `${window.location.origin}/account/payments/setup-complete`;

export default function CardElement() {
  const dispatch = useDispatch();
  
  const axios = useAxiosPrivate();
  const [setupIntent, setSetupIntent] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);
  const [publishableKey, setPublishableKey] = useState(null);
  const [open, setOpen] = useState(false);
  const [duplicates, setDuplicates] = useState(null);
  const [loading, setLoading] = useState(true);

  const getSetupIntent = async () => {
    try {
      // if (setupIntent !== null) {
      //   return;
      // }
      getPublishableKey();
      const { data } = await axios.get(
        `/customer/setup-intent/${window.sessionStorage.getItem('id')}`
      );
      setSetupIntent(data);
    } catch (error) {
      // getSetupIntent();
      // please handle errors
    }
  };

  const getPublishableKey = async () => {
    try {
      const { data } = await axios.get('/customer/publishable-key');
      setPublishableKey(data.publishableKey);
      setTimeout(() => {
        window.sessionStorage.setItem('publishableKey', data.publishableKey);
      }, 0);
    } catch (error) {
      // lgetPublishableKey();
      // please handle errors
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/customer/payment-methods`);
      setPaymentMethods(data.cardList);
      setDefaultCard(data.defPayment);
      setDuplicates(data.duplicates);
      const setTrue = data.duplicates.length ? true : false;
      setOpen(setTrue);
      setLoading(false);
      dispatch(paymentCardsList(data));
    } catch (error) {
      // please handle errors
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPublishableKey();
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetData = () => {
    setSetupIntent(null)

  }

  return (
    <Grid container spacing={1} xs={12} md={12} lg={12}>
      {/* {setupIntent === null && (
        <Grid
          item
          alignItems='flex-end'
          alignContent='right'
          justifyContent='flex-end'
          style={{ textAlign: 'right', marginBottom: "2rem" }}
          mr={4}
          xs={12}
          md={12}
          lg={12}
        >
          <StyledButton
            variant='contained'
            size='small'
            startIcon={<AddCircleIcon />}
            onClick={getSetupIntent}
          >
            Add a Card
          </StyledButton>
        </Grid>
      )} */}

      <Grid item mt={1} xs={12} md={12} lg={12}>
        <>
          {setupIntent && publishableKey ? (
            <Grid
              item
              mt={1}
              /*alignContent='center' alignItems='center' direction="column"*/ xs={
                12
              }
              md={12}
              lg={12}
            >
              <StripeContainer resetData={resetData} setupIntent={setupIntent} path={path} />
            </Grid>
          ) : paymentMethods ? (
            <Grid item mt={1} xs={12} md={12} lg={12}>
              <ShowCards
                cards={paymentMethods}
                getPaymentMethods={() => getPaymentMethods()}
                defaultCard={defaultCard}
                loadingSetter={() => setLoading(true)}
                loadingOffSetter={() => setLoading(false)}
                getSetupIntent={getSetupIntent}
              />
            </Grid>
          ) : loading ? (
            <Grid container item justify='center' alignItems='center'>
              <CircularProgress color='inherit' />
            </Grid>
          ) : (
            <Grid item mt={1} xs={12} md={12} lg={12}>
              <div style={accountEmptylist}>
                <p>No credit cards added yet</p>
              </div>
            </Grid>
          )}
        </>
      </Grid>

      {duplicates && open && (
        <CardDuplicates
          duplicates={duplicates}
          getPaymentMethods={() => getPaymentMethods()}
          setOpen={setOpen}
          open={open}
        />
      )}
    </Grid>
  );
}
