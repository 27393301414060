import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import DialogAlertMain from "components/common/DialogAlertMain";
import MyAccount from "screens/myAccount/MyAccount";
import { makeStyles } from "@material-ui/core/styles";
import Pickups from "./Pickups";
import Delivery from "./Delivery";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import SpecialCare from "./Special";
// import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { reset } from '../../../store/actions/orderAction';


const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "0rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  headerTypo: {
    color: "#457FC2",
    fontWeight: "700",
    fontSize: "1.2rem",
    fontFamily: '"Lato",sans-serif',
    marginBottom: "2rem",
  },

  headerTypo2: {
    color: "#457FC2",
    fontWeight: "700",
    fontSize: "1.2rem",
    fontFamily: '"Lato",sans-serif',
    marginBottom: "2rem",
    marginTop: "2rem",
  },
}));

const OrderButton2 = styled(Button)({
  color: "#fff",
  padding: "0.3rem",
  backgroundColor: "#2cca5c",
  textTransform: "none",

  "&:hover": {
    backgroundColor: "#23a24a",
  },
  "&:active": {
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

export default function Upcoming() {
  const axios = useAxiosPrivate();
  const classes = useStyles();

  const [loadingData, setLoadingData] = useState(false);


  const navigate = useNavigate();
  const dispatch = useDispatch();


  // let location = useSelector((state) => state.auth);

  const [orders, setOrders] = React.useState([]);
  const [specialCare, setSpecialCare] = React.useState([]);
  const [normal, setNormal] = React.useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const getData = async () => {

    setLoadingData(true)

    try {
      const { data } = await axios.get("/customer/upcoming-services");
      setOrders(data);
        setSpecialCare(data.specialData);
        setNormal([...data.deliveryData, ...data.pickupData]);
        setLoadingData(false)

    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Something went wrong",
          message: "",
        });
      }, 3000);
    }
  };

  const isTrackOrderBtnClicked = async () => {
    await getData();
  }


  const DirectToOrder = () => {
    dispatch(reset());
    navigate("/order");
  };



  useEffect(() => {
    getData();
    //eslint-disable-next-line
  }, []);

  return (
    <MyAccount>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>

          <Grid item container xs={12} md={12} lg={12}>
            <Grid item
              lg={9}
              md={9}
              sm={12}
              xs={12}
            >
              <Typography variant="h4" className={classes.headerTypo}>
                Upcoming Pick-ups
              </Typography>
            </Grid>

            <Grid item
              lg={3}
              md={3}
              sm={12}
              xs={12}
            >
              <OrderButton2
                sx={{

                  width: {
                    lg: "100%",
                    md: "100%",
                    sm: "20rem",
                    xs: "20rem",
                  },
                }}
                variant="contained"
                color="success"
                onClick={() => DirectToOrder()}
                startIcon={<AddCircleIcon />}
              >
                Start an Order!
              </OrderButton2>

            </Grid>
          </Grid>

          <Pickups
            pickups={orders?.pickupData}
            loadingData={loadingData}
            isTrackOrderBtnClicked={isTrackOrderBtnClicked}
           />
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt={5}>
          <Typography variant="h4" className={classes.headerTypo2}>
            Upcoming Deliveries
          </Typography>
          <Delivery
            delivery={orders?.deliveryData}
            specialCare={specialCare}
            normal={normal}
            loadingData={loadingData}
            isTrackOrderBtnClicked={isTrackOrderBtnClicked}
          />
          <Typography variant="h4" className={classes.headerTypo2}>
            Special Care Deliveries
          </Typography>
          <SpecialCare 
            specialCare={specialCare}
            loadingData={loadingData}
            isTrackOrderBtnClicked={isTrackOrderBtnClicked}
           />
        </Grid>
      </Grid>
        {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
    </MyAccount>
  );
}
