import React, { useState } from "react";
import {
  Box,
  // Button,
  Grid, Typography
} from "@mui/material";
import Stepper from "./Stepper";
import { useEffect } from "react";


const OrderStatus = ({ resOrder, from}) => {
  //eslint-disable-next-line
  const [order, setOrder] = useState({
    orderId: "Loading...",
    shippedPlace: "Loading...",
    orderStatus: "Loading...",
    specialCareTracker: "Loading...",
    dateLabel: "Loading...",
    date: "Loading...",
  });
  
  const assignValues = () => {
    setTimeout(() => {
      if (resOrder.specialCareTracker === null || resOrder.specialCareTracker === undefined) {

        switch (resOrder.statusTracker) {
          case "placingOrder":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Order is Placed", dateLabel: null,
              date: null, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "setToPickup":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Set to pickup", dateLabel: "Pickup Date",
              date: resOrder.pickupDate, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "pickup":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Pickedup", dateLabel: "Delivery date",
              date: resOrder.diliverDate, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "pickupCancel":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Pickup Canceled", dateLabel: null,
              date: null, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "processing":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Processing",
              dateLabel: "Delivery date", date: resOrder.diliverDate, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "setToDeliver":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Set to delivery",
              dateLabel: "Delivery date", date: resOrder.diliverDate, isSpecial: resOrder.specialDate ? true : false, });
            break
          case "deliveryCancel":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Delivery Canceled",
              dateLabel: null, date: null, isSpecial: resOrder?.specialDate ? true : false, });
            break
          case "completed":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Delivered", dateLabel: null,
              date: null, isSpecial: resOrder?.specialDate ? true : false, });
            break
          default:
            setOrder("no value passed");
        }
      } else if (
        // (resOrder.statusTracker === "deliveryCancel" || resOrder.statusTracker === "completed") && 
        resOrder.specialCareTracker !== null || resOrder.specialCareTracker !== undefined
      ) {

        //specialcare track
        switch (resOrder.specialCareTracker) {
          case "specialSetToDeliver":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Set to delivery (Special Care)",
              dateLabel: "Special Care Delivery date", date: resOrder?.specialDate ? resOrder?.specialDate : resOrder?.diliverDate,
              isSpecial: resOrder?.specialDate ? true : false, normalOrderStatus: resOrder.statusTracker, });
            break
          case "specialDeliveryCancel":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Delivery Canceled (Special Care)",
              dateLabel: null, date: null, isSpecial: resOrder?.specialDate ? true : false, normalOrderStatus: resOrder.statusTracker, });
            break
          case "specialCompleted":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Delivered (Special Care)",
              dateLabel: null, date: null, isSpecial: resOrder?.specialDate ? true : false, normalOrderStatus: resOrder.statusTracker, });
            break
          default:
            setOrder("no value passed");
        }
      } else if (from === "placed") {  
        switch (resOrder.statusTracker) {
          case "placingOrder":
            setOrder({ orderId: resOrder.orderId, shippedPlace: resOrder.shippedPlace, orderStatus: "Order is Placed", dateLabel: null,
              date: null, isSpecial: resOrder?.specialDate ? true : false, });
            break
            default:
              setOrder("no value passed");
        }
      } else { 
  //eslint-disable-next-line
        console.log("Normal Delivery need to be finish before Special Care delivery to be shown or pickup can't be in canceled state.", resOrder.orderId)
      }
  }, 300);
}

  useEffect(() => {
    if (order.orderId === "Loading...") { assignValues(); }
  //eslint-disable-next-line
}, [resOrder]);

return (
  <Grid container justifyContent="center" alignItems="center" >
    <Grid container item justifyContent="center" >
      <Box sx={{ border: "2px solid #35BFFF", minHeight: { lg: "19rem", md: "20rem", sm: "45rem", xs: "49rem", }, backgroundColor:"white", marginTop:"1rem"}} >
        <Grid item container direction="row" justifyContent="center" alignItems="center" xs={12} >
          <Grid container item sx={{ bgcolor: "#35BFFF", color: "white", width: "100%", minHeight: "100%", }} >
            <Grid item container justifyContent="center" padding="0.4rem">
              <Typography data-testid="order-tracking" variant="h6" fontWeight="700" sx={{ textAlign: "center", }} > ORDER TRACKING : #{order.orderId} </Typography>
            </Grid>
          </Grid>
          {/* <Grid container item direction="row" marginBottom={{ lg: 0, md: 0, sm: 0, xs: 3, }} marginTop={{ lg: 0, md: 0, sm: 3, xs: 3, }}
            paddingLeft={{ lg: 3, md: 3, sm: 0, xs: 0, }} paddingRight={{ lg: 3, md: 3, sm: 0, xs: 0, }} >
            {(order.date !== null && (
              <Grid item container lg={4} md={4} sm={12} xs={12}
                justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center", }}
                alignItems={{ lg: "center", md: "center", sm: "center", xs: "center", }}>
                <Typography fontSize="11px" sx={{ textAlign: { lg: "left", md: "left", sm: "center", xs: "center", } }} > Shipped via <br />
                  <Typography fontSize="14px" fontWeight="800"> {order.shippedPlace} </Typography>
                </Typography>
              </Grid>
            )) || (order.date === null && (
                <Grid item container paddingTop={{ lg: 3, md: 3, sm: 1, xs: 1, }} paddingBottom={{ lg: 3, md: 3, sm: 1, xs: 1, }}
                  paddingLeft={{ lg: 3, md: 3, sm: 0, xs: 0, }} paddingRight={{ lg: 3, md: 3, sm: 0, xs: 0, }}
                  lg={5} md={5} sm={12} xs={12} justifyContent={{ lg: "flex-start", md: "flex-start", sm: "center", xs: "center", }}
                  alignItems={{ lg: "center", md: "center", sm: "center", xs: "center", }} marginLeft={{ lg: "35px", md: "35px", sm: "35px", xs: "0", }} >
                  <Typography fontSize="11px" sx={{ textAlign: { lg: "left", md: "left", sm: "center", xs: "center", } }}> Shipped via <br />
                    <Typography fontSize="14px" fontWeight="800"> {order.shippedPlace} </Typography>
                  </Typography>
                </Grid> ))}
            {(order.date !== null && (
              <Grid item container marginBottom={{ lg: 0, md: 0, sm: 0, xs: 3, }}
                marginTop={{ lg: 0, md: 3, sm: 3, xs: 3, }} paddingLeft={{ lg: 3, md: 3, sm: 0, xs: 0, }}
                paddingRight={{ lg: 3, md: 3, sm: 0, xs: 0, }}
                lg={4} md={4} sm={12} xs={12}
                justifyContent={{ lg: "center", md: "center", sm: "center", xs: "center", }}
                alignItems={{ lg: "center", md: "flex-start", sm: "flex-start", xs: "center", }}>
                <Typography fontSize="11px" sx={{ textAlign: { lg: "left", md: "center", sm: "center", xs: "center", } }}> Status <br />
                  <Typography fontSize="14px" fontWeight="800"> {order.orderStatus} {resOrder?.orderType==="4" && "(Special Care)"} </Typography>
                </Typography>
              </Grid>
            )) || (order.date === null && (
                <Grid item container
                  paddingTop={{ lg: 3, md: 3, sm: 1, xs: 1, }}
                  paddingBottom={{ lg: 3, md: 3, sm: 1, xs: 1, }}
                  paddingLeft={{ lg: 3, md: 3, sm: 0, xs: 0, }}
                  paddingRight={{ lg: 3, md: 3, sm: 0, xs: 0, }}
                  lg={5} md={5} sm={12} xs={12}
                  justifyContent={{ lg: "flex-end", md: "flex-end", sm: "center", xs: "center", }}
                  alignItems={{ lg: "flex-end", md: "flex-start", sm: "flex-start", xs: "center", }}
                  marginLeft={{ lg: "35px", md: "35px", sm: "35px", xs: "0", }} >
                  <Typography fontSize="11px" sx={{ textAlign: { lg: "left", md: "left", sm: "center", xs: "center", } }}> Status <br />
                    <Typography fontSize="14px" fontWeight="800"> {order.orderStatus} </Typography>
                  </Typography>
                </Grid> ))}
            {order.date !== null && ( <Grid item container padding={3} lg={4} md={4} sm={12} xs={12}
                justifyContent={{ lg: "flex-end", md: "flex-end", sm: "center", xs: "center", }}
                alignItems={{ lg: "center", md: "flex-start", sm: "flex-start", xs: "center", }}>
                <Typography fontSize="11px"
                  sx={{ textAlign: { lg: "left", md: "left", sm: "center", xs: "center", } }}> {order.dateLabel}  <br />
                  <Typography fontSize="14px" fontWeight="800"> {order.date} </Typography>
                </Typography>
              </Grid> )}
          </Grid> */}
          <Grid container item
            sx={{paddingTop:"5rem"}}
            padding={1}>
            <Stepper order={order} from={from} />
          </Grid>
          {/* <Grid container item justifyContent="center" marginTop={2}>
            <Button sx={{ width: { lg: "23rem", md: "23rem", sm: "23rem", xs: "91%", },
                minHeight: "100%", bgcolor: "#0073a8", color: "white", fontSize: "14px",
                ":hover": { bgcolor: "#016694", color: "white", }, }} > INQUIRY :
              <a href="tel:+19159969739" style={{ textDecoration: "none", color: "white" }} > +1 915 9969739 </a>
            </Button>
          </Grid> */}
        </Grid>
      </Box>
    </Grid>
  </Grid>
);
};

export default OrderStatus;
