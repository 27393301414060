import {
  ORDER,
  PICKUP,
  DELIVERY,
  DELIVERY2,
  ADDORDER,
  ADDITEMS,
  ADDTITEMS,
  RESET,
  SET_REWARDS,
  SET_PROMO,
  SET_DISCOUNT,
  SET_ORDERID,
} from "../actions/types";

const initialState = {};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case ORDER:
      sessionStorage.setItem(
        "orderNumber",
        JSON.stringify({ ...action.payload.orderNumber })
      );
      return { ...state, ...action.payload };

    case PICKUP:
      sessionStorage.setItem(
        "pickup",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };

    case DELIVERY:
      sessionStorage.setItem(
        "delivery",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };
    case DELIVERY2:
      sessionStorage.setItem(
        "delivery2",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };

    case ADDORDER:
      sessionStorage.setItem(
        "addorder",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };
    case ADDITEMS:
      sessionStorage.setItem(
        "additems",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };

    case ADDTITEMS:
      sessionStorage.setItem(
        "addTitems",
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };

    case SET_REWARDS:
      sessionStorage.setItem(
        'addRewards',
        JSON.stringify({ ...action.payload })
      );
      return {
        ...state,
        ...action.payload,
      };

    case SET_PROMO:
      sessionStorage.setItem(
        'addPromo',
        JSON.stringify({ ...action.payload })
      );
      return {
        ...state,
        ...action.payload,
      };


    case SET_ORDERID:
      sessionStorage.setItem(
        'orderId',
        JSON.stringify({ ...action.payload.data })
      );
      return {
        ...state,
        ...action.payload,
      };



    case SET_DISCOUNT:
      // sessionStorage.setItem(
      //   'addTitems',
      //   JSON.stringify({ ...action.payload.data })
      // );
      return {
        ...state,
        ...action.payload,
      };



    case RESET:
      sessionStorage.removeItem("orderNumber");
      sessionStorage.removeItem("combinedPickupNote");
      sessionStorage.removeItem("pickup");
      sessionStorage.removeItem("delivery");
      sessionStorage.removeItem("delivery2");
      sessionStorage.removeItem("addorder");
      sessionStorage.removeItem("additems");
      sessionStorage.removeItem("addTitems");
      sessionStorage.removeItem("addRewards");
      sessionStorage.removeItem("addPromo");
      sessionStorage.removeItem("orderId");
      delete state.rewards;
      delete state.promo;
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
