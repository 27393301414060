import React, { useState, useEffect, useMemo, useRef } from "react";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSearchParams } from "react-router-dom";

import {
  WashDryCard,
  DryCleaningCard,
  AlterationsCard,
} from "../services/ServiceCard";
import styles from "../../styles/services.module.scss";

import ZipCodePopup from "../services/ZipCodePopup";
import {
  WashDryCardDisplay,
  DryCleanDisplay,
  Alternation,
} from "../services/DisplayCard";
import ServiceAlert from "../services/ServiceNotAvailable";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },

  cardStyle: {
    textAlign: "center",
    paddingTop: "4rem",
    paddingBottom: "4rem",

    marginLeft: "16rem",
    marginRight: "16rem",

    [theme.breakpoints.down(1684)]: {
      marginLeft: "10rem",
      marginRight: "10rem",
    },

    [theme.breakpoints.down(1433)]: {
      marginLeft: "0rem",
      marginRight: "0rem",
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: "11rem",
      marginRight: "11rem",
    },

    [theme.breakpoints.down(760)]: {
      marginLeft: "0rem",
      marginRight: "0rem",
    },



  },

  headerstyle: {
    marginLeft: "1rem",
    marginRight: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2rem",
      marginRight: "2rem",


    },
  },

}));



const setItem = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}

const removeItem = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.removeItem(key);
  } return value;
}

//zipcode

const setZipcode = (key, value) => {
  if (typeof window !== 'undefined') {
    window.sessionStorage.setItem(key, value);
  }
}

const getZipcode = (key) => {
  let value = {};
  if (typeof window !== 'undefined') {
    value = window.sessionStorage.getItem(key);
  } return value;
}

export default function Services() {

  const scrollRef = useRef(null)

  const classes = useStyles();
  const [washDry, setWashDry] = useState({});
  const [dryClean, setDryClean] = useState([]);
  const [alterations, setAlternation] = useState([]);
  const [WashDrynumber, setWashDrynumber] = useState(false);
  const [DryCleannumber, setDryCleannumber] = useState(false);
  const [Alternationnumber, setAlternationnumber] = useState(false);
  const [servicveAlert, setServicveAlert] = useState(false);


  const [searchParams] = useSearchParams();

  const { token, zipCode } = useMemo(() => {
    return {
      token: searchParams.get("token"),
      zipCode: searchParams.get("q"),
    }
  }, [searchParams]);

  // const zipCode = router.query.q;

  const [nextToken, setsessionToken] = useState(false)
  const [checkZip, setGotZip] = useState(false)

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    // after done change to true
  };


  const scrollFunc = () => {

    setTimeout(() => {
      scrollRef.current.scrollIntoView({ block: "start" })
    }, 10);
  }


  useEffect(() => {

    handleClickOpen();
    if (token) {
      setItem('accessToken', token);
      setZipcode('defaultZipCode', zipCode);
    }
    const value = getItem('accessToken');
    setsessionToken(value);

    const zipValue = getZipcode('defaultZipCode');
    setGotZip(zipValue);
    // console.log(zipValue)
    // else window.sessionStorage.removeItem('accessToken', token);
    // eslint-disable-next-line 
  }, [token])

  return (
    <Grid container
      justifyContent="center"
      alignItems="center"
    >
      <ServiceAlert setServicveAlert= {setServicveAlert}  servicveAlert= {servicveAlert}/>
      <Grid container item
        lg={12}

        className={classes.root}
        justifyContent="center">

        <Grid item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={styles.content_bg}>

          <div className={classes.headerstyle}>
            <h2 className={styles.title}>Our Services</h2>

            <p className={styles.para1}>
              Let us handle your Dry Cleaning {"&"} Wash / Dry / Fold. We even
              pick up and deliver for free.
            </p>
          </div>

        </Grid>
      </Grid>

      <Grid container item
        lg={12}
        md={12}
        sm={8}
        xs={12}
        spacing={3}
        className={classes.cardStyle}
      >

        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.serviceCard}>
          <WashDryCard
            scrollFunc={scrollFunc}
            selfNumber={WashDrynumber}
            DryCleannumber={DryCleannumber}
            Alternationnumber={Alternationnumber}
            setDryCleannumber={setDryCleannumber}
            setAlternationnumber={setAlternationnumber}
            setNumber={setWashDrynumber} />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.serviceCard}>
          <DryCleaningCard
            scrollFunc={scrollFunc}
            selfNumber={DryCleannumber}
            WashDrynumber={WashDrynumber}
            Alternationnumber={Alternationnumber}
            setWashDrynumber={setWashDrynumber}
            setAlternationnumber={setAlternationnumber}
            setNumber={setDryCleannumber} />
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12} className={classes.serviceCard}>
          <AlterationsCard
            scrollFunc={scrollFunc}
            selfNumber={Alternationnumber}
            WashDrynumber={WashDrynumber}
            DryCleannumber={DryCleannumber}
            setDryCleannumber={setDryCleannumber}
            setWashDrynumber={setWashDrynumber}
            setNumber={setAlternationnumber} />
        </Grid>
      </Grid>
      <Grid ref={scrollRef} container mt={2} className={classes.root}>
        {!Alternationnumber && !DryCleannumber && WashDrynumber ? (
          <WashDryCardDisplay washDry={washDry} />
        ) : !Alternationnumber && !WashDrynumber && DryCleannumber ? (
          <DryCleanDisplay dryClean={dryClean} />
        ) : !WashDrynumber && !DryCleannumber && Alternationnumber ? (
          <Alternation alterations={alterations} />
        ) : (
          ""
        )}
      </Grid>
      <ZipCodePopup
        open={open}
        setOpen={setOpen}
        setWashDry={setWashDry}
        setDryClean={setDryClean}
        setAlternation={setAlternation}
        zipCode={checkZip}
        setServicveAlert={setServicveAlert}
      />
    </Grid>
  );
}
