import { LOGIN, LOGOUT,ZIPCODE,DEFAULTADRESS,DEFAULTLOCATION, PAYMENTCARDLIST} from "../actions/types";

const initialState = {
  accessToken: sessionStorage.getItem("accessToken"),
  id: sessionStorage.getItem("id"),
  defaultZipCode: sessionStorage.getItem("defaultZipCode"),
  defaultAddress: sessionStorage.getItem("defaultAddress"),
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, ...action.payload };

    case LOGOUT:
      return {
        // accessToken: "",
      };

    case ZIPCODE:
      sessionStorage.setItem("defaultZipCode", action.payload.defaultZipCode);
      return {
        ...state, ...action.payload
      }

    case DEFAULTADRESS:
      sessionStorage.setItem("defaultAddress", action.payload.defaultAddress);
      return {
        ...state, ...action.payload
      } 

      case DEFAULTLOCATION:
        sessionStorage.setItem("defaultLocation", JSON.stringify(action.payload.defaultLocation));
        return {
          ...state, ...action.payload
      }   
      case PAYMENTCARDLIST:
        return { ...state, ...action.payload };

    default:
      return state;
  }
}
