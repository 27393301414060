import React, { useEffect, useState, useRef } from "react";
import PageHeader from "components/UI/PageHeader";
import Divider from "@mui/material/Divider";
import { useStyles } from "./classes";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Grid } from "@material-ui/core";
import FilledInput from "@mui/material/FilledInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { addorder, setDiscount, additems, addTitems } from "../../store/actions/orderAction";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import GetCoupons from "./redeemedCoupon/getRedeemedCoupons";
import SavedCards from "./SavedCards";

export default function PlaceOrder() {
  const axios = useAxiosPrivate();
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
  let states = useSelector((state) => state);
  const coupon = useSelector((state) => state.order?.rewards?.couponVal?.item);
  const promo = useSelector((state) => state.order?.promo);
  const [pageLoading, setPageLoading] = useState(true);
  const [removeClicked, setRemoveClicked] = useState(0);
  let processingFee = 5;
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(null);
  let extraAmount = sessionStorage.getItem('setExtraAmount');
  const [values, setValues] = useState({ amount: extraAmount > 0 ? extraAmount : "", proCode: "", coupon: "", });
  const [card, setCard] = useState(null);
  const orderNumber = JSON.parse(sessionStorage.getItem("orderNumber"));
  const [userData, setUserData] = useState([]);
  const [list, setList] = useState([]);
  const [buttonDiabled, setButtonDiabled] = useState(false)

  const [isWashDryFoldAvailable, setIsWashDryFoldAvailable] = useState(false)
  const [isSpecialCareAvailable, setIsSpecialCareAvailable] = useState(false)
  const [isWashDataLoading, setIsWashDataLoading] = useState(true)


  const isWashDryFoldServiceSelected = !(orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4") && userData?.price ? true : false

  const setServiceAvailability = (rewards) => {
    if (rewards) {
      const services = rewards.services;
      setIsWashDryFoldAvailable(services.includes("Wash/ Dry/ Fold"));
      setIsSpecialCareAvailable(services.includes("Dry Cleaning") || services.includes("Tailoring & Alterations"));
    }else{
      setIsWashDryFoldAvailable(false);
      setIsSpecialCareAvailable(false);
    }
  }

  useEffect(() => {

    if(promo) setServiceAvailability(promo)
    if(coupon) setServiceAvailability(coupon)

  },[promo,coupon])

  const getSession = () => {
    const item = JSON.parse(sessionStorage.getItem("additems"));
    if (item === null) { setList([]); }
    else { let itemArray = []; for (let x in item) { itemArray.push(item[x]); } setList(itemArray); }
  };
  const [list2, setList2] = useState([]);
  const getSession2 = () => {
    const item = JSON.parse(sessionStorage.getItem("addTitems"));
    if (item === null) { setList2([]); }
    else { let itemArray = []; for (let x in item) { itemArray.push(item[x]); } setList2(itemArray); }
  };
  const handleChange = (prop) => (event) => {
    if (prop === "amount" && event.target.value < 0) { return }
    else { 
      setValues({ ...values, [prop]: event.target.value }); 
      if(prop === "amount") sessionStorage.setItem('setExtraAmount', event.target.value);
    }
  };
  const loadData = async () => {
    setIsWashDataLoading(true)
    const { data } = await axios.post("/franchiseeService/wash", { zipCode: zipCode, });
    if (removeClicked === 0) {
       setUserData(data) 
       setIsWashDataLoading(false)
      }
    };
  //Start remove items from cart
  function removeItems(id, type, listType) {
    let obj = list.filter(o => o._id !== id);
    let obj2 = list2.filter(o => o._id !== id);
const deliveryVal = JSON.parse(sessionStorage.getItem("delivery"))
    switch (type) {
      case '1':
        //remove Wash/Dry/Fold items from session storage
        sessionStorage.removeItem("additems");
        setUserData([]);
        navigate("/order");
        break;
      case '2':
        setRemoveClicked(oldValue => oldValue + 1)
        setList(obj)
        window.sessionStorage.setItem("additems", JSON.stringify(obj))
        if (obj.length === 0) { setUserData([]); }
        break;
      case '3':
        setRemoveClicked(oldValue => oldValue + 1)
        setList2(obj2)
        window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))
        if (obj2.length === 0) { setUserData([]); }
        break;
      case '4':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list") {
          
          setList(obj)
          window.sessionStorage.setItem("additems", JSON.stringify(obj))
          if (obj2.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
           let newUpdatedDeliveryData = {
            diliverDate: updatedDeliveryData.specialDate,
            diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
            deliverySchedule: updatedDeliveryData.deliverySchedule
        };
            if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
              sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
              sessionStorage.removeItem("delivery2");
            }


          } else if (obj.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          } else if (obj.length < 1 && deliveryVal) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          }
        }
        else {
          setList2(obj2)
          window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))

          if (obj2.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          } else if (obj.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          }

          if (obj2.length < 1 && deliveryVal) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          }
        }
        if (obj.length === 0 && obj2.length === 0) { setUserData([]); }
        break;
      case '5':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list2") {
          setList2(obj2)
          window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))
          if (obj.length < 1 && obj2.length < 1) {
            sessionStorage.removeItem("delivery2");
            let updatedDataTypeFive = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedDataTypeFive.orderType = "1";
          if(updatedDataTypeFive.orderType === "1") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedDataTypeFive));
            }
          }else if (obj.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          }
        } else if (listType === "washDryFold") {
          setUserData(oldValue => ({ ...oldValue, price: null }));
        
          let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
          updatedData.orderType = "3";
          if (updatedData.orderType === "3") {
            sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
          }

          const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
          let newUpdatedDeliveryData = {
           diliverDate: updatedDeliveryData.specialDate,
           diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
           deliverySchedule: updatedDeliveryData.deliverySchedule
       };
           if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
             sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
             sessionStorage.removeItem("delivery2");
           }
        }
        break;
      case '6':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list2") {
          setList2(obj2)
          window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))
        } else if (listType === "washDryFold") {
          setUserData(oldValue => ({ ...oldValue, price: null }));
        }
        break;
      case '7':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list") {
          setList(obj)
          window.sessionStorage.setItem("additems", JSON.stringify(obj))
          if (obj.length < 1 && obj2.length < 1) {
            sessionStorage.removeItem("delivery2");
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "1";
            if (updatedData.orderType === "1") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          } else if (obj2.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          }
        } else if (listType === "washDryFold") {
          setUserData(oldValue => ({ ...oldValue, price: null }));

            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
        
        }
        break;
      case '8':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list") {
          setList(obj)
          window.sessionStorage.setItem("additems", JSON.stringify(obj))
        } else if (listType === "washDryFold") {
          setUserData(oldValue => ({ ...oldValue, price: null }));
        }
        break;
      case '9':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list") {
          setList(obj)
          window.sessionStorage.setItem("additems", JSON.stringify(obj))
          if (obj.length < 1 && obj2.length < 1) {
            sessionStorage.removeItem("delivery2");
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "1";
            sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
          } else if (obj.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
            }
            
          } else if (obj.length < 1 && deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "5";
            if (updatedData.orderType === "5") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          } else if (obj2.length < 1 && !deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "2";
            if (updatedData.orderType === "2") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }

            const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
            let newUpdatedDeliveryData = {
             diliverDate: updatedDeliveryData.specialDate,
             diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
             deliverySchedule: updatedDeliveryData.deliverySchedule
         };
             if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
               sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
               sessionStorage.removeItem("delivery2");
             }
          } else if (obj.length < 1 && deliveryVal ) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "7";
            if (updatedData.orderType === "7") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          }

        } else if (listType === "list2") {
          setList2(obj2)
          window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))
          if (obj.length < 1 && obj2.length < 1) {
            sessionStorage.removeItem("delivery2");
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "1";
            if (updatedData.orderType === "1") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          } else if (obj.length < 1) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "3";
            if (updatedData.orderType === "3") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          } else if (obj2.length < 1) {
            let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
            updatedData.orderType = "7";
            if (updatedData.orderType === "7") {
              sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
            }
          }
        } else if (listType === "washDryFold") {
          setUserData(oldValue => ({ ...oldValue, price: null }));

          let updatedData = JSON.parse(sessionStorage.getItem("orderNumber"))
          updatedData.orderType = "4";
          if (updatedData.orderType === "4") {
            sessionStorage.setItem("orderNumber", JSON.stringify(updatedData));
          }

          const updatedDeliveryData = JSON.parse(sessionStorage.getItem("delivery2"))
          let newUpdatedDeliveryData = {
           diliverDate: updatedDeliveryData.specialDate,
           diliverTimeSlot: updatedDeliveryData.specialTimeSlot,
           deliverySchedule: updatedDeliveryData.deliverySchedule
       };
           if (newUpdatedDeliveryData.diliverDate === updatedDeliveryData.specialDate) {
             sessionStorage.setItem("delivery", JSON.stringify(newUpdatedDeliveryData));
             sessionStorage.removeItem("delivery2");
           }
        }
        break;
      case '10':
        setRemoveClicked(oldValue => oldValue + 1)
        if (listType === "list") {
          setList(obj)
          window.sessionStorage.setItem("additems", JSON.stringify(obj))
        }
        else if (listType === "list2") {
          setList2(obj2)
          window.sessionStorage.setItem("addTitems", JSON.stringify(obj2))
        }
        else if (listType === "washDryFold") { setUserData(oldValue => ({ ...oldValue, price: null })); }
        break;
      default:
        console.log(id, type, "Other Type");
        break;
    }
  }
  //END remove items from cart
  const executeScroll = () => myRef.current.scrollIntoView();
  const Total = (list) => {
    let total = 0; 
    for (let x in list) {
       total += Number(list[x].itemId.price.toFixed(2)) * list[x].itemId.quantity;
       }
       
    return total;
  };
    
  let total = orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4" ? Total(list) + Total(list2) + Math.abs(Number(values.amount)) + Number(processingFee) : Number(userData.price * 15) + Total(list) + Total(list2) + Math.abs(Number(values.amount)) + Number(processingFee);

  const addOrders = async (data) => {
    const specialCarePrice = Total(list) + Total(list2);
    let rewardDiscount = total - calcSubTotal();
    dispatch(addorder({
      servicePrice: userData.price * 15, specialCarePrice: specialCarePrice, extra: Math.abs(Number(values.amount)),
      processingFee: processingFee, totalPrice: orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4" ? Total(list) + Total(list2) + Math.abs(Number(values.amount)) : Number(userData.price * 15) + Total(list) + Total(list2) + Math.abs(Number(values.amount)),
      subtotal: orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4" ? Total(list) + Total(list2) : Number(userData.price * 15) + Total(list) + Total(list2),
      franchiseeId: userData.franchiseeId, card: card, rewardDiscount: rewardDiscount,
    }));
    dispatch(setDiscount(rewardDiscount));
    navigate("/confirmOrder");
  };

  const addOrdersWithNewCard = async (data) => {
    const specialCarePrice = Total(list) + Total(list2);
    let rewardDiscount = total - calcSubTotal();
    dispatch(addorder({
      servicePrice: userData.price * 15, specialCarePrice: specialCarePrice, extra: Math.abs(Number(values.amount)),
      processingFee: processingFee, totalPrice: orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4" ? Total(list) + Total(list2) + Math.abs(Number(values.amount)) : Number(userData.price * 15) + Total(list) + Total(list2) + Math.abs(Number(values.amount)),
      subtotal: orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4" ? Total(list) + Total(list2) : Number(userData.price * 15) + Total(list) + Total(list2),
      franchiseeId: userData.franchiseeId, card: card, rewardDiscount: rewardDiscount,
    }));
    dispatch(setDiscount(rewardDiscount));
  };


  

  const calcSubTotal = () => {
    // coupon or promocode
    let subtotal = 0;
    let SpecialCareTot = 0;

    if(isSpecialCareAvailable){
      SpecialCareTot = Total(list) + Total(list2)
    }

    if (states.order?.rewards?.couponVal?.couponValue) {

      if (states.order?.rewards?.couponVal?.couponType === "percentage") {

        subtotal =
          orderNumber?.orderType === "2" ||
          orderNumber?.orderType === "3" ||
          orderNumber?.orderType === "4"
            ? (Total(list) + Total(list2)) *
              (Number(Math.abs(100 - states.order?.rewards?.couponVal?.couponValue)) / 100)
            : ((isSpecialCareAvailable && !isWashDryFoldAvailable
                ? 0
                : Number(userData.price * 15)) +
                (isSpecialCareAvailable && isWashDryFoldAvailable
                  ? Total(list) + Total(list2)
                  : 0) +
                (isSpecialCareAvailable && !isWashDryFoldAvailable
                  ? Total(list) + Total(list2)
                  : 0)) *
              (Number(
                Math.abs(100 - states.order?.rewards?.couponVal?.couponValue)
              ) /
                100);

        subtotal +=
          Number(processingFee) +
          Math.abs(
            Number(values.amount) +
              (!isSpecialCareAvailable && isWashDryFoldAvailable
                ? Total(list) + Total(list2)
                : 0) +
              (isSpecialCareAvailable && !isWashDryFoldAvailable
                ? orderNumber?.orderType === "2" ||
                  orderNumber?.orderType === "3" ||
                  orderNumber?.orderType === "4"
                  ? 0
                  : Number(userData.price * 15)
                : 0)
          ); 
        
      } else {

        let sumOfTotal = Number(userData.price * 15) + SpecialCareTot 
        
        if (sumOfTotal <= states.order.rewards.couponVal.couponValue) {

           subtotal =
           Math.abs(Number(values.amount)) +
           Number(processingFee) +
           (isWashDryFoldAvailable && !isSpecialCareAvailable
            ? Total(list) + Total(list2)
            : 0) 
            + 
            (isSpecialCareAvailable && !isWashDryFoldAvailable
              ? orderNumber?.orderType === "2" ||
                orderNumber?.orderType === "3" ||
                orderNumber?.orderType === "4"
                ? 0
                : Number(userData.price * 15)

              : 0); 
                      
        }else { 

          subtotal =
            orderNumber?.orderType === "2" ||
            orderNumber?.orderType === "3" ||
            orderNumber?.orderType === "4"
              ? Number(
                  Number(
                    Total(list) +
                      Total(list2) +
                      Math.abs(Number(values.amount)) +
                      Number(processingFee)
                  ).toFixed(2)
                ) -
                (SpecialCareTot <
                  Number(states.order?.rewards?.couponVal?.couponValue) &&
                isSpecialCareAvailable
                  ? Number(SpecialCareTot)
                  : Number(states.order?.rewards?.couponVal?.couponValue))
              : Number(
                  Number(
                    Number(userData.price * 15) +
                      Total(list) +
                      Total(list2) +
                      Math.abs(Number(values.amount)) +
                      Number(processingFee)
                  ).toFixed(2)
                ) -
                (SpecialCareTot <
                  Number(states.order?.rewards?.couponVal?.couponValue) &&
                isSpecialCareAvailable &&
                !isWashDryFoldAvailable
                  ? Number(SpecialCareTot)
                  : Number(states.order?.rewards?.couponVal?.couponValue)); 

         } 
        }
    } else if (promo?.discountValue) {

      if (promo?.discountType === "percentage") {

        subtotal =
          orderNumber?.orderType === "2" ||
          orderNumber?.orderType === "3" ||
          orderNumber?.orderType === "4"
            ? 

            ((!isSpecialCareAvailable && isWashDryFoldAvailable) ? 0 : Total(list) + Total(list2)) *
              (Number(Math.abs(100 - promo?.discountValue)) / 100)

            : ((isSpecialCareAvailable && !isWashDryFoldAvailable ? 0 : Number(userData.price * 15)) +
                (isSpecialCareAvailable && isWashDryFoldAvailable ? Total(list) + Total(list2) : 0) +
                (isSpecialCareAvailable && !isWashDryFoldAvailable ? Total(list) + Total(list2) : 0)) *
                 (Number(Math.abs(100 - promo?.discountValue)) / 100);

        subtotal +=
          Number(processingFee) +
          Math.abs(
            Number(values.amount) +
              (!isSpecialCareAvailable && isWashDryFoldAvailable
                ? Total(list) + Total(list2)
                : 0) +
              (isSpecialCareAvailable && !isWashDryFoldAvailable
                ? orderNumber?.orderType === "2" ||
                  orderNumber?.orderType === "3" ||
                  orderNumber?.orderType === "4"
                  ? 0
                  : Number(userData.price * 15)
                : 0)
          ); 

      } else {  

        let sumOfTotal2 = ((orderNumber?.orderType === "1" || orderNumber?.orderType === "9" || orderNumber?.orderType === "10" || orderNumber?.orderType === "7" || orderNumber?.orderType === "8" || orderNumber?.orderType === "5" || orderNumber?.orderType === "6")
        ? 
        Number(userData?.price * 15) : 0) + SpecialCareTot //+ Number(processingFee) //processing fee is not needed here

        if (sumOfTotal2 <= promo?.discountValue) 
        { 
          
          subtotal = Math.abs(Number(values.amount)) + Number(processingFee) + (isWashDryFoldAvailable && !isSpecialCareAvailable ? Total(list) + Total(list2) : 0) + (!isWashDryFoldAvailable && isSpecialCareAvailable && isWashDryFoldServiceSelected ? Number(userData?.price * 15) : 0) ;  

        }else {
          subtotal =
            orderNumber?.orderType === "2" ||
            orderNumber?.orderType === "3" ||
            orderNumber?.orderType === "4"
              ? Number(
                  (
                    Total(list) +
                    Total(list2) +
                    Math.abs(Number(values.amount)) +
                    Number(processingFee)
                  ).toFixed(2)
                ) - Number(promo?.discountValue)
              : Number(
                  (
                    Number(userData.price * 15) +
                    Total(list) +
                    Total(list2) +
                    Math.abs(Number(values.amount)) +
                    Number(processingFee)
                  ).toFixed(2)
                ) -
                (SpecialCareTot < Number(promo?.discountValue) &&
                isSpecialCareAvailable &&
                !isWashDryFoldAvailable
                  ? Number(SpecialCareTot)
                  : Number(promo?.discountValue));
        }
      }
    } else {
      
      subtotal =
        orderNumber?.orderType === "2" ||
        orderNumber?.orderType === "3" ||
        orderNumber?.orderType === "4"
          ? Total(list) +
            Total(list2) +
            Math.abs(Number(values.amount)) +
            Number(processingFee)
          : Number(userData.price * 15) +
            Total(list) +
            Total(list2) +
            Math.abs(Number(values.amount)) +
            Number(processingFee);
    }
    
      return Number(subtotal.toFixed(2));
  };

  useEffect(() => {
    if (states.order?.rewards?.promoVal) calcSubTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states.order?.rewards?.promoVal]);
  useEffect(() => {
    executeScroll();
    loadData();
    getSession();
    getSession2();
    calcSubTotal();
    //eslint-disable-next-line
  }, []);
  // remove items from cart useEffect
  useEffect(() => {
    if (removeClicked > 0) {
      if (list?.length === 0 && list2?.length === 0 && !userData.price) {
        sessionStorage.removeItem("additems");
        sessionStorage.removeItem("addTitems");
        sessionStorage.removeItem("orderNumber");
        sessionStorage.removeItem("pickup");
        sessionStorage.removeItem("delivery");
        sessionStorage.removeItem("delivery2");
        sessionStorage.removeItem("combinedPickupNote");
        setList([])
        setList2([])
        setRemoveClicked(0)
        navigate("/order");
      }
      if ((orderNumber?.orderType === "1" || orderNumber?.orderType === "4" || orderNumber?.orderType === "5" || orderNumber?.orderType === "6" || orderNumber?.orderType === "7" || orderNumber?.orderType === "8" || orderNumber?.orderType === "9" || orderNumber?.orderType === "10") && list?.length === 0 && list2?.length === 0 && !userData.price) {
        setUserData([]);
        navigate("/order");
      }
    }
    //eslint-disable-next-line
  }, [removeClicked])
  useEffect(() => {
    setPageLoading(true)
    setTimeout(() => { setPageLoading(false) }, 6000);
  }, [])

  const addItemsCleaningList = async (data) => {
    await dispatch(additems(data));
  };

  const handleChangeDryCleaningList = (prop) => (event) => {

    if (event.target.value === "") {
      setButtonDiabled(true)
    } else {
      setButtonDiabled(false)
    }

      const object = { [prop]: Number(event.target.value) < 1 && event.target.value !== "" ? 1 : Math.floor(Number(event.target.value)) };

      for (const property in object) {
        const filterObject = list.findIndex((item) => item._id === property);
        list[filterObject].itemId.quantity = object[property];
        setList([...list]);
        addItemsCleaningList([...list]);
      } 

  };

  const handleChangeTailoringList = (prop) => (event) => {

    if (event.target.value === "") {
      setButtonDiabled(true)
    } else {
      setButtonDiabled(false)
    }

    const object = { [prop]: Number(event.target.value) < 1 && event.target.value !== "" ? 1 : Math.floor(Number(event.target.value)) };

    for (const property in object) {
      const filterObject = list2.findIndex((item) => item._id === property);
      list2[filterObject].itemId.quantity = object[property];
      setList2([...list2]);
      addItemsTailoringList([...list2]);
    }

  };

  const addItemsTailoringList = async (data) => {
    await dispatch(addTitems(data));
  };

  return (
    <div ref={myRef} style={{ backgroundColor: "#EFEFEF" }}>
      <PageHeader tittle={"Payment & Confirmation"}/>
      <Grid container>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
          <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} alignItems="center" justifyContent="center" >
            <div style={{ height: "50vh", marginTop: "25vh", display: pageLoading ? "flex" : "none", }} > <CircularProgress /> </div> </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
          <Grid item lg={12} md={12} sm={12} xs={12} >
            < div className={classes.root} style={{ display: pageLoading && "none", }} >
              <div className={classes.boxnew}>
                <p className={classes.tittle} data-testid="order-review-title">Order Review</p>
                <Divider className={classes.divider} />
                <p className={classes.para1}> Please review the order details below.</p>
                
                {!(orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4")
                  && userData?.price && (<> <div className={classes.box2}>
                    <p className={classes.para2}> {`1 - Wash/Dry/Fold  (15 lb minimum, $${(userData?.price).toFixed(2)} per lb.)`} </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className={classes.para3}>${(userData.price * 15).toFixed(2)}</p>
                      <IconButton onClick={() => removeItems(userData.id, orderNumber?.orderType, "washDryFold")} > <CancelIcon /> </IconButton>
                    </div>
                  </div>
                    <Divider className={classes.divider1} /> </>)}

                {list.map((item, i) => (<> <div className={classes.box2}>
                  <div style={{display:'flex', alignItems:'center'}}>
                  <FormControl
                          style={{
                            width:"60px",
                          }}
                          >
                          <FilledInput
                            InputProps={{ inputProps: { min: 1} }}
                            key={i}
                            id='filled-adornment-amount' 
                            type='number'
                            value={item.itemId.quantity}
                            onChange={handleChangeDryCleaningList(`${item._id}`)}
                            size='small' 
                            />
                        </FormControl>

                  <p className={classes.para2OrderReview}> {item.itemId.item.name} </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className={classes.para3}> ${(item.itemId.price.toFixed(2) * item.itemId.quantity).toFixed(2)} </p>
                    <IconButton onClick={() => removeItems(item?._id, orderNumber?.orderType, "list")} > <CancelIcon /> </IconButton>
                  </div>
                </div>
                  <Divider className={classes.divider1} /> </>))}

                {list2.map((item, i) => (<> <div className={classes.box2}>
                  <div style={{display:'flex', alignItems:'center'}}>
                  <FormControl
                          style={{
                            width:"60px",
                          }}
                          >
                          <FilledInput
                            InputProps={{ inputProps: { min: 1} }}
                            key={i}
                            id='filled-adornment-amount' 
                            type='number'
                            value={item.itemId.quantity}
                            onChange={handleChangeTailoringList(`${item._id}`)}
                            size='small' />
                    </FormControl>

                  <p className={classes.para2OrderReview}> {item.itemId.item.name} </p>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className={classes.para3}> ${(item.itemId.price.toFixed(2) * item.itemId.quantity).toFixed(2)} </p>
                    <IconButton onClick={() => removeItems(item?._id, orderNumber?.orderType, "list2")} > <CancelIcon /> </IconButton>
                  </div>
                </div>
               
                  <Divider className={classes.divider1} /> </>))}

                <Grid container>
                  <Grid item xs={12} sm={6} style={{ padding: "1rem" }}>
                    <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                      <p className={classes.para5}> Would you like to add something extra? </p>
                      <FilledInput id="filled-adornment-amount" type="number" value={values.amount} onChange={handleChange("amount")} size="small" style={{ width: "70%" }} startAdornment={<InputAdornment position="start">$</InputAdornment>} /> </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                      <p style={{ marginTop: "1rem" }} className={classes.para5}> Promos</p>
                      <GetCoupons value={values.coupon} funChanges={handleChange} orderType={orderNumber?.orderType} zipcode={zipCode} isListEmpty={(list?.length === 0 && list2?.length === 0 && !userData.price) && (removeClicked > 0)} isWashDryFoldServiceSelected={isWashDryFoldServiceSelected} isSpecialCareItemsEmpty={list?.length === 0 && list2?.length === 0 ? true : false} isWashDataLoading={isWashDataLoading} />
                      {/* END slect a coupon */}
                    </FormControl>
                  </Grid>
                  <Grid item container xs={12} sm={6} style={{ padding: "1rem" }}>
                    <Grid item xs={8}>
                      {!(orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4") && userData?.price && <p className={classes.para4}>Wash/Dry/Fold Services</p>}
                      {!(orderNumber?.orderType === "1") && (
                      <p className={classes.para4}>Special Care Services</p>)}
                      <p className={classes.para4}>Reward discount:</p> 
                      <p className={classes.para4}>Something Extra 🙂 *</p>
                      <p className={classes.para4}>A small processing fee:</p> 
                      <p style={{ color: "#25c268", fontSize: "1.18rem", fontWeight: 700, lineHeight: "1.37rem", marginBottom: "0.3rem", marginTop: "1.5rem", }} > Subtotal </p>
                    </Grid>
                    <Grid item xs={4}> {!(orderNumber?.orderType === "2" || orderNumber?.orderType === "3" || orderNumber?.orderType === "4") && userData?.price && <p className={classes.para4}> ${(userData.price * 15).toFixed(2)} </p>}
                      {!(orderNumber?.orderType === "1") && (<p className={classes.para4}>${(Total(list) + Total(list2)).toFixed(2)}</p>)}
                      <p className={classes.para4}> (${Number(total.toFixed(2) - calcSubTotal()).toFixed(2)}) </p>
                      <p className={classes.para4}> ${Math.abs(Number(values.amount)).toFixed(2)} </p>
                      <p className={classes.para4}>${(processingFee).toFixed(2)}</p>
                      <p style={{ color: "#25c268", fontSize: "1.18rem", fontWeight: 700, lineHeight: "1.37rem", marginBottom: "0.3rem", marginTop: "1.5rem", overflow: "hidden", textOverFlow: "clip", }} > $ {Number(calcSubTotal()).toFixed(2)} </p>
                    </Grid>
                  </Grid>
                </Grid>
                <p className={classes.checkout__forms_h2}>Payment Methods</p>
                <SavedCards card={card} setCard={setCard} addOrdersWithNewCard= {addOrdersWithNewCard}/>
                <div className={classes.divroot2} > <Button disabled={!card || buttonDiabled} className={classes.continue2} onClick={() => addOrders()} data-testid="order-summary-continue-btn">Continue</Button></div>
              </div> </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
