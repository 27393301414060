import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MyAccount from "screens/myAccount/MyAccount";
import { Pagination, Typography } from "@mui/material";
import SinglePayment from "./SinglePayment";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import PaginationItem from "@mui/material/PaginationItem";
import { Link, useLocation } from "react-router-dom";

const textStyle = {
  font: '100% "Lato",sans-serif',
  textAlign: "left",
  display: "flex",
  color: "#457FC2",
  fontSize: "1.2em",
  lineHeight: "1.5em",
  fontWeight: "700",
  marginBottom: "15px",
};

export default function PaymentHistory() {
  const axios = useAxiosPrivate();
  const [payments, setPayments] = useState([]);
  const [newPage, setNewPage] = useState(1);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const getData = async () => {
    const { data } = await axios.get(`/customer/payment-details/${newPage}`);

    const sortedArray = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setPayments(sortedArray);
  };

  const getCount = async () => {
    const { data } = await axios.get(`/customer/get-totalcount`);
    setCount(data);
  };

  useEffect((e) => {
    getCount();
    // eslint-disable-next-line
  }, []);
  useEffect(
    (e) => {
      getData();
    },
    // eslint-disable-next-line
    [newPage]
  );
  const handleChangePage = (event, newPage) => {
    setNewPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setNewPage(0);
  };

  return (
    <MyAccount>
      <Grid container xs={12} md={12} lg={12} direction="column" x>
        <Grid item>
          <Typography style={textStyle}>Payment History</Typography>
        </Grid>

        {payments.map((payment) => {
          return (
            <Grid item id={payment.id} 
            // style={{paddingRight:"2rem"}}
            >
              <SinglePayment payment={payment} />
            </Grid>
          );
        })}
      </Grid>
      <Pagination
        page={page}
        count={count}
        variant="outlined"
        shape="rounded"
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`/myaccount/paymenthistory${
              item.page === 1 ? "" : `?page=${item.page}`
            }`}
            {...item}
          />
        )}
        onChange={handleChangePage}
      />
    </MyAccount>
  );
}
