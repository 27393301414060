import React, { useState, useRef, useEffect } from 'react';
import PageHeader from 'components/UI/PageHeader';
import { useStyles } from './classes';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import LoadingButton from '@mui/lab/LoadingButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { setPromo, setRewards, setOrderid } from '../../store/actions/orderAction';
// Payment errors
import PaymentDialog from './PaymentDialog';
import OrderFailDialog from './OrderFailDialog';
import truncateText from 'components/common/truncateText';
const CustomColorCheckbox = withStyles({ root: { color: '#008EFF', '&$checked': { color: '#008EFF', }, }, checked: {}, })
  ((props) => <Checkbox color='default' {...props} />);

const processingFee = 5;

export default function ConfirmOrder() {
  const axios = useAxiosPrivate();
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
  let address = useSelector((state) => state.auth.defaultAddress);
  let location = useSelector((state) => state.auth.defaultLocation);
  let dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const orderNumber = JSON.parse(sessionStorage.getItem('orderNumber'));
  const combinedPickupNote = JSON.parse(sessionStorage.getItem('combinedPickupNote'));
  const pickup = JSON.parse(sessionStorage.getItem('pickup'));
  const delivery = JSON.parse(sessionStorage.getItem('delivery'));
  const delivery2 = JSON.parse(sessionStorage.getItem('delivery2'));
  const addorder = JSON.parse(sessionStorage.getItem('addorder'));
  const addRewards = JSON.parse(sessionStorage.getItem('addRewards'));
  const addPromo = JSON.parse(sessionStorage.getItem('addPromo'));
  let getDiscount = useSelector((state) => state.order?.discount) || addorder?.rewardDiscount;
  let discount = Number(getDiscount?.toFixed(2))
  const coupon = useSelector((state) => state.order?.rewards?.couponVal) || addRewards?.rewards?.couponVal;
  const promo = useSelector((state) => state.order?.promo) || addPromo?.promo;
  const [checked2, setChecked2] = useState(orderNumber?.customer?.laundryStatus);
  const [checked4, setChecked4] = useState(orderNumber?.customer?.pickupStatus);
  const [checked6, setChecked6] = useState(orderNumber?.customer?.deliveryStatus);
  const [success, setSuccess] = useState(false);
  const myRef = useRef(null);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [userData, setUserData] = useState(orderNumber?.customer);
  const [open, setOpen] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [backendCall, setBackendCall] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [failDialog, setFailDialog] = useState(false);
  const getSession = () => {
    const item = JSON.parse(sessionStorage.getItem('additems'));
    if (item === null) {
      setList([]);
    } else { let itemArray = [];
      for (let x in item) { itemArray.push(item[x]); }
      setList(itemArray);
    }
  };
  const [list2, setList2] = useState([]);
  const getSession2 = () => {
    const item = JSON.parse(sessionStorage.getItem('addTitems'));
    if (item === null) {
      setList2([]);
    } else { let itemArray = [];
      for (let x in item) { itemArray.push(item[x]); }
      setList2(itemArray);
    }
  };

  const addinstruction1 = async (checked) => {
    try { const data = { laundry: `${checked ? value : ""}`, laundryStatus: checked, userId: userData._id, };
      await axios.put('/customer/landry', data);
    } catch (error) { }
  };

  const addinstruction2 = async (checked) => {
    try { const data = { pickup: `${checked ? value1 : ""}`, pickupStatus: checked, userId: userData._id, };
      await axios.put('/customer/pickup', data);
    } catch (error) { }
  };

  const addinstruction3 = async (checked) => {
    try { const data = { delivery: `${checked ? value3 : ""}`, deliveryStatus: checked, userId: userData._id, };
      await axios.put('/customer/delivery', data);
    } catch (error) { }
  };

  const handleChange2 = (event) => { setChecked2(event.target.checked); };
  const handleChange4 = (event) => { setChecked4(event.target.checked); };
  const handleChange6 = (event) => { setChecked6(event.target.checked); };
  let instruction = '';
  if (checked2) { instruction = `${userData.laundry}`; }
  const [value, setValue] = useState(instruction);
  const handleChange = (event) => { setValue(event.target.value); };
  let instruction2 = '';
  if (checked4) { instruction2 = `${userData.pickup}`; }
  const [value1, setValue1] = useState(combinedPickupNote?.note ? combinedPickupNote?.note : instruction2);
  const handleChange1 = (event) => { setValue1(event.target.value); };
  let instruction3 = '';
  if (checked6) { instruction3 = `${userData.delivery}`; }
  const [value3, setValue3] = useState(instruction3);
  const handleChange3 = (event) => { setValue3(event.target.value); };
  const directions = () => {
    dispatch(setPromo(null));
    dispatch(setRewards(null));
    navigate('/complete');
  };
  const executeScroll = () => myRef.current.scrollIntoView();
  
  const addOrders = async (details) => {
    if (checked2) { addinstruction1(checked2); }
    else if (checked2 === false) { addinstruction1(false); }
    if (checked4 === true) { addinstruction2(true); }
    else if (checked4 === false) { addinstruction2(false); }
    if (checked6) { addinstruction3(checked6); }
    else if (checked6 === false) { addinstruction3(false); }
    try {
      setOpen(false);
      setBackendCall(true);
      if (delivery2) {
        const { data } = await axios.post("/order/", {
          orderType: orderNumber?.orderType,
          zipCode: zipCode,
          pickupDate: pickup?.pickupDate,
          pickupTimeSlot: pickup?.pickupTimeSlot,
          diliverDate: delivery?.diliverDate,
          diliverTimeSlot: delivery?.diliverTimeSlot,
          specialDate: delivery2?.specialDate,
          specialTimeSlot: delivery2?.specialTimeSlot,
          servicePrice: addorder?.servicePrice,
          specialCarePrice: addorder?.specialCarePrice,
          extra: addorder?.extra,
          processingFee: processingFee,
          totalPrice: addorder?.totalPrice - (discount || 0),
          discount: discount,
          orderNote: value,
          pickupNote: value1,
          deliveryNote: value3,
          dryItem: list,
          tailItem: list2,
          customer: orderNumber?.customer?._id,
          franchiseeId: addorder?.franchiseeId,
          orderAddress: address,
          coupon: coupon,
          promo,
          orderLocation: location,
          stripeId: orderNumber?.customer?.stripeId,
          isRetry: sessionStorage.getItem("isRetry") ? true : false,
          declaredOrderId: sessionStorage.getItem("retryOrderID"),
        });
        
        if(data?.validationFailed){
          console.log(data.message)
         return setFailDialog(true);
        }
          
        if(data.message === "Payment Fail") {
          setOpen(true);
          setBackendCall(false);
          setOrderId(data?.data)
          sessionStorage.setItem("isRetry", true);
          sessionStorage.setItem("retryOrderID", data?.data);
          return  setPaymentData(data);
        }  
        sessionStorage.removeItem("isRetry");
        sessionStorage.removeItem("retryOrderID");
        await scheduleUpdate();
        dispatch(setOrderid({ orderId: data?.orderId, shippedPlace: data?.shippedPlace }));
        setSuccess(true);
        setOrderId("");
        setTimeout(() => {
          setBackendCall(false);
          setSuccess(false);
          directions();
        }, 3000);
        // await payOrder(data);
      } else {
        const { data } = await axios.post("/order/", {
          orderType: orderNumber?.orderType,
          zipCode: zipCode,
          pickupDate: pickup?.pickupDate,
          pickupTimeSlot: pickup?.pickupTimeSlot,
          diliverDate: delivery?.diliverDate,
          diliverTimeSlot: delivery?.diliverTimeSlot,
          servicePrice: addorder?.subtotal,
          specialCarePrice: addorder?.specialCarePrice,
          extra: addorder?.extra,
          processingFee: processingFee,
          totalPrice: addorder?.totalPrice - (discount || 0),
          discount: discount,
          orderNote: value,
          pickupNote: value1,
          deliveryNote: value3,
          dryItem: list,
          tailItem: list2,
          customer: orderNumber?.customer._id,
          franchiseeId: addorder?.franchiseeId,
          orderAddress: address,
          coupon: coupon,
          promo,
          orderLocation: location,
          stripeId: orderNumber?.customer?.stripeId,
          isRetry: sessionStorage.getItem("isRetry") ? true : false,
          declaredOrderId: sessionStorage.getItem("retryOrderID"),
        });
        if(data?.validationFailed){
          console.log(data.message)
          return setFailDialog(true);
         }
        if(data.message === "Payment Fail") {
          setOpen(true);
          setBackendCall(false);
          setOrderId(data?.data);
          sessionStorage.setItem("isRetry", true);
          sessionStorage.setItem("retryOrderID", data?.data);
        return  setPaymentData(data);
        }  
        sessionStorage.removeItem("isRetry");
        sessionStorage.removeItem("retryOrderID");
        await scheduleUpdate();
        dispatch(setOrderid({ orderId: data?.orderId, shippedPlace: data?.shippedPlace }));
        setSuccess(true);
        setOrderId("")

        setTimeout(() => {
          setBackendCall(false);
          setSuccess(false);
          directions();
        }, 3000);
        // await payOrder(data); 
      }
    } catch (error) { setError(true);
      setTimeout(() => { setError(false); }, 3000); }
  };


  // const payOrder = async (orderData) => {
  //   try { await axios.post('/payment-detail/', { orderId: orderData?.id, paymentType: 'order', stripeId: orderNumber?.customer?.stripeId,
  //       franchiseeId: addorder?.franchiseeId, });
  //     const orderId = orderData?.orderId
  //     const shippedPlace = orderData?.shippedPlace
  //     dispatch(setOrderid({ orderId: orderId, shippedPlace: shippedPlace }));
  //     setTimeout(() => {
  //       setBackendCall(false);
  //       setSuccess(false);
  //       directions();
  //     }, 3000);
  //   } catch (error) {
  //     setOpen(true);
  //     setBackendCall(false);
  //     setPaymentData(orderData); }
  // };

  const scheduleUpdate = async () => {
    try {if (pickup) { const pickup1 = { timeSlots: [ { id: pickup?.pickupSchedule?.timeSlotId, count: pickup?.pickupSchedule?.attended ? 1 : 0, } ],
          unattendedCount: pickup?.pickupSchedule?.attended ? 0 : 1, }
        await axios.put(`/datepicker/${pickup?.pickupSchedule?.id}`, pickup1); }
      if (delivery) { const delivery1 = { timeSlots: [ { id: delivery?.deliverySchedule?.timeSlotId, count: delivery?.deliverySchedule?.attended ? 1 : 0, } ],
          unattendedCount: delivery?.deliverySchedule?.attended ? 0 : 1, }
        await axios.put(`/datepicker/delivery/${delivery?.deliverySchedule?.id}`, delivery1);}
      if (delivery2) { const del2 = { timeSlots: [ { id: delivery2?.deliverySchedule?.timeSlotId, count: delivery2?.deliverySchedule?.attended ? 1 : 0, } ],
          unattendedCount: delivery2?.deliverySchedule?.attended ? 0 : 1, }
        await axios.put(`/datepicker/delivery/${delivery2?.deliverySchedule?.id}`, del2); }
    } catch (error) { }
  }
  const loadData = async () => {
    try { const { data } = await axios.get('/customer/getData');
      setUserData(data);
    } catch (err) {
      setUserData([]); }
  };

  useEffect(() => {
    executeScroll();
    loadData();
    getSession();
    getSession2();
    if (!orderNumber) { 
      navigate('/order')
    }
    //eslint-disable-next-line
  }, []);

  function serviceTypeRender() {
    const servicesSelected = orderNumber?.servicesSelected;
    if (servicesSelected && servicesSelected.length > 0) {
      if(servicesSelected.length === 1) {
        return servicesSelected[0];
      }else{
      const formattedServices = servicesSelected.map((service, index) => {
        if (index === servicesSelected.length - 1) {
          return ` and ${service}`;
        } else if (index === servicesSelected.length - 2){
          return `${service}`;
        }
         else {
          return `${service}, `;
        }
      });
    
      const result = formattedServices.join(' ');
      return result;
    }
    }
  }

  return (
    <div ref={myRef} style={{ backgroundColor: '#EFEFEF' }} >
      <PageHeader tittle={'Order Summary'} />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container className={classes.mainGrid}> <Grid item lg={6} md={6} sm={12} xs={12} >
            <h2 style={{ textAlign: 'center' }} className={classes.header}> Notes and Special Instructions </h2>
            <div className={classes.container}> <p className={classes.para1}>Laundry instructions</p>
              <Divider style={{ marginBottom: '1rem' }} />
              <TextField id='filled-multiline-static' multiline rows={3} placeholder='e.g. Low heat/air dry' variant='filled' value={value} onChange={handleChange} fullWidth />
              <div className={classes.box}>
                <CustomColorCheckbox checked={checked2} onChange={handleChange2} style={{ transform: 'scale(1.1)', }} />
                <p> Save these instructions for later use!</p> </div>
            </div>
            <div className={classes.container}> <p className={classes.para1}>Pickup instructions</p>
              <Divider style={{ marginBottom: '1rem' }} />
              <TextField id='filled-multiline-static' multiline rows={3} placeholder='Pickup instructions' variant='filled'
                value={combinedPickupNote?.note ? combinedPickupNote?.note && value1 : value1} onChange={handleChange1} fullWidth />
              <div className={classes.box}>
                <CustomColorCheckbox checked={checked4} onChange={handleChange4} style={{ transform: 'scale(1.1)', }} />
                <p> Save these instructions for later use!</p> </div>
            </div>
            <div className={classes.container}> <p className={classes.para1}>Delivery instructions</p>
              <Divider style={{ marginBottom: '1rem' }} />
              <TextField id='filled-multiline-static' multiline rows={3} placeholder='Delivery instructions' variant='filled'
                value={value3} onChange={handleChange3} fullWidth />
              <div className={classes.box}>
                <CustomColorCheckbox checked={checked6} onChange={handleChange6} style={{ transform: 'scale(1.1)', }} />
                <p> Save these instructions for later use!</p> </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.mainGrid2} >
            <h2 className={classes.bilingHeader}>Billing Details</h2>
            <p className={classes.bilingCard}> Card: xxxx-xxxx-xxxx-{addorder?.card} </p>
            <p className={classes.bilingBackto} onClick={() => navigate('/checkout')} > Back To Billing Information </p>
            <h2 style={{ marginTop: '1rem', marginBottom: '1rem' }} className={classes.header} > Order Details </h2>
            <div className={classes.divContain}> <p className={classes.pdate}> Pickup for {pickup?.pickupDate} between {pickup?.pickupTimeSlot} </p>
              <p className={classes.pdetail}>{address}</p> </div>
            <div className={classes.divContain}> <p className={classes.pdate}> {((orderNumber?.orderType === "5" || orderNumber?.orderType === "7" || orderNumber?.orderType === "9")? orderNumber?.servicesSelected[0] : serviceTypeRender())} Delivery for {delivery?.diliverDate} between{' '} {delivery?.diliverTimeSlot} </p>
              <p className={classes.pdetail}>{address}</p> </div>
              
            {(orderNumber?.orderType === "5" ||
            orderNumber?.orderType === "7" ||
            orderNumber?.orderType === "9")
              && (<div className={classes.divContain}> <p className={classes.pdate}>{orderNumber?.servicesSelected.slice(1).join(' and ')} Delivery for {delivery2?.specialDate} between{' '} {delivery2?.specialTimeSlot} </p>
              <p className={classes.pdetail}>{address}</p> </div>)}
            {/* Start Show on LG hide on MD */}
            <TableContainer className={classes.hideOnMd} sx={{ marginTop: '1.5rem' }} >
              <Table padding='none'>
                <TableBody>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.para6}>Subtotal:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6} data-testid="subtotal"> ${addorder?.subtotal?.toFixed(2)} </p> </TableCell>
                  </TableRow>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.para6}>Something Extra :</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6} data-testid="somthingExtra"> ${addorder?.extra?.toFixed(2)} </p> </TableCell>
                  </TableRow>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.para6}>A small processing fee:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6}  data-testid="processingFee">${processingFee?.toFixed(2)}</p> </TableCell>
                  </TableRow>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.para6}>Reward discount: </p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6}  data-testid="reward">${discount?.toFixed(2)} { addPromo?.promo === null && addRewards?.rewards ? `(${truncateText(addRewards?.rewards?.couponVal?.item?.name, 15)})` : ``} {addPromo?.promo !== null && addPromo?.promo?.name ? `(${truncateText(addPromo?.promo?.name, 15)})` : ``}</p> </TableCell> </TableRow>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.total}>Order Total:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.total}  data-testid="orderTotal"> $ {( addorder?.totalPrice + processingFee - discount ).toFixed(2)}{' '} </p> </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* END Show on LG hide on MD */}
            {/* Start Show on MD */}
            <TableContainer className={classes.showOnMd} sx={{ marginTop: '1.5rem' }} >
              <Table padding='none'>
                <TableBody>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell style={{ width: '14rem' }} align='left'> <p className={classes.para6}>Subtotal:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6} data-testid="subtotal"> ${addorder?.subtotal?.toFixed(2)} </p> </TableCell>
                  </TableRow>
                  <TableRow className={classes.borderRowstyle}>
                    <TableCell align='left'> <p className={classes.para6}>Something Extra :</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6} data-testid="somthingExtra"> ${addorder?.extra?.toFixed(2)} </p> </TableCell>
                  </TableRow>
                  <TableRow className={classes.borderRowstyle}> <TableCell align='left'> <p className={classes.para6}>A small processing fee:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.para6}  data-testid="processingFee">${processingFee?.toFixed(2)}</p> </TableCell> </TableRow>
                  <TableRow className={classes.borderRowstyle}> <TableCell align='left'> <p className={classes.para6}>Reward discount: </p> </TableCell>
                  <TableCell align='left'> <p className={classes.para6}  data-testid="reward">${discount?.toFixed(2)} { addPromo?.promo === null && addRewards?.rewards ? `(${truncateText(addRewards?.rewards?.couponVal?.item?.name, 15)})` : ``} {addPromo?.promo !== null && addPromo?.promo?.name ? `(${truncateText(addPromo?.promo?.name, 15)})` : ``}</p> </TableCell> </TableRow>
                  <TableRow className={classes.borderRowstyle}> <TableCell align='left'> <p className={classes.total}>Order Total:</p> </TableCell>
                    <TableCell align='left'> <p className={classes.total} data-testid="orderTotal"> $ {( addorder?.totalPrice + processingFee - discount ).toFixed(2)}{' '} </p> </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/* END Show on MD */}
            <p style={{ marginTop: '1rem', color: 'red' }}> Please make sure if you have any dry cleaning it is in a separate bag. Never put laundry and dry cleaning in the same bag! </p>
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <LoadingButton loading={backendCall} onClick={() => addOrders()} sx={{ backgroundColor: '#2cca5c', color: 'white', '&:hover': { backgroundColor: '#2cca5c', color: 'white', }, }}
                variant='contained' size='large' data-testid="place-order-btn">
                   Place Order 
              </LoadingButton> </div>
            {success && (<p style={{ color: 'green' }}>Order Placed Successfully</p>)}
            {error && <p style={{ color: 'red' }}>Order Placed Failed</p>}
          </Grid>
          <PaymentDialog open={open} openMe={() => setOpen(true)} closeDialog={() => setOpen(false)} paymentData={paymentData} tryPayment={() => addOrders()} />
          <OrderFailDialog  open={failDialog} setFailDialog={setFailDialog}/>
        </Grid>
      </div>
    </div>
  );
}