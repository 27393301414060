import React /*, { useState, useEffect } */ from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentStatus from './PaymentStatus';
import MyAccount from 'screens/myAccount/MyAccount';
import { Grid, Typography } from '@material-ui/core';


const PUBLIC_KEY = window.sessionStorage.getItem('publishableKey');
const stripePromise = loadStripe(PUBLIC_KEY);
const callBack = '/myaccount/mywallet';
export default function NewStripeContainer() {
  return (
    <MyAccount>
      <Elements stripe={stripePromise}>
        {' '}

        <Grid container style={{ width: "100%", height: "100%" }} alignItems='center' justifyContent='center' lg={12} md={12} sm={12} xs={12}>

          <Grid item style={{ width: "100%", height: "10rem" }}></Grid>
          <Grid item>
            <Typography variant='h4' style={{ textAlign: "center" }}>
              <PaymentStatus callBack={callBack} />{' '}
            </Typography>
          </Grid>
        </Grid>

      </Elements>
    </MyAccount >
  );
}
