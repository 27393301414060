import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import MyAccount from 'screens/myAccount/MyAccount';
import CardElement from './CardElement';

const textStyle = {
  font: '100% "Lato",sans-serif',
  textAlign: 'left',
  display: 'block',
  color: '#457FC2',
  fontSize: '1.2em',
  lineHeight: '1.5em',
  fontWeight: '700',
};

export default function MyWallet() {

  return (
    <MyAccount>
      <Grid container spacing={1} xs={12} md={12} lg={12}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography style={textStyle}>My Wallet</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <CardElement />
        </Grid>
      </Grid>
    </MyAccount>
  );
}
