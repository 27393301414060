import { Grid, Card, CardContent, CardHeader, makeStyles, Button, } from "@material-ui/core";
import React, { useState } from "react";
import DialogAlertMain from "components/common/DialogAlertMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({

  headTitle: { height: "1rem", textAlign: "center", color: "#35BFFF", fontSize: "2em", boxShadow: "5px", fontWeight: "300",
    fontFamily: '"Lato",sans-serif', marginTop: "1rem",
    [theme.breakpoints.down("xs")]: { height: "1rem", },
    [theme.breakpoints.down(316)]: { height: "3rem", },
  },
  containCard: { boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)", borderRadius: "6px", },
  emailIn: { fontFamily: "Lato", fontSize: "1.7rem", minWidth: "100%", minHeight: "4rem", backgroundColor: "#eee",
    border: "1px solid #ddd", borderRadius: "0.3rem", marginBottom: "10px", color: "#101d27", },
  formPara: { color: "#888", fontSize: "1rem", textAlign: "center", marginBottom: "0.5rem", },
  buttonSubmit: { borderRadius: "0.3rem", padding: "20px 40px", fontSize: "1.2em", border: "0", color: "#fff", backgroundColor: "#2cca5c",
    marginTop: "1rem", textTransform: 'none', fontFamily: "Lato,sans-serif",
    "&:hover": { backgroundColor: "#23b34f", },
    [theme.breakpoints.down("xs")]: { padding: "10px 20px", },
  },
  cancelButton: { fontSize: "1rem", border: "0", marginTop: "1rem", color: "#35bfff", fontWeight: "600", fontFamily: "Lato,sans-serif",
    textTransform: 'none', "&:hover": { backgroundColor: "#E5F4F9", }, },
  cardStyle: { textAlign: "center", marginTop: "5rem", marginBottom: "3rem", paddingLeft: "3rem", paddingRight: "3rem",
    [theme.breakpoints.down("sm")]: { marginBottom: "3rem", paddingLeft: "2rem", paddingRight: "2rem", },
  },



}))


export default function ForgotPassword() {
    const marginsSm = useMediaQuery("(max-width: 899px)");
  const axios = useAxiosPrivate();
  const classes = useStyles();
  let navigate = useNavigate();
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  //eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({ email: "" });
  let ForgotPassSchema = Yup.object().shape({ email: Yup.string().email().required("Email is required!"), });
  const submit = async (e) => {

    const email = { email: e.email, }
    try {
      await axios.post("/auth/customer/forgot-password", email)
      setAlert({ showAlert: true, severity: "success", message: "Password Reset Email Sent, Please Check Your Email", });

      setTimeout(() => {
        setAlert({ showAlert: false, severity: "", message: "", });
        navigate("/");
      }, 4000);

    } catch (err) {
      if (err.response.status === 403) {
        setAlert({ showAlert: true, severity: "error", message: err.response.data.message, });
        setTimeout(() => { setAlert({ showAlert: false, severity: "", message: "", }); }, 3000);
      }

    }

  }

  return (
    <Grid container justifyContent="center" alignItems="center" >
      <Grid container item lg={12} md={12} sm={12} xs={12} className={classes.cardStyle} justifyContent="center">
        <Card className={classes.containCard}>
          <CardHeader titleTypographyProps={{ variant: 'h4' }} classes={{ title: classes.headTitle }} title="Password Reset" />
          <CardContent className={classes.hideOnSm} >
            <Grid container item lg={12} md={12} sm={12} xs={12} style={{ padding: "1rem", }} justifyContent="center">
              <Formik initialValues={initialValues} onSubmit={submit} validationSchema={ForgotPassSchema} >
                {({ dirty, isValid }) => {
                  return (
                    <Form>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <p className={classes.formPara}>Enter the email address that you signed up
                          with and we'll email you a link
                          to reset your password.</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Field name="email" type="email" className={classes.emailIn}
                        style={{ fontSize: marginsSm ? "1.25rem" : "1.7rem", minHeight: marginsSm ? "3rem" : "4rem"}}
                          label="Email address" ></Field>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button type="submit" className={classes.buttonSubmit} color="primary" variant="contained"
                          disabled={!dirty || !isValid} > Reset Password </Button>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Button className={classes.cancelButton} color="primary" variant="text" onClick={() => navigate("/")} > Cancel </Button>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Grid>
          </CardContent>
          {alert.showAlert && (
          // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <DialogAlertMain setAlert={setAlert} alert={alert} />
          // {alert.message} </Alert> 
        )}
        </Card >
      </Grid>
    </Grid>
  )
}
