import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import MyAccount from "screens/myAccount/MyAccount";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';

import MyLocation from "./MyLocations";
import AccountDetails from "./AccountDetails";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: "0rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },

  button: {
    backgroundColor: "#2cca5c",
    color: "white",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#2cca5c",
      color: "white",
    },
  },

  headerTypo: {
    color: "#457FC2",
    fontWeight: "700",
    fontSize: "1.2rem",
    fontFamily: '"Lato",sans-serif',
  },

  mylocation: {
    paddingLeft: "2rem",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "1rem",
      paddingLeft: "0rem",
    },
  },

  fields: {
    marginTop: "1rem",
  },
}));

export default function MySettings() {
  const axios = useAxiosPrivate();
  const classes = useStyles();

  let id = useSelector((state) => state.auth.id);

  const [location, setLocation] = useState([]);

  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  });


  const getData = async () => {
    try {
      const { data } = await axios.get(`/customer/${id}`);

      setInitialValues({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
      });

      setLocation(data.location);
    } catch (error) { }
  };


  return (
    <MyAccount>
      <Grid container>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <AccountDetails classes={classes} setLocation={setLocation} />
        </Grid>

        <Grid container item lg={6} md={6} sm={12} xs={12} className={classes.mylocation}>
          <Grid item lg={12} md={12} sm={6} xs={12}>
            <MyLocation classes={classes} location={location} getData={getData} initialValues={initialValues} />
          </Grid>
        </Grid>

      </Grid>

    </MyAccount>
  );
}
