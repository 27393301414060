import React, { useState, useRef, useEffect, useMemo } from "react";
import Calendar from "react-calendar";
import PageHeader from "components/UI/PageHeader";
import Progressbar from "../../components/UI/ProgressBar";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useDispatch, useSelector } from "react-redux";
import { pickup } from "../../store/actions/orderAction";
import { useStyles } from "../order/classes";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";

const isSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export default function Pickup() {
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
  const axios = useAxiosPrivate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(null);
  const isMounted = useRef(false);
  const [value, onChange] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [getData, setGetData] = useState([]);
  const [message, setMessage] = useState("Data is loading...");
  const [fData, setFData] = useState([]);
  const [scheduleId, setScheduleId] = useState(null);
  const [timeSlotId, setTimeSlotId] = useState(null);

  const today = new Date();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setTimeSlotId(event.target.id);
  };

  const disabledDates = useMemo(() => {
    let dates = [];
    if (getData.length !== 0) {
      dates = getData?.disabledDates.map((item) => {
        const date = item;
        const newDate = date.slice(0, -5)
        return (
          new Date(newDate)
        )
      });
    }
    return dates;
  }, [getData]);
  const orderNumber = JSON.parse(sessionStorage.getItem("orderNumber"));
  const executeScroll = () => myRef.current.scrollIntoView();
  const controlProps = (item, index) => ({
    checked: selectedValue === item, onChange: handleChange, value: item, id: index,
    name: "size-radio-button-demo", inputProps: { "aria-label": item },
  });

  const addpickup = (data) => {
    dispatch(pickup(data));
    navigate("/delivery");
  };

  const loadData = async () => {
    try {
      const { data } = await axios.post(
        `/datepicker/${zipCode}`,
      {attended : orderNumber?.attended});
      setGetData(data);
    } catch (err) {
      setGetData([]);
      setMessage("Schedule not available, please check again later");
    }
  };

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      // Disable tiles in month view only
      if (orderNumber?.attended) {
        let new_array = [];
        // getData?.availableDates?.forEach(element => {
        //   if (element?.availableTimes?.every(item => item.limit === 0)) { new_array.push(element.date); }
        // });
        
        new_array.push(...disabledDates);
        new_array = [...new Set(new_array)];
        return new_array?.find((dDate) => isSameDay(new Date(dDate), new Date(date)));
      } else {
        let to_disable = [];
        to_disable = getData?.availableDates.map((item) => {
          if (item.unattendedLimit === 0 || item?.unattendedLimit === item?.unattendedCount) { return item.date; }
          else { return null; } });
        to_disable = to_disable.filter((item) => item !== null);
        // append disabled dates to disable dates array
        to_disable.push(...disabledDates);
        // make unique array
        to_disable = [...new Set(to_disable)];
        return to_disable.find((dDate) => isSameDay(new Date(dDate), new Date(date)));
      }
      // Check if a date React-Calendar wants to check is on the list of disabled dates
    }
  }
  const getFilteredData = (data) => {

    let filteredData = getData?.availableDates.filter((item) => {
      const date = item.date;
      const newDate = date.slice(0, -5);
      return (
        isSameDay(new Date(newDate), data)
      )
    });
    setScheduleId(filteredData[0]?.id);
    setFData(filteredData);
  };

  useEffect(() => {
    executeScroll();
    loadData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      getFilteredData(value);
    } else {
      isMounted.current = true;
    }
    //eslint-disable-next-line
  }, [value]);
  if (getData.length === 0) {
    return (
      <div ref={myRef} style={{ marginBottom: 50 }}>
        <PageHeader tittle={"Choose Your Pick-up Date and Time"} />
        <Grid container>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item lg={9} md={11} sm={12} xs={12} >
              <Progressbar bgcolor="#35D8F2" progress="35" text={"PICKUP"} height={30} />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.textbox}> <p className={classes.text}>{message}</p> </div>
      </div>
    );
  }
  return (
    <div ref={myRef} style={{ marginBottom: 150 }}>
      <PageHeader tittle={"Choose Your Pick-up Date and Time"} />
      <Grid container>
        <Grid container item alignItems="center" justifyContent="center">
          <Grid item lg={9} md={11} sm={12} xs={12} >
            <Progressbar bgcolor="#35D8F2" progress="35" text={"PICKUP"} height={30} />
          </Grid>
        </Grid>
      </Grid>
      {!orderNumber?.attended && (
        <Grid container>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item lg={6} md={6} sm={9} xs={11}>
              <div style={{ padding: "1.3rem", backgroundColor: "#FFF9D0", }} >
                <p style={{ color: "#987a2f" }}>
                  {/* TODO value is hardcoded */}
                  {`Unattended pick-ups will be made from a time period of ${getData?.unAttendedTime}.
              Please make sure your laundry is at your pick-up point by ${getData?.unAttendedTime?.split('-')[0]}.`}
                </p>
              </div>
            </Grid>
          </Grid>
        </Grid>)}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
        {" "}
        <Calendar showNeighboringMonth={false}
          onChange={onChange} value={value} calendarType={"US"}
          minDate={new Date(new Date(today?.toLocaleString('en-US', { timeZone: `${getData?.timeZone}` }).slice(0, -4)).getTime() + 86400000)}
          maxDate={new Date(new Date(getData.endDate).toUTCString().slice(0, -4))} tileDisabled={tileDisabled} />
      </div>
      {value !== "" && fData?.length !== 0 && (
        <>
          <div className={classes.flex2}>
            <div className={classes.box2}>
              <p style={{ padding: "1rem" }} >{`Select a pickup time for ${value.toLocaleDateString("en-US", {
                weekday: "long", year: "numeric",
                month: "long", day: "numeric",
              })}`}</p>
            </div>
          </div>
          {orderNumber?.attended ? (
            <div>
              <div className={classes.flex2}>
                <div className={classes.box3}>
                  <RadioGroup column name="position" defaultValue="top" >
                    {fData[0].availableTimes?.map((item) => (
                      <>
                        <FormControlLabel style={{ border: "1px solid #75a8dc", margin: 2 }} key={item?.id}
                          control={<Radio {...controlProps(item?.timeSlot, item?.id)}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20, color: "#00A3FF", }, }} />}
                          label={<span className={classes.spantext2}> {"Between"}
                            <span className={classes.spantext3}> {item?.timeSlot}</span>
                          </span>} />
                      </>))}
                  </RadioGroup>
                </div>
              </div>
            </div>
          ) : (
            <Grid container>
              <Grid container item alignItems="center" justifyContent="center">
                <Grid item lg={6} md={6} sm={9} xs={11}>
                  <div style={{ marginTop: 10 }}>
                    <h3 style={{ marginTop: "1rem", fontSize: "1.18rem", lineHeight: "1.37rem", color: "#222", fontWeight: "700", }} >
                      {`You have selected ${value?.toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "long", day: "numeric", })}`}
                    </h3>
                    <p style={{ marginTop: "1rem", fontSize: "1rem", lineHeight: "1.37rem", color: "#222", fontWeight: "400", }} >
                      {/* TODO value is hardcoded */}
                      {`Unattended pick-ups will be made from a time period of ${getData?.unAttendedTime}.
              Please make sure your laundry is at your pick-up point by ${getData?.unAttendedTime?.split('-')[0]}.`}
                    </p>
                  </div>
                  <div className={classes.divroot2}
                    onClick={() => addpickup({
                      pickupDate: value.toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "long", day: "numeric", }),
                      pickupTimeSlot: getData?.unAttendedTime, pickupSchedule: { id: scheduleId, timeSlotId: timeSlotId, attended: orderNumber?.attended, },
                    })} >
                    <div className={classes.continue2}>
                      <div className={classes.box}> <Button style={{ color: "white", height: "1rem", fontSize: "0.9rem" }}>Continue To Delivery</Button> </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {selectedValue !== "" && (
            <div className={classes.divroot2}
              onClick={() => addpickup({
                pickupDate: value.toLocaleDateString("en-US", { weekday: "long", year: "numeric", month: "long", day: "numeric", }),
                pickupTimeSlot: selectedValue,
                pickupSchedule: { id: scheduleId, timeSlotId: timeSlotId, attended: orderNumber?.attended, },
              })} >
              <div className={classes.continue2}>
                <div className={classes.box}> <Button style={{ color: "white", height: "1rem", fontSize: "0.9rem" }} data-testid="continue-to-delivery-btn">Continue To Delivery</Button> </div>
              </div>
            </div>)}
        </>)}
    </div>);
}
