import React, { Fragment, useEffect, useMemo, useState, useRef, useCallback } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
// import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useStyles } from "utils/styles/styles";

import {
  TextField,
  Popper,
  Grid,
  Typography,
} from "@material-ui/core";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function SearchLocation({
  setFieldValue,
  // setInitialValues,
  setPlacesIdforPost,
  setZipCode,
  setState,
  setCity,
  // dirty,
  // isValid,
}) {
  const classes = useStyles();
  const [value, setValue] = useState({
    description: "",
  });
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const [placesId, setPlacesId] = useState("");


  const getPlacesPostCodeById = async (placeId) => {
    new Promise((resolve, reject) => {
      if (!placeId) reject("placeId not provided");

      try {
        new window.google.maps.places.PlacesService(
          document.createElement("div")
        ).getDetails(
          {
            placeId,
            fields: ["address_components"],
          },
          (details) => {
            let postcode = null;
            details?.address_components?.forEach((entry) => {
              if (entry.types?.[0] === "postal_code") {
                postcode = entry.long_name;
              }
            });
            setZipCode(postcode);
            setPlacesIdforPost(placeId);
            setFieldValue("zipCode", postcode);
            return resolve(postcode);
          }
        );
      } catch (e) {
        reject(e);
      }
    });}

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );


  const getCoordinates = (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setFieldValue("location.lat", location.lat());
        setFieldValue("location.lng", location.lng());
      } 
    });
  };


  const getStateByPlaceId = async (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const addressComponents = results[0].address_components;
        let state = null;
  
        // Iterate through address components to find the state
        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];
          for (let j = 0; j < component.types.length; j++) {
            if (component.types[j] === "administrative_area_level_1") {
              state = component.long_name;
              break;
            }
          }
          if (state) {
            break; // Exit the loop if state is found
          }
        }
  
        if (state) {
          setState(state)
        } else {
          // setState the case where the state is not found in the address
          setState(null);
        }
      } else {
        // Handle error or empty results here
        console.error("Geocode request failed or returned no results.");
        setState(null);
      }
    });
  };
  
  const getCityByPlaceId = async (placeId) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        const addressComponents = results[0].address_components;
        let city = null;
  
        // Iterate through address components to find the city
        for (let i = 0; i < addressComponents.length; i++) {
          const component = addressComponents[i];
          for (let j = 0; j < component.types.length; j++) {
            if (component.types[j] === "locality") {
              city = component.long_name;
              break;
            }
          }
          if (city) {
            break; // Exit the loop if city is found
          }
        }
  
        if (city) {
          // Handle the case where the city is found
          setCity(city);
        } else {
          // Handle the case where the city is not found in the address
          setCity("");
        }
      } else {
        // Handle error or empty results here
        setCity("");
      }
    });
  };
  
  

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const PopperMy = function (props) {
    return <Popper {...props} placement="none" />;
  };

  const handleClick = useCallback(async () => {
    if (placesId) { 
        await getPlacesPostCodeById(placesId); 
        await getStateByPlaceId(placesId);
        await getCityByPlaceId(placesId)
    }
    //eslint-disable-next-line
  }, [placesId]);

  useEffect(() => { 
    if (placesId !== "" && placesId) { 
      handleClick();
    }
  }, [placesId, handleClick]);

  return (
    <Fragment>
      <Autocomplete
         style={{
          marginTop: "0.5rem",
          marginBottom: "0.5rem",
        }}
        PopperComponent={PopperMy}
        size="small"
        id="google-map-demo"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
        }
        filterOptions={(x) => {
          return x.filter((option) => option?.description.includes("USA"));
        }}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        defaultValue={{
          description: "Homagama",
        }}
        value={value}
        onChange={(event, newValue) => {
          if (newValue) {
            getCoordinates(newValue.place_id);
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            setPlacesId(newValue.place_id);
            setFieldValue("address", newValue.description);
            // setInitialValues((pre) => ({
            //   ...pre,
            //   address: newValue.description,
            // }));
          } else {
            setValue(null);
            setZipCode("");
            setPlacesIdforPost("");
            setFieldValue("zipCode", "");

            setFieldValue("coordinates.lat", null);
            setFieldValue("coordinates.lng", null);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <div>
            <TextField
              {...params}
              variant="outlined"
              defaultValue={{
                description: "Homagama",
              }}
              placeholder="Street address"
              label="Street address"
              // InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                // startAdornment: (
                //   <InputAdornment
                //     style={{ marginRight: "2rem" }}
                //     position="start"
                //   >
                //     <GpsFixedIcon className={classes.gpsIcon} />
                //   </InputAdornment>
                // ),
              }}
              fullWidth
            />

          </div>
        )}
        renderOption={(option, { selected }) => {

          let parts = [];
          if (option?.structured_formatting) {

            const matches = option?.structured_formatting?.main_text_matched_substrings;

            if(matches){
              parts = parse( 
                option?.structured_formatting.main_text,
                matches.map((match) => [
                  match?.offset,
                  match?.offset + match?.length,
                ])
              );
            }
          }
          return (
            <Grid 
            container 
            alignItems="center" 
            onClick={() => selected()}
            >
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting &&
                    option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField className={classes.fieldWidth} name="apartmentNo" label="Suite / Apt #"
          onChange={(e) => { setFieldValue("apartmentNo", e.target.value); }}
          variant="outlined" fullWidth margin="dense" ></TextField>
      </Grid>
      {/* <Grid style={{ marginTop: "1rem" }}>
        {(getZipCodeAlert && inputValue !== "" && !isValid) && <Typography color="error" >Please click "GET ZIPCODE" to continue.</Typography>}
        <Button 
          style={{
            fontSize:"0.79rem",
            fontWeight:"600"
          }}
          variant="contained" color="primary" onClick={handleClick}>
          Get zipcode
        </Button>
      </Grid> */}
    </Fragment>
  );
}
