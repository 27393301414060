import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  box: {
    backgroundColor: '#fff',
    width: '50%',
    boxShadow: '0px 0px 5px #f5f5f5',
    marginBottom: "3rem",
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  boxnew: {
    backgroundColor: '#fff', 
    boxShadow: '0px 0px 5px #f5f5f5',
    marginBottom: "3rem", 
    maxWidth:"900px"
  },

  tittle: {
    color: '#35bfff',
    fontSize: '1.5rem',
    fontWeight: 300,
    lineHeight: '1rem',
    textAlign: 'center',
    marginTop: '1rem',
    marginBottom: '1.5rem',
  },
  para1: {
    color: '#888888',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1rem',
    textAlign: 'center',
    padding: '1.5rem',
  },
  box2: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2rem',
    paddingTop: '1rem',
    paddingBottom: 0,
  },
  para2: {
    color: '#333',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.37rem',
  },
  para3: {
    color: '#999',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.37rem',
  },
  para4: {
    color: '#222',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.37rem',
    marginBottom: '0.3rem',
    overflow: "hidden",
    textOverFlow: "clip",
  },
  para5: {
    color: '#222',
    fontSize: '0.9rem',
    fontWeight: 400,
    lineHeight: '1.37rem',
    padding: 2,
  },
  divroot2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '1rem',
  },
  checkout__forms_h2: {
    color: '#35bfff',
    fontSize: '1.5em',
    fontWeight: 300,
    lineHeight: '1em',
    margin: '20px 0',
    marginTop: '20px',
    marginRight: '0px',
    marginBottom: '20px',
    marginLeft: '0px',
    paddingBottom: '20px',
    borderBottom: '1px solid #e0e0e0',
    textAlign: 'center',
  },
  continue2: {
    backgroundColor: '#2cca5c',
    width: '20%',
    height: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '1.2rem',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#3ab961',
    },
    '&:disabled': {
      backgroundColor: '#E8E8E8',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },

  box3: {
    display: 'flex',
    alignItems: 'center',
  },
  para2OrderReview: {
    color: '#333',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.37rem',
    minWidth: '120px',
    paddingLeft:'15%'
  },
}));
