import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)({
  backgroundColor: '#2cca5c',
  textTransform: 'none',
  color: 'white',
  alignSelf: 'right',

  '&:hover': {
    backgroundColor: '#23a24a',
  },
  '&:active': {
    boxShadow: 'none',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

export const useStyles = {
  textStyle: {
    font: '100% "Lato",sans-serif',
    textAlign: 'left',
    display: 'block',
    color: '#457FC2',
    fontSize: '1.25em',
    lineHeight: '1.5em',
    fontWeight: '700',
  },
};