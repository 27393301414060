import React, { useEffect } from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import CardEdit from './CardEdit';
import { ReactComponent as Visa } from "../../../utils/assets/visa.svg";
import { ReactComponent as Addcard } from "../../../utils/assets/addCard.svg";
import { ReactComponent as MasterCard } from "../../../utils/assets/masterCard.svg";
import { ReactComponent as VisaBig } from "../../../utils/assets/visaBig.svg";
import { ReactComponent as Amex } from "../../../utils/assets/amex.svg";
import { ReactComponent as Discover } from "../../../utils/assets/discover.svg";
import { ReactComponent as Diners } from "../../../utils/assets/diners.svg";
import { ReactComponent as Jcb } from "../../../utils/assets/jcb.svg";
import { ReactComponent as Unionpay } from "../../../utils/assets/unionpay.svg";
import { ReactComponent as MasterBig } from "../../../utils/assets/masterBig.svg";
import { ReactComponent as AmexBig } from "../../../utils/assets/amexBig.svg";
import { ReactComponent as DiscoverBig } from "../../../utils/assets/discoverBig.svg";
import { ReactComponent as DinersBig } from "../../../utils/assets/dinersBig.svg";
import { ReactComponent as JcbBig } from "../../../utils/assets/jcbBig.svg";
import { ReactComponent as UnionpayBig } from "../../../utils/assets/unionpayBig.svg";
import { ReactComponent as RedCircle } from "../../../utils/assets/redCircle.svg";
import { ReactComponent as RedCircleTransparent } from "../../../utils/assets/redCircleTransparent.svg";

import { StyledButton, useStyles } from './style';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

export default function ShowCards({
  cards,
  getPaymentMethods,
  defaultCard,
  loadingSetter,
  loadingOffSetter,
  getSetupIntent,
}) {
  const [selectCard, setSelectCard] = React.useState(cards.filter((card) => card?.isDefault)[0]);

  const axios = useAxiosPrivate();
  const PUBLIC_KEY = window.sessionStorage.getItem('publishableKey');
  const stripePromise = loadStripe(PUBLIC_KEY);

  const updateDefault = async (id) => {
    try {
      loadingSetter();
      await axios.put('/customer/update-payment-method', {
        id,
      });
      getPaymentMethods();
    } catch (error) {
      // please handle errors
    }
  };

  const detachCard = async (paymentMethodId) => {
    try {
      loadingSetter();
      await axios.post('/customer/detach-payment-method', {
        id: paymentMethodId,
      });
      getPaymentMethods();
    } catch (error) { }
  };

  useEffect(() => {
    if (cards.length === 1 && !defaultCard) {
      updateDefault(cards[0].id);
    }
    setSelectCard(cards.filter((card) => card.isDefault)[0]);
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards]);

  function addLeadingZero(number) {
    return number < 10 ? "0" + number : "" + number;
  }

  return (
    <Grid
      container
      justify='center'
      alignItems='center'
      xs={12}
      xm={12}
      md={12}
      lg={12}
      mt={1}
    >
      <Grid item xs={12} md={12} lg={12}>
        <Typography style={useStyles.textStyle}>Card & Accounts</Typography>
      </Grid>
      {cards.length > 0 ? (
        <Grid container
          justifyContent={"center"}
          // spacing={1}
          xs={12}
          xm={12}
          md={12}
          lg={12}
          mt={2}
        >
          <Grid
            container item
            justifyContent={"center"}
            xs={12}
            xm={12}
            sm={12}
            md={6}
            lg={5.5}
          >
            <Grid container item
              justifyContent={"center"}
              alignItems='center'
              xs={12}
              xm={12}
              sm={10}
              md={12}
              lg={12}
              style={{ backgroundColor: "#EAECEC", border: '1px solid #CDCDCD', borderRadius: '0.625rem' }}
            >
              {cards.filter((item) => item.isDefault).map((card) => (
                <Grid container item
                  justify='center'
                  alignItems='center'
                  xs={12}
                  xm={12}
                  md={12}
                  lg={12}
                  style={{ backgroundColor: "#FFFFFF", borderRadius: '0.625rem', borderLeft: "6px solid #3DCA5C", boxShadow: "0px -3px 8px rgba(0, 0, 0, 0.06)", marginBottom: cards.length === 1 ? '0rem' : '-0.8rem' }}
                  onClick={() => setSelectCard(card)}
                >
                  <Grid key={card.id} item xs={4} xm={4} md={4} lg={4}>
                    {card?.brand === "visa" && <Visa style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "mastercard" && <MasterCard style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "amex" && <Amex style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "discover" && <Discover style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "diners" && <Diners style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "jcb" && <Jcb style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                    {card?.brand === "unionpay" && <Unionpay style={{ margin: "17px 10px 24px 21px" }} width="70%" />}
                  </Grid>


                  
                  <Grid item xs={8} xm={8} md={8} lg={8} style={{ textAlign: 'left' }}>

                  <Typography sx={{
                      cursor: 'pointer',
                      color: '#3DCA5C',
                      fontSize: '12px',
                      lineHeight: '24px',
                      font: 'Lato",sans-serif',
                      textAlign: 'left',
                      fontWeight: '500'
                    }}
                    style={{
                      marginTop: '-2em',
                      textAlign: 'end',
                      marginRight: '1em'
                    }}
                    >
                      {"Default"}
                      { selectCard?.id === card?.id ?
                      <RedCircle style={{ margin: "0px 0px 0px 5px" , padding:"0px 0px 0px 0px"}} />
                      :
                      <RedCircleTransparent style={{ margin: "0px 0px 0px 5px" }} />
                      }
                    </Typography>

                    <Typography sx={{
                      cursor: 'pointer',
                      color: '#1B1B1B',
                      fontSize: '1em',
                      lineHeight: '1.5em',
                      font: 'Lato",sans-serif',
                      textAlign: 'left',
                    }}
                    style={{
                      marginTop: '-10px'
                    }}
                    >
                      {card?.brand === "visa" && "Visa"}
                      {card?.brand === "mastercard" && "Mastercard"}
                      {card?.brand === "amex" && "American Express"}
                      {card?.brand === "discover" && "Discover"}
                      {card?.brand === "diners" && "Diners Club"}
                      {card?.brand === "jcb" && "JCB"}
                      {card?.brand === "unionpay" && "UnionPay"}
                    </Typography>
                    <Typography sx={{
                      cursor: 'pointer',
                      color: '#666',
                      fontSize: '0.8em',
                      font: 'Lato",sans-serif',
                      lineHeight: '1.5em',
                      fontWeight: '500',
                      textAlign: 'left',
                    }}>Debit card ending in •••• {card.last4}
                    </Typography>
                  </Grid>

                </Grid>
              ))}

              {cards.filter((item) => !item.isDefault).map((card) => (
                <Grid container item
                  justifyContent={"center"}
                  alignItems={"center"}
                  xs={12}
                  xm={12}
                  md={12}
                  lg={12}
                  style={{ backgroundColor: "#EAECEC", borderTop: '1px solid #CDCDCD', borderRadius: '0.625rem', boxShadow: "0px -3px 8px rgba(0, 0, 0, 0.06)" }}
                  onClick={() => setSelectCard(card)}
                >
                  <Grid key={card.id} item xs={4} xm={4} md={4} lg={4}>
                    {card?.brand === "visa" && <Visa style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "mastercard" && <MasterCard style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "amex" && <Amex style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "discover" && <Discover style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "diners" && <Diners style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "jcb" && <Jcb style={{ margin: "17px 10px 13px 21px" }} width="70%" />}
                    {card?.brand === "unionpay" && <Unionpay style={{ margin: "17px 10px 13px 21px" }} width="70%" />}

                  </Grid>
                  <Grid item xs={8} xm={8} md={8} lg={8} style={{ textAlign: 'left' }}>

                  <Typography sx={{
                      cursor: 'pointer',
                      color: '#3DCA5C',
                      fontSize: '12px',
                      lineHeight: '24px',
                      font: 'Lato",sans-serif',
                      textAlign: 'left',
                      fontWeight: '500'
                    }}
                    style={{
                      marginTop: '-2em',
                      textAlign: 'end',
                      marginRight: '1em'
                    }}
                    >
                      { selectCard?.id === card?.id ?
                      <RedCircle style={{ margin: "1px 0px 0px 5px", padding:"0px 0px 0px 0px" }} />
                      :
                      <RedCircleTransparent style={{ margin: "1px 0px 0px 5px" }} />
                      }
                    </Typography>

                    <Typography sx={{
                      cursor: 'pointer',
                      color: '#1B1B1B',
                      fontSize: '1em',
                      lineHeight: '1.5em',
                      font: 'Lato",sans-serif',
                      textAlign: 'left',
                    }}
                    
                    style={{
                      marginTop: '-10px'
                    }}
                    >
                      {card?.brand === "visa" && "Visa"}
                      {card?.brand === "mastercard" && "Mastercard"}
                      {card?.brand === "amex" && "American Express"}
                      {card?.brand === "discover" && "Discover"}
                      {card?.brand === "diners" && "Diners Club"}
                      {card?.brand === "jcb" && "JCB"}
                      {card?.brand === "unionpay" && "UnionPay"}
                    </Typography>
                    <Typography sx={{
                      cursor: 'pointer',
                      color: '#666',
                      fontSize: '0.8em',
                      font: 'Lato",sans-serif',
                      lineHeight: '1.5em',
                      fontWeight: '500',
                      textAlign: 'left',
                    }}>Debit card ending in •••• {card.last4}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              
            </Grid>
            <Grid container item
              justify='center'
              alignItems='center'
              xs={12}
              xm={12}
              sm={10}
              md={12}
              lg={12}
            >
              <Grid item xs={4} xm={4} md={4} lg={4}>
                <Addcard style={{ margin: "17px 10px 13px 23px", cursor: "pointer" }} onClick={() => getSetupIntent()} width="70%" />
              </Grid>
              <Grid item xs={8} xm={8} md={8} lg={8} style={{ textAlign: 'left' }}>
                <Typography onClick={() => getSetupIntent()} sx={{
                  cursor: 'pointer',
                  color: '#1976D2',
                  fontSize: '0.87em',
                  lineHeight: '1.5em',
                  font: 'Lato",sans-serif',
                  textAlign: 'left',
                }}>Add a New Card
                </Typography>
              </Grid>
            </Grid>
            <Grid container item
              justifyContent={'center'}
              alignItems='center'>
              <Grid item xs={12} xm={12} md={12} lg={11} mt={2}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>

          <Grid container item
            xs={12}
            xm={12}
            sm={10}
            md={6}
            lg={6.5}
            style={{ backgroundColor: "#FFFFFF" }}
          >

            <Grid item xs={12} xm={12} md={12} lg={12}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <Grid container item xs={12} xm={12} md={12} lg={12}
                justifyContent={'center'}
                alignItems='flex-start'
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <Grid key={selectCard?.id} item xs={5} xm={6} sm={4} md={6} lg={6}>
                  {selectCard?.brand === "visa" && <VisaBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "mastercard" && <MasterBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "amex" && <AmexBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "discover" && <DiscoverBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "diners" && <DinersBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "jcb" && <JcbBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                  {selectCard?.brand === "unionpay" && <UnionpayBig style={{ margin: "-12px 10px 13px 20px" }} width="80%" />}
                </Grid>
                <Grid item xs={7} xm={6} sm={8} md={6} lg={6} style={{ textAlign: 'left' }}>
                  <Grid item style={{ marginTop: '0.3rem' }}>
                    <Typography sx={{
                      color: '#1B1B1B',
                      fontSize: '1em',
                      lineHeight: '1.5em',
                      font: 'Lato",sans-serif',
                      textAlign: 'left',
                    }}>
                      {selectCard?.brand === "visa" && "Visa"}
                      {selectCard?.brand === "mastercard" && "Mastercard"}
                      {selectCard?.brand === "amex" && "American Express"}
                      {selectCard?.brand === "discover" && "Discover"}
                      {selectCard?.brand === "diners" && "Diners Club"}
                      {selectCard?.brand === "jcb" && "JCB"}
                      {selectCard?.brand === "unionpay" && "UnionPay"}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography sx={{
                      color: '#666',
                      fontSize: '0.8em',
                      font: 'Lato",sans-serif',
                      lineHeight: '1.5em',
                      fontWeight: '500',
                      textAlign: 'left',
                    }}>Debit card ending in •••• {selectCard?.last4}
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: '0.5rem' }}>
                    <Elements stripe={stripePromise}>
                      <CardEdit
                        detach={() => detachCard(selectCard?.id)}
                        ending={selectCard?.last4}
                        cardDetails={selectCard}
                        defaultCheck={selectCard?.isDefault}
                        cardId={selectCard?.id}
                        loadingSetter={loadingSetter}
                        loadingOffSetter={loadingOffSetter}
                        expDateAndMonth={`${addLeadingZero(selectCard?.exp_month)}/${selectCard?.exp_year % 100}`}
                        updateDefault={updateDefault}
                        getPaymentMethods={getPaymentMethods}
                      />
                    </Elements>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid item mt={1} xs={12} md={12} lg={12}>
          <Grid
            item
            alignItems='center'
            alignContent='center'
            justifyContent='center'
            style={{ textAlign: 'center', marginTop: "3rem" }}>
            <Typography sx={{
              color: '#666',
              fontSize: '1em',
              font: 'Lato",sans-serif',
              lineHeight: '1.5em',
              fontWeight: '600',
              textAlign: 'left',
            }}>You don't have any saved payment methods.
            </Typography>
            <Grid
              item
              alignItems='center'
              alignContent='center'
              justifyContent='center'
              style={{ textAlign: 'center', marginTop: "1.5rem" }}
              xs={12}
              md={12}
              lg={12}
            >
              <StyledButton
                variant='contained'
                size='small'
                startIcon={<AddCircleIcon />}
                onClick={getSetupIntent}
              >
                Add a Card
              </StyledButton>
            </Grid>
          </Grid>
        </Grid>
      )
      }
    </Grid >
  );
}
