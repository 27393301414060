import {useState} from "react";

function useFaqFunctions() { 

  const [openServiceZone, setOpenServiceZone] = useState(false);
  const [openSendOrders, setOpenSendOrders] = useState(false);
  const [openHaveGarments, setOpenHaveGarments] = useState(false);
  const [openDryCleaning, setOpenDryCleaning] = useState(false);
  const [openDryCleaner, setOpenDryCleaner] = useState(false);
  const [openFirstOrder, setOpenFirstOrder] = useState(false);
  const [openDeliver, setOpenDeliver] = useState(false);
  const [openTip, setOpenTip] = useState(false);
  const [openPickup, setOpenPickup] = useState(false);
  const [openGift, setOpenGift] = useState(false);
  const [openWeddingDress, setOpenWeddingDress] = useState(false);
  const [openReferralProgram, setOpenReferralProgram] = useState(false);
  const [openAlterationsWork, setOpenAlterationsWork] = useState(false);
  const [openSpecialCare, setOpenSpecialCare] = useState(false);
  const [openRescheduleAnOrder, setOpenRescheduleAnOrder] = useState(false);
  const [openHoursOfOperation, setOpenHoursOfOperation] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [openLoadWeighed, setOpenLoadWeighed] = useState(false);
  const [openDeliveryOrPickUp, setOpenDeliveryOrPickUp] = useState(false);
  const [openFabricSoftener, setOpenFabricSoftener] = useState(false);
  const [openDamagedOrMissing, setOpenDamagedOrMissing] = useState(false);
  const [openProcessingFee, setOpenProcessingFee] = useState(false);
  const [openDryCleaningItems, setOpenDryCleaningItems] = useState(false);
  const [openMyLaundryBag, setOpenMyLaundryBag] = useState(false); 

  const handleClickServiceZone = () => { setOpenServiceZone(!openServiceZone); };
  const handleClickSendOrders = () => { setOpenSendOrders(!openSendOrders); };
  const handleClickHaveGarments = () => { setOpenHaveGarments(!openHaveGarments); };
  const handleClickDryCleaning = () => { setOpenDryCleaning(!openDryCleaning); };
  const handleClickDryCleaner = () => { setOpenDryCleaner(!openDryCleaner); };
  const handleClickFirstOrder = () => { setOpenFirstOrder(!openFirstOrder); };
  const handleClickDeliver = () => { setOpenDeliver(!openDeliver); };
  const handleClickTip = () => { setOpenTip(!openTip); };
  const handleClickPickup = () => { setOpenPickup(!openPickup); };
  const handleClickGift = () => { setOpenGift(!openGift); };
  const handleClickWeddingDress = () => { setOpenWeddingDress(!openWeddingDress); };
  const handleClickReferralProgram = () => { setOpenReferralProgram(!openReferralProgram); };
  const handleClickAlterationsWork = () => { setOpenAlterationsWork(!openAlterationsWork); };
  const handleClickSpecialCare = () => { setOpenSpecialCare(!openSpecialCare); };
  const handleClickRescheduleAnOrder = () => { setOpenRescheduleAnOrder(!openRescheduleAnOrder); };
  const handleClickHoursOfOperation = () => { setOpenHoursOfOperation(!openHoursOfOperation); };
  const handleClickPayment = () => { setOpenPayment(!openPayment); };
  const handleClickLoadWeighed = () => { setOpenLoadWeighed(!openLoadWeighed); };
  const handleClickDeliveryOrPickUp = () => { setOpenDeliveryOrPickUp(!openDeliveryOrPickUp); };
  const handleClickFabricSoftener = () => { setOpenFabricSoftener(!openFabricSoftener); };
  const handleClickDamagedOrMissing = () => { setOpenDamagedOrMissing(!openDamagedOrMissing); };
  const handleClickProcessingFee = () => { setOpenProcessingFee(!openProcessingFee); };
  const handleClickDryCleaningItems = () => { setOpenDryCleaningItems(!openDryCleaningItems); };
  const handleClickMyLaundryBag = () => { setOpenMyLaundryBag(!openMyLaundryBag); };

  return {
    handleClickServiceZone, handleClickSendOrders, handleClickHaveGarments, handleClickDryCleaning, handleClickDryCleaner,
    handleClickFirstOrder, handleClickDeliver, handleClickTip, handleClickPickup, handleClickGift, handleClickWeddingDress,
    handleClickReferralProgram, handleClickAlterationsWork, handleClickSpecialCare, handleClickRescheduleAnOrder, handleClickHoursOfOperation,
    handleClickPayment, handleClickLoadWeighed, handleClickDeliveryOrPickUp, handleClickFabricSoftener, handleClickDamagedOrMissing,
    handleClickProcessingFee, handleClickDryCleaningItems, handleClickMyLaundryBag,
    openServiceZone, openSendOrders, openHaveGarments, openDryCleaning, openDryCleaner, openFirstOrder, openDeliver, openTip, openPickup,
    openGift, openWeddingDress, openReferralProgram, openAlterationsWork, openSpecialCare, openRescheduleAnOrder, openHoursOfOperation,
    openPayment, openLoadWeighed, openDeliveryOrPickUp, openFabricSoftener, openDamagedOrMissing, openProcessingFee, openDryCleaningItems,
    openMyLaundryBag
  }
}

export default useFaqFunctions;
