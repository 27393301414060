import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardContent, CardHeader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Lato,sans-serif", textAlign: "left", fontSize: "2rem", lineHeight: "1em",
        fontWeight: "300", background: "#35BFFF", color: "#FFFFFF", paddingLeft: "1rem",
        [theme.breakpoints.down("sm")]: { fontSize: "1.6rem", },
        [theme.breakpoints.down("xs")]: { fontSize: "1.6rem", },
    },
    img: {
        maxWidth: "13rem", position: "absolute", right: "2rem", top: "7rem",
        [theme.breakpoints.down("sm")]: { position: "initial", right: "2rem", top: "7rem", },
    },
    hideOnSm: {
        display: "flex",
        [theme.breakpoints.down("sm")]: { display: "none", },
    },
    hideOnLg: {
        display: "none",
        [theme.breakpoints.down("sm")]: { display: "flex", },
    },
    paraText: {
        textAlign: "left", marginTop: "1rem", marginLeft: "2rem", marginRight: "2rem",
        fontSize: "1.02rem", lineHeight: "1.5rem",
        [theme.breakpoints.down(1280)]: { marginLeft: "2rem", marginRight: "2rem", },
        [theme.breakpoints.down("sm")]: { marginTop: "0.2rem", marginLeft: "2rem", marginRight: "2rem", },
        [theme.breakpoints.down("xs")]: { marginTop: "0.2rem", marginLeft: "0.5rem", marginRight: "0.5rem", },
    },
    cardStyle: {
        textAlign: "center", paddingTop: "5rem", paddingBottom: "4rem", position: "relative", marginLeft: "30rem", marginRight: "30rem",
        [theme.breakpoints.down(1684)]: { marginLeft: "20rem", marginRight: "20rem", },
        [theme.breakpoints.down(1433)]: { marginLeft: "15rem", marginRight: "15rem", },
        [theme.breakpoints.down(1280)]: { marginLeft: "11rem", marginRight: "11rem", },
        [theme.breakpoints.down(1043)]: { marginLeft: "6rem", marginRight: "6rem", },
        [theme.breakpoints.down("sm")]: { marginLeft: "3rem", marginRight: "3rem", },
        [theme.breakpoints.down("xs")]: { marginLeft: "1rem", marginRight: "1rem", },
    },
}));

function TermsAndConditions() {
    const classes = useStyles();

    return (
        <Grid container justifyContent="center" alignItems="center" >
            <Grid container item lg={12} className={classes.cardStyle} justifyContent="center">
                <Card>
                    <CardHeader titleTypographyProps={{ variant: 'h1' }} style={{ height: "5rem" }} className={classes.title}
                        classes={{ title: classes.title }} title="Terms and Conditions" />
                    <CardContent>
                        <Grid container item>
                            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paraText}>
                                <p> The legal (boring) stuff... Queue the guy that speaks really
                                    fast on infomercials... </p>
                                <p> You have clothes that need to get cleaned and it is our goal at
                                    Life Without Laundry LLC to get the job done with the utmost
                                    care, quality, and expedience. We use the most modern equipment
                                    available and give each one of your garments the individual care
                                    it deserves. </p>
                                <p> By signing up online and creating an account with Life Without
                                    Laundry LLC, you agree to the following terms and conditions;
                                    These terms and conditions are subject to change and though we
                                    try to keep our customers as up to date as much as possible with
                                    any changes, it is the responsibility of you, the customer, to
                                    review these terms prior to purchase of any services. </p>
                                <p> If you are not 100% pleased with our services, if any of your
                                    items are missing, or to report any damage to your garments, you
                                    must contact our customer service number (617-768-7779) within
                                    24 hours of your scheduled delivery date and time. After this
                                    period, no refunds will be given or claims responded to. </p>
                                <p> Our wash / dry / fold service is for a minimum of 15 lbs and is
                                    set up in per lb rate of $2.50, plus a processing fee of $5.95
                                    per order. The bags will be weighed at our facility and we
                                    reserve the right to automatically bill the credit or debit card
                                    you have provided for your purchase. Life Without Laundry LLC
                                    will not honor any weighing disputes and we do not give any
                                    refunds for claims involving load weight. </p>
                                <p> At Life Without Laundry LLC, we individually inspect every item
                                    at the facility before and after they are processed and do our
                                    best to find any items that may have been forgotten by a
                                    customer in pockets or other areas of the laundry. However Life
                                    Without Laundry is not responsible for any items left in pockets
                                    such as pens or other items which may harm laundry during the
                                    washing or drying processes. Additionally, any items which are
                                    purposely tore or damaged such as jeans, denim garments, etc… by
                                    the manufacturer of the garment or the customer can never be
                                    included in any damage claim for any reason. </p>
                                <p> All of our wash/dry/fold cycles use cold washes. This means that
                                    we will never honor any claims of shrinking, color fading, or
                                    color blending. These are all normal occurrences to some degree in
                                    all laundry processes and will never be considered a form of
                                    damage by Life Without Laundry LLC. Additionally, some stains
                                    cannot be removed in a cold wash. We do not promise to remove any
                                    stain but make sure to spot treat any stain with a removing-pen
                                    and or liquid substance and double check the stain before the
                                    drying process. </p>
                                <p> There are some stains that have been scientifically proven not to
                                    appear until exposed to water or heat. Such stains typically have
                                    a sugar base. Some examples of stains are alcohol and juices. Such
                                    stains can be proved in a lab, but the expense is $200 to test. If
                                    it turns out that Life Without Laundry LLC is responsible for the
                                    discoloration, then Life Without Laundry LLC will assume the lab
                                    charge and suitable reparation for the garment. If Life Without
                                    Laundry LLC is not responsible for the discoloration, i.e., it is
                                    a sugar based stain that was invisible before exposure to
                                    water/heat, the customer is responsible for the lab charge and
                                    will not be reimbursed for the garment. No garment will ever be
                                    sent to a lab without customer’s request. Life Without Laundry LLC
                                    does not use sugar based stains as an escape clause for damaged
                                    garments since it is easy for an expert to identify the cause of a
                                    stain. </p>
                                <p> By putting any item into your laundry or dry cleaning bags, you
                                    are guaranteeing ownership and responsibility of that item. We
                                    highly recommend not including items that do not belong to you
                                    such as your roommates’ or friends’ to avoid any conflict. If they
                                    really need a specific item to be washed, have them set up a
                                    separate account separate their belongings. Also by putting an
                                    item into your laundry bag you consent the item can be washed
                                    dried and folded (for laundry orders) or dry cleaned (for dry
                                    cleaning orders). You can customize your order online and request
                                    things like "air dry" etc. This is allowed but it is the customers
                                    responsibility to list special notes online and to ensure the
                                    notes are clear online, and the air dry items are bagged up
                                    separate from regular laundry, before sending out a garment which
                                    cannot be done by our default washing technique (cold water wash
                                    and Medium heat dry). No claims for handwash items will ever be
                                    honored. We try to be as delicate as possible but we have
                                    commercial grade machines and sending out handwash only items is
                                    at the customers risk solely. </p>
                                <p> If you wish to report a missing or stolen item, you must be able
                                    to provide a specific description of the item such as the type of
                                    garment, brand, color, size… While we will investigate any claim
                                    of stolen or missing items to the best of our ability, we do not
                                    offer any compensation for missing or stolen garments. Because we
                                    take such care in individually handling and sorting garments, we
                                    find that the vast majority of claims are in fact the fault of the
                                    customer misplacing or simply not including a garment that they
                                    thought was in their laundry bag. We recommend including a
                                    detailed list of all items in the bag which will be checked upon
                                    reaching our facility if this is a concern. This list will not be
                                    considered a formal form of inventory, but it will help us to
                                    identify the items you include. </p>
                                <p> At Life Without Laundry LLC, we make sure to individually handle
                                    and check all items for damages, contents, and to count each item.
                                    This happens before and after the wash/dry/fold and dry cleaning
                                    process. We accept no financial responsibility for any items left
                                    in the customers garments like cash, jewelry, credit cards, etc…
                                    We practice extremely thorough pocket checks for any items such as
                                    ink pens, markers, highlighters, makeup, or other items which may
                                    cause serious damage to your garments, but accept no
                                    responsibility for damages that are caused by these items. The
                                    best way to ensure that these damages never occur is to do what we
                                    do before you send it in. Check each individual item before
                                    putting it in the laundry / dry cleaning bag, even if it doesn’t
                                    have pockets. And also double check to make sure nothing other
                                    then your garments found its way into your laundry / dry cleaning
                                    bag. </p>
                                <p> While we try as hard as we can to make our service as convenient
                                    for you as possible, we do not accept responsibility for items
                                    lost or stolen if they are left for pickup at a pre-designated
                                    area rather then a hand to hand exchange from customer to driver. </p>
                                <p> Once you have placed an order with Life Without Laundry LLC and
                                    set a pickup and delivery date and time, you cannot change any
                                    services required within 24 hours of your scheduled pick-up time.
                                    To avoid any conflict, please make sure you schedule the job you
                                    want us to do carefully so we can meet your needs to the best of
                                    our abilities. </p>
                                <p> Wash Dry Fold Policy - Life Without Laundry LLC will not cover any
                                    reimbursement over $200 total for any order or any individual item
                                    over $20. Life Without Laundry reserves the right to decide if any
                                    reimbursement will be in cash or credit (to our services) form. We
                                    are also not responsible for any fire, theft, lost items,
                                    flooding, or other occurrence out of the control of Life Without
                                    Laundry LLC and it's employees. </p>
                                <p> Dry Cleaning / Tailoring Policy- Life Without Laundry LLC
                                    exercises the utmost care in processing articles entrusted to us
                                    and use such processes which, in our opinion are the best suited
                                    to the nature and condition of each individual article.
                                    Nevertheless we cannot assume responsibility for inherent
                                    weaknesses of or defects in materials that are not readily
                                    apparent prior to processing. This applies particularly, but not
                                    exclusively, to suedes, leathers, silks, satins, double face
                                    fabrics, vinyls, pulyurethanes, etc... Responsibility also is
                                    disclaimed for trimmings, buckles, belts, beads, buttons, and
                                    sequins. In cleaning we cannot guarantee against color loss and
                                    shrinkage; or against damage to weak and tender fabrics.
                                    Differences in count must be reported within 24 hours. Any
                                    reimbursement considered, is 100% at our own discretion, and will
                                    be calculated at 5x the cleaning cost (price on website for item)
                                    and $200 max per order. Unless a list accompanied the bundle our
                                    count must be accepted. The company's liability with respect to
                                    any lost article shall not exceed 5 times our charge for
                                    processing it. Also if dry cleaning is not put in a separate bag
                                    and instead put into the same bag with regular wash dry fold, Life
                                    Without Laundry reserves the right to refuse any liability for
                                    those clothes if washed. Please always use a separate bag for dry
                                    cleaning as we request in our FAQ’s and during checkout. Makes it
                                    easy on all of us. Thank you! </p>
                                <p> Life Without Laundry LLC reserves the right to change these terms
                                    at any time and refuse service to any customer should conflict
                                    arise. All of our operating information such as prices and
                                    operating hours can be found on our website. We will never take a
                                    order by phone and if any necessary information is missing for a
                                    customers account, we reserve the right to refuse service. If you
                                    want to tip a driver directly please do so in cash in person.
                                    Nothing extra provided online goes to drivers. All tipping, if you
                                    choose to do any, should be done in person in the form of cash, at
                                    the time of the pickup or the delivery. </p>
                                <p> Life Without Laundry LLC also reserves the right to cancel any
                                    order and issue a full refund of any money spent on said order if
                                    there is a pricing error or website glitch which led to improper
                                    payment amounts received. Life Without Laundry LLC also is not
                                    responsible for any monthly services started by a user. It is the
                                    user's sole responsibility to start and stop these services and
                                    any failure to do so will not result in a refund from Life Without
                                    Laundry LLC. The user is responsible for stopping monthly services
                                    which can easily be accessed under the {"My Plans"} tab when
                                    logged in. </p>
                                <p> Due to the unpredictability of weather conditions at certain times
                                    of the year, Life Without Laundry reserves the right to make any
                                    pickup or delivery cancellations for the safety of the drivers.
                                    Contact will be made with the customer as soon as possible so that
                                    a future pickup/delivery may be made. All attempts will made to
                                    honor the original pickup and delivery times before resorting to a
                                    schedule change. </p>
                                <p> Did you just read that whole thing? Impressive lol... That all
                                    being said bottom line we will take great care of your stuff and
                                    provide you with a high level of customer service... Just set up
                                    the order! 🙂 </p>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}
export default TermsAndConditions;
