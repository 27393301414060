import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/authActions";
import { reset } from '../../store/actions/orderAction';
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';


const useStyles = makeStyles(() => ({
  para: { color: "white", fontFamily: '"Lato", sans-serif', fontSize: "0.8rem", padding: 5, margin: 0, },
  iconbtn: { backgroundColor: "#aacbe9", padding: "0.4rem 0.6rem", borderRadius: 5, margin: 0, "&:hover": { backgroundColor: '#9CBBD8', }, },
  iconText: {color: "white", fontFamily: '"Lato", sans-serif', fontSize: "0.8rem"},
}));

export default function TemporaryDrawer() {
  let dispatch = useDispatch();
  let token = useSelector((state) => state.auth.accessToken);
  const navigate = useNavigate();
  const classes = useStyles();
  const [state, setState] = useState({ top: false, left: false, bottom: false, right: false, });
  //eslint-disable-next-line
  const [zipCode, setZipCode] = useState("");
  let defaultZipCode = useSelector((state) => state.auth.defaultZipCode) || sessionStorage.getItem("defaultZipCode");
  useEffect(() => {
    setZipCode(defaultZipCode);
  }, [defaultZipCode]);

  const onLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  function clickLogin() {
    navigate("/");
  }

  const redirectAbout = () => {
    let path;
    if (token) {
      path = `${process.env.REACT_APP_ABOUT}?q=${zipCode}&token=${token}` || `https://qa.lwls.live/about?q=${zipCode}&token=${token}`;
       }
    else { path = `${process.env.REACT_APP_ABOUT}`; }
    window.location.assign(path);
  };

  const redirectServices = () => {
    let path;
    if (token) { path = `${process.env.REACT_APP_SERVICES}?q=${zipCode}&token=${token}` || `https://qa.lwls.live/services?q=${zipCode}&token=${token}`; }
    else { path = `${process.env.REACT_APP_SERVICES}` || "https://qa.lwls.live/services"; }
    window.location.assign(path);
  };

  const redirectFaq = () => {
    let path;
    if (token) { path = `${process.env.REACT_APP_FAQ}?q=${zipCode}&token=${token}` || `https://qa.lwls.live/faq?q=${zipCode}&token=${token}`; }
    else { path = process.env.REACT_APP_FAQ || "https://qa.lwls.live/faq"; }
    window.location.assign(path);
  };

  const redirectContactus = () => {
    let path;
    if (token) { path = `${process.env.REACT_APP_CONTACT}?q=${zipCode}&token=${token}` || `https://qa.lwls.live/contact-us?q=${zipCode}&token=${token}`; }
    else { path = process.env.REACT_APP_CONTACT || "https://qa.lwls.live/contact-us"; }
    window.location.assign(path);
  };

  //  Start an Order!
  const DirectToOrder = () => {
    dispatch(reset());
    navigate("/order");
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift"))
    { return; }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ backgroundColor: "#222222", height: "100%" }}
    >
      <List>
        <div>
          {
            <>
              <ListItem
                button
                onClick={() => navigate("/about")}
                style={{ backgroundColor: "#333", paddingLeft: "3px" }}
              >
                <ListItemText>
                  {" "}
                  <p className={classes.para}>About</p>{" "}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => navigate("/services")}
                style={{ backgroundColor: "#333", paddingLeft: "3px" }}
              >
                <ListItemText>
                  {" "}
                  <p className={classes.para}>Services</p>{" "}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => navigate("/faq")}
                style={{ backgroundColor: "#333", paddingLeft: "3px" }}
              >
                <ListItemText>
                  {" "}
                  <p className={classes.para}>F.A.Q.</p>{" "}
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={() => navigate("/contact-us")}
                style={{ backgroundColor: "#333", paddingLeft: "3px" }}
              >
                <ListItemText>
                  {" "}
                  <p className={classes.para}>Contact</p>{" "}
                </ListItemText>
              </ListItem>
              <Divider />
            </>
          }
          {token && (
            <>
              {/* <ListItem button onClick={() => navigate("/myaccount")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp;Home</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => DirectToOrder()} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp;Start an Order!</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => navigate("/myaccount/upcoming")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp;Upcoming Services</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => navigate("/myaccount/completedservices")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp;Completed Services</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => navigate("/myaccount/mywallet")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp; My Wallet</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => navigate("/myaccount/manage")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp; My Account Details</p> </ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={() => navigate("/myaccount/paymenthistory")} style={{ backgroundColor: '#333', }}>
                <ListItemText> <p className={classes.para}>&nbsp;&nbsp;&nbsp; Payment History</p> </ListItemText>
              </ListItem>
              <Divider /> */}
              <ListItem
                button
                onClick={() => navigate("/myaccount")}
                style={{ backgroundColor: "#333" }}
              >
                <ListItemText>
                  <div
                    style={{ display: "flex", flexDirection: "row" }}
                    className={classes.iconText}
                  >
                    <PersonIcon
                      style={{ marginRight: "10px" }}
                      fontSize="small"
                    />
                    My Account
                  </div>
                </ListItemText>
              </ListItem>
              <Divider />
            </>
          )}

          {!token ? (
            <ListItem
              button
              onClick={clickLogin}
              style={{ backgroundColor: "#333", paddingLeft: "3px" }}
            >
              <ListItemText>
                {" "}
                <p className={classes.para}>Login</p>{" "}
              </ListItemText>
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={onLogout}
              style={{ backgroundColor: "#333" }}
            >
              <ListItemText>
                <div
                  style={{ display: "flex", flexDirection: "row" }}
                  className={classes.iconText}
                >
                  <PowerSettingsNewIcon
                    style={{ marginRight: "10px" }}
                    fontSize="small"
                  />
                  Logout
                </div>
              </ListItemText>
            </ListItem>
          )}
          <Divider />
          {!token && (
            <ListItem
              button
              onClick={() => navigate("/signup")}
              style={{ backgroundColor: "#333", paddingLeft: "3px"}}
            >
              <ListItemText>
                {" "}
                <p className={classes.para}>Signup</p>{" "}
              </ListItemText>
            </ListItem>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => ( <React.Fragment key={anchor}>
          <IconButton className={classes.iconbtn} onClick={toggleDrawer(anchor, true)} >
            <MenuIcon style={{ color: "black", backgroundColor: "#aacbe9", height: "40px", width: "40px", borderRadius: "4px", padding: "5px", }} />
          </IconButton>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} > {list(anchor)} </Drawer>
        </React.Fragment> ))}
    </div>
  );
}
