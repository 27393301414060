import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { reset } from '../../store/actions/orderAction';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';


export default function OrderFailDialog({
  open,setFailDialog
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleYes = async () => {
    dispatch(reset());
    navigate('/order');
    setFailDialog(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Unable to place order'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {`Item prices have been changed. Please replace the order.`}
          </DialogContentText>
  
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} autoFocus color='success' variant='contained'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
