import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent } from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    fontFamily: '"Lato",sans-serif',
    marginTop: 20,
    lineHeight: '1.37rem',
    color: '#61a8c3',
    fontWeight: '700',
    fontSize: '1.18rem',
  },

  text: { 
    padding: '0.4rem',
    marginBottom: '0.2rem',
    '&:hover': {
      backgroundColor: '#cfd3d0',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5rem',
    marginBottom: '1.5rem',
  },
}));

export default function AlertDialog({
  open,
  setOpen,
  selectedValue,
  getUnattendedVal
}) {
 
  const [unattendedNote, setUnattendedNote] = useState(null); 

  const classes = useStyles();
  
  const handleClose = () => {
    setOpen(false);
    setUnattendedNote(null); 
    getUnattendedVal(null);
  };


  useEffect(() => {
    if (unattendedNote !== null) {
      getUnattendedVal(unattendedNote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [unattendedNote])
  

  useEffect(() => {
    if (selectedValue === "a") {
      setUnattendedNote(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [selectedValue])
  
  return (
    <div>
 <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        fullWidth={'sm'}
        maxWidth={'sm'}
      >
        <h3 className={classes.root}>Do we have access to your laundry?</h3>

        <DialogContent>
          <div className={classes.text}
            style={{
              backgroundColor: unattendedNote === "Yes, you already have my key" ? "#63C04F" : "#e6eae7", 
              cursor:'pointer'
            }}
            onClick={() => {
              setUnattendedNote("Yes, you already have my key");
            }}
          > <p>Yes, you already have my key</p>
          </div>

          <div className={classes.text}
             style={{
              backgroundColor: unattendedNote === "No, you will need access instructions" ? "#63C04F" : "#e6eae7", 
              cursor:'pointer'
            }}
            onClick={() => {
              setUnattendedNote("No, you will need access instructions");
            }}
          > <p>No, you will need access instructions</p>
          </div>

          <div className={classes.text}
             style={{
              backgroundColor: unattendedNote === "I'll give you my key when you get here" ? "#63C04F" : "#e6eae7", 
              cursor:'pointer'
            }}
            onClick={() => {
              setUnattendedNote("I'll give you my key when you get here");
            }}
          > <p>I'll give you my key when you get here</p>
          </div>


          <div className={classes.button}>
            <Button
              variant='outlined'
              color='primary'
              style={{
                textTransform: 'capitalize',
                fontFamily: '"Lato",sans-serif',
                marginLeft: "0.5rem"
              }}
              onClick={handleClose}
            >
              Nevermind
            </Button>

            <Button
              variant="contained"
              style={{
                textTransform: 'capitalize',
                fontFamily: '"Lato",sans-serif',
                marginLeft: "0.5rem",
                backgroundColor: "#008EFF",
                color:"white"
              }}
              onClick={()=> setOpen(false) }
            >
            Submit
            </Button>
          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
}
