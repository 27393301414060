import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled, Grid } from "@mui/material";
import StepConnector, { stepConnectorClasses, } from "@mui/material/StepConnector";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@mui/material/CircularProgress';

export default function HorizontalLabelPositionBelowStepper({ order, from }) {
  const [steps, setSteps] = useState([])
  const stepFunc = () => {
    if (order?.isSpecial) {
      setSteps(["Order is Placed", "Set to pickup", "Pickedup", "Processing", "Set to delivery", "Delivered",
        "Set to delivery (Special Care)", "Delivered (Special Care)", ])
    } else {
      setSteps( [ "Order is Placed", "Set to pickup", "Pickedup", "Processing", "Set to delivery", "Delivered", ])
    }
  } 

  const useStyles = makeStyles((theme) => ({
    hideonSm: { display: "initial", [theme.breakpoints.down("sm")]: { display: "none", }, },
    hideonLg: { display: "none", [theme.breakpoints.down("sm")]: { display: "initial", }, },
  }));
 
  const StyledStepConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: { top: 17, },
    [`&.${stepConnectorClasses.active}`]: { [`& .${stepConnectorClasses.line}`]: { backgroundColor: "#2CCA5C", }, },
    [`&.${stepConnectorClasses.completed}`]: { [`& .${stepConnectorClasses.line}`]: { backgroundColor: "#2CCA5C", }, },
    [`& .${stepConnectorClasses.line}`]: { height: 6, border: 0, backgroundColor: theme.palette.mode === "dark" ?
      theme.palette.grey[800] : "#ccc", borderRadius: 1,
    },
  }));
  
  const classes = useStyles();
  let activeStep;
  if (order?.isSpecial) {
    switch (order.orderStatus) {
      case "Order is Placed":
        activeStep = 0;
        break;
      case "Set to pickup":
        activeStep = 1;
        break;
      case "Pickedup":
        activeStep = 2;
        break;
      case "Pickup Canceled":
        activeStep = 2;
        break;
      case "Processing":
        activeStep = 3;
        break;
      case "Set to delivery":
        activeStep = 4;
        break;
      case "Delivered":
        activeStep = 5;
        break;
      case "Delivery Canceled":
        activeStep = 5;
        break;
      case "Set to delivery (Special Care)":
        activeStep = 6;
        break;
      case "Delivered (Special Care)":
        activeStep = 8;
        break;
      case "Delivery Canceled (Special Care)":
        activeStep = 7;
        break;
      default:
        break;
    }

  } else {
    switch (order.orderStatus) {
      case "Order is Placed":
        activeStep = 0;
        break;
      case "Set to pickup":
        activeStep = 1;
        break;
      case "Pickedup":
        activeStep = 2;
        break;
      case "Pickup Canceled":
        activeStep = 2;
        break;
      case "Processing":
        activeStep = 3;
        break;
      case "Set to delivery":
        activeStep = 4;
        break;
      case "Delivered":
        activeStep = 5;
        break;
      case "Delivery Canceled":
        activeStep = 5;
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (order) { stepFunc() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order])
  const isStepFailed = (step) => {
    return step === 1;
  };

  return (
    <Grid container>
      <Grid item container alignItems="center" justifyContent="center" >
        <Grid item className={classes.hideonLg} >
          <Box sx={{ width: "100%" }}>
            {(from === "placed" ? true : order.orderId !== "Loading...") ?
              ( <Stepper activeStep={activeStep} orientation="vertical" >
                  {steps.map((label, index) => {
                    const labelProps = { optional: "", error: "" };
                    if (isStepFailed(index)) { labelProps.error = true; }
                    return (
                      <Step key={label}>
                        <StepLabel error={(((order.normalOrderStatus === "pickupCancel"
                          || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                              ((order.normalOrderStatus === "deliveryCancel" ||
                                order.orderStatus === "Delivery Canceled") ?
                                index === 5 : null) || ((order.normalOrderStatus === "deliveryCancel" &&
                                order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? true : false)}
                        
                        sx={{ "& .MuiStepLabel-alternativeLabel": { fontSize: "11px", color: ( (
                                ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : null)) },
                          '& .MuiStepIcon-root': { zIndex: 1, fontSize: "2.5rem", color: ((
                                ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : null)) },
                          '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
                            color : ( ( ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : "green")) }
                          }} >
                        {(label === "Pickedup" && order.orderStatus === "Pickup Canceled") ? "Pickup Canceled" :
                          (label === "Delivered" && (order.orderStatus === "Delivery Canceled" || order.normalOrderStatus === "deliveryCancel")) ? "Delivery Canceled" :
                            (label === "Delivered (Special Care)" && order.orderStatus === "Delivery Canceled (Special Care)") ? "Delivery Canceled (Special Care)" : label}
                      </StepLabel>
                      </Step> ) })}
                </Stepper> ) : ( <CircularProgress sx={{ color: "#cfcccc" }} /> ) }
          </Box>
        </Grid>
        <Grid item className={classes.hideonSm} >
          <Box sx={{ width: "100%" }}>
            {(from === "placed" ? true : order.orderId !== "Loading...") ?
              (<Stepper alternativeLabel activeStep={activeStep} connector={<StyledStepConnector />} >
                {steps.map((label, index) => {
                  const labelProps = { optional: "", error: "" };
                  if (isStepFailed(index)) { labelProps.error = true; }
                  return (
                    <Step key={label}>
                      <StepLabel
                        error={(((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                              ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                              ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? true : false)} 
                        sx={{ "& .MuiStepLabel-alternativeLabel": { fontSize: "11px",
                            color: (( ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : null)) },
                              '& .MuiStepIcon-root': { zIndex: 1, fontSize: "2.5rem",
                            color: (( ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : null)) },
                          '& .Mui-completed': { color : ((
                                ((order.normalOrderStatus === "pickupCancel" || order.orderStatus === "Pickup Canceled") ? index === 2 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" || order.orderStatus === "Delivery Canceled") ? index === 5 : null) ||
                                  ((order.normalOrderStatus === "deliveryCancel" && order.orderStatus === "Delivery Canceled (Special Care)") && index === 7 ) ? "red" : "green")) } }} >
                        {(label === "Pickedup" && order.orderStatus === "Pickup Canceled") ? "Pickup Canceled" :
                          (label === "Delivered" && (order.orderStatus === "Delivery Canceled" || order.normalOrderStatus === "deliveryCancel")) ? "Delivery Canceled" :
                            (label === "Delivered (Special Care)" && order.orderStatus === "Delivery Canceled (Special Care)") ? "Delivery Canceled (Special Care)" : label}
                        </StepLabel>
                    </Step> ) })}
              </Stepper>
              ) : (
                <CircularProgress sx={{ color: "#cfcccc" }} />)}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
