import {
  ORDER,
  PICKUP,
  DELIVERY,
  DELIVERY2,
  ADDORDER,
  ADDITEMS,
  ADDTITEMS,
  RESET,
  SET_REWARDS,
  SET_PROMO,
  SET_DISCOUNT,
  SET_ORDERID,
} from "./types";

export const orderType = (orderNumber) => (dispatch) => {
  dispatch({
    type: ORDER,
    payload: {
      orderNumber,
    },
  });
};

export const pickup = (data) => (dispatch) => {
  dispatch({
    type: PICKUP,
    payload: {
      data,
    },
  });
};

export const delivery = (data) => (dispatch) => {
  dispatch({
    type: DELIVERY,
    payload: {
      data,
    },
  });
};

export const delivery2 = (data) => (dispatch) => {
  dispatch({
    type: DELIVERY2,
    payload: {
      data,
    },
  });
};
export const addorder = (data) => (dispatch) => {
  dispatch({
    type: ADDORDER,
    payload: {
      data,
    },
  });
};

export const additems = (data) => (dispatch) => {
  dispatch({
    type: ADDITEMS,
    payload: {
      data,
    },
  });
};

export const addTitems = (data) => (dispatch) => {
  dispatch({
    type: ADDTITEMS,
    payload: {
      data,
    },
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setRewards = (data) => (dispatch) => {
  dispatch({
    type: SET_REWARDS,
    payload: { rewards: data },
  });
};

export const setPromo = (data) => (dispatch) => {
  dispatch({
    type: SET_PROMO,
    payload: { promo: data },
  });
};


export const setDiscount = (data) => (dispatch) => {
  dispatch({
    type: SET_DISCOUNT,
    payload: { discount: data },
  });
};

export const setOrderid = (data) => (dispatch) => {
  dispatch({
    type: SET_ORDERID,
    payload: { data },
  });
};