import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
    Grid,
    TextField,
    Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import DialogAlertMain from 'components/common/DialogAlertMain';
import useAxiosPrivate from 'utils/hooks/useAxiosPrivate';
import { StyledButton } from './style';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';

export default function CardEdit({ detach, ending, cardDetails, defaultCheck, expDateAndMonth, updateDefault, cardId, getPaymentMethods }) {
    const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
    const [open, setOpen] = useState(false);
    const [editExpDate, setEditExpDate] = useState(false);
    const [cardValidation, setCardValidation] = useState(false);
    const [isDateValid, setIsDateValid] = useState(true);
    const [editDate, setEditDate] = useState({
        expMonth: '',
        expYear: '',
    });

    const [isDefaultBtnEdited, setIsDefaultBtnEdited] = useState(false);
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const stripe = useStripe();
    const elements = useElements();
    const axios = useAxiosPrivate();

    useEffect(() => {
        setEditDate({
            expMonth: '',
            expYear: '',            
        })
    },[editExpDate])

    useEffect(() => {
        setIsSaveBtnDisabled(((!editExpDate || !cardValidation) && !isDateValid) || ((editDate.expMonth === '' || editDate.expYear === '') && editExpDate) || (!isDefaultBtnEdited && !editExpDate) ? true : false)
    },[cardValidation, editDate.expMonth, editDate.expYear, editExpDate, isDateValid, isDefaultBtnEdited])

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        const cardExpiryElement = elements.getElement(CardExpiryElement);
        let result = null;
        
        if(isDefaultBtnEdited && !editExpDate){
                updateDefault(cardId);
                setAlert({ showAlert: true, severity: "success", message: "The default card was added successfully", });
                handleClose();
        }else if (isDefaultBtnEdited && editExpDate) {
             result = await axios.put('/customer/update-card-expiry', {
                id: cardId,
                expMonth: editDate.expMonth,
                expYear: editDate.expYear,
            });
            getPaymentMethods()
            updateDefault(cardId);
            setAlert({ showAlert: true, severity: "success", message: "The default card was added successfully", });
        }else{
            result = await axios.put('/customer/update-card-expiry', {
                id: cardId,
                expMonth: editDate.expMonth,
                expYear: editDate.expYear,
            });
            getPaymentMethods()
        }


        if (result && result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            handleClose();
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setEditExpDate(false);
        setCardValidation(false);
        setOpen(false);
        setIsDefaultBtnEdited(false)
    };

    const handleYes = () => {
        detach();
        handleClose();
    };

    const handleRemoveCard = () => {
        if (defaultCheck) {
            setAlert({ showAlert: true, severity: "error", message: "Default card cannot be removed", });
        } else {
            handleYes()
        }
    }

    const handleChange = change => {
        if(change.complete){
            setCardValidation(true)
        }else{
            setCardValidation(false);
        }
    };

    const handleCancelEdit = () => {
        setEditExpDate(false);
        setCardValidation(false);
    }

    const handleTextFieldValue = (date) => {
        if (date) {
            const currentDate = dayjs(); 
            const selectedDate = date.startOf('month'); 
        
            if (selectedDate.isBefore(currentDate, 'month')) {
                setIsDateValid(false)          
            } else {

                const formattedDate = date.format('MM/YY');
                
                if(formattedDate === 'Invalid Date'){
                    setIsDateValid(false)
                }else{
                    setIsDateValid(true)
                    const formattedMonth = date.format('MM');
                    const formattedYear = date.format('YYYY');

                    setEditDate({
                        expMonth: formattedMonth,
                        expYear: formattedYear,
                    })
                }              
            }
          }
	}

    return (
        <div>
            <Typography onClick={handleClickOpen}
                sx={{
                    cursor: 'pointer',
                    color: '#1976D2',
                    fontSize: '0.8em',
                    font: 'Lato",sans-serif',
                    lineHeight: '1.5em',
                    fontWeight: '500',
                    textAlign: 'left',
                }}>Edit
            </Typography>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                maxWidth='xs'
                fullWidth

            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle id='alert-dialog-title'>
                        <Grid container item
                            justifyContent={'flex-end'}
                            alignItems='center'
                            xs={12}
                            xm={12}
                            md={12}
                            lg={12}>
                            <CloseIcon onClick={handleClose} sx={{
                                cursor: 'pointer',
                                color: '#7E8384',
                            }} />
                        </Grid>
                        <Grid container item
                            justifyContent={'center'}
                            alignItems='center'
                            xs={12}
                            xm={12}
                            md={12}
                            lg={12}>
                            <Typography sx={{
                                cursor: 'pointer',
                                color: '#457FC2',
                                fontSize: '1.25rem',
                                font: 'Lato",sans-serif',
                                lineHeight: '1.5em',
                                fontWeight: '700',
                                textAlign: 'center',
                            }}>Edit Your Card
                            </Typography>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            <Grid container item
                                justifyContent={'center'}
                                alignItems='center'
                                xs={12}
                                xm={12}
                                md={12}
                                lg={12}>
                                <Grid item
                                    justifyContent={'center'}
                                    alignItems='center'
                                    xs={10}
                                    xm={10}
                                    md={10}
                                    lg={10}>
                                    <Typography sx={{
                                        cursor: 'pointer',
                                        color: '#30313D',
                                        fontSize: '1rem',
                                        font: 'Lato",sans-serif',
                                        lineHeight: '1.5em',
                                        fontWeight: '500',
                                    }}>Expiration date
                                    </Typography>
                                    <Grid item container alignItems={"center"}>
                                        <Grid item
                                            xs={10}
                                            xm={10}
                                            md={10}
                                            lg={10}>
                                            {editExpDate ?
                                            <>
                                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateField
                                                  format="MM/YY" 
                                                  onChange={handleTextFieldValue} 
                                                  formatDensity="dense" 
                                                  slotProps={{ textField: { size: 'small' } }}
                                                  style={{
                                                    boxShadow: "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px", 
                                                    borderColor: 'white', 
                                                    borderRadius: '4px', 
                                                    margin: '15px 0px', 
                                                    fontSize: '1rem', "& .MuiInputBase-input.Mui-disabled": {
                                                        WebkitTextFillColor: "#000000",
                                                    },
                                                    width: '100%',
                                                    // backgroundColor:"red",
                                                    color:"red",
                                                    // color:"red"
                                                  }}
                                                  />
                                               </LocalizationProvider>
                                            </>
                                                    
                                                    :
                                                <TextField
                                                    id="filled-multiline-static"
                                                    placeholder="Pickup instructions"
                                                    variant="outlined"
                                                    value={expDateAndMonth}
                                                    fullWidth
                                                    size='small'
                                                    disabled
                                                    sx={{
                                                        boxShadow: "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px", 
                                                        borderColor: 'white', 
                                                        borderRadius: '4px', 
                                                        margin: '15px 0px', 
                                                        fontSize: '1rem', "& .MuiInputBase-input.Mui-disabled": {
                                                            WebkitTextFillColor: "#000000",
                                                        },
                                                        // width:"275px",
                                                    }}
                                                />
                                            }
                                        </Grid>
                                        <Grid item xs={2} xm={2} md={2} lg={2} alignItems={"center"} sx={{ textAlign: 'center' }}>
                                            {editExpDate ?
                                                <Typography onClick={() => handleCancelEdit()}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: '#1976D2',
                                                        fontSize: '0.8em',
                                                        font: 'Lato",sans-serif',
                                                        lineHeight: '1.5em',
                                                        fontWeight: '500',
                                                    }}>Cancel
                                                </Typography>
                                                :
                                                <Typography onClick={() => setEditExpDate(true) }
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: '#1976D2',
                                                        fontSize: '0.8em',
                                                        font: 'Lato",sans-serif',
                                                        lineHeight: '1.5em',
                                                        fontWeight: '500',
                                                    }}>Edit
                                                </Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item container alignItems={"center"}>
                                        <Checkbox {...label} size="medium" defaultChecked={defaultCheck} onChange={e => {
                                            if (e.target.checked) {
                                                setIsDefaultBtnEdited(true)
                                            }
                                        }} disabled={defaultCheck}
                                            sx={{
                                                '&.Mui-disabled': {
                                                    color: "#1976d2",
                                                },
                                            }}
                                        />
                                        <Typography sx={{
                                            color: '#666',
                                            fontSize: '0.875rem',
                                            font: 'Lato",sans-serif',
                                            lineHeight: '1.5em',
                                            fontWeight: '500',
                                        }}>Set as default card
                                        </Typography>
                                    </Grid>
                                    <Grid item container alignItems={"center"} justifyContent={"center"} sx={{ marginTop: "2rem" }} onClick={() => handleRemoveCard()}>
                                        <Typography sx={{
                                            cursor: 'pointer',
                                            color: '#EF0622',
                                            fontSize: '0.875rem',
                                            font: 'Lato",sans-serif',
                                            lineHeight: '1.5em',
                                            fontWeight: '500',
                                        }}>Remove from wallet
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid item container alignItems={"center"} justifyContent={"center"} spacing={2}>
                            <Grid item>
                                <Button onClick={handleClose} sx={{
                                    width: '5.7rem',
                                    color: 'black', fontSize: '0.875rem',
                                    font: 'Lato",sans-serif',
                                    lineHeight: '1.5em',
                                    fontWeight: '500',
                                }}>
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <StyledButton autoFocus sx={{
                                    width: '5.7rem',
                                    backgroundColor: isSaveBtnDisabled ? "grey":"#2CCA5C", 
                                    color: 'white', 
                                    fontSize: '0.875rem',
                                    font: 'Lato",sans-serif',
                                    lineHeight: '1.5em',
                                    fontWeight: '500',
                                }}
                                    type="submit"
                                    disabled={isSaveBtnDisabled}
                                    onClick={handleSubmit}>
                                    SAVE
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
            {alert.showAlert && (
                <DialogAlertMain setAlert={setAlert} alert={alert} />
            )}
        </div>
    );
}
