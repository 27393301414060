// Auth actions
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ORDER = 'ORDER';
export const PICKUP = 'PICKUP';
export const DELIVERY = 'DELIVERY';
export const DELIVERY2 = 'DELIVERY2';
export const ADDORDER = 'ADDORDER';
export const ADDITEMS = 'ADDITEMS';
export const ADDTITEMS = 'ADDTITEMS';
export const RESET = 'RESET';
export const SET_REWARDS = 'SET_REWARDS';
export const SET_PROMO = 'SET_PROMO';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_ORDERID = 'SET_ORDERID';
export const PAYMENTCARDLIST = 'PAYMENTCARDLIST';


//Zipcode 
export const ZIPCODE = 'ZIPCODE';
export const DEFAULTADRESS = 'DEFAULTADRESS';
export const DEFAULTLOCATION = 'DEFAULTLOCATION';
