import React from "react";
import Pickup from "screens/pickup/Pickup";
import DryClean from "screens/dryClean/DryClean";
import Tailoring from "screens/tailoring/Tailoring";
import Combine from "screens/combine/Combine";

export default function Form() {
  const point = JSON.parse(sessionStorage.getItem("orderNumber"));
  const ShowForm = ({ pointer }) => {
    let MyComponent = Pickup;
    switch (Number(pointer)) {
      case 1:
        MyComponent = Pickup;
        break;
      case 2:
        MyComponent = DryClean;
        break;
      case 3:
        MyComponent = Tailoring;
        break;
      case 4:
        MyComponent = Combine;
        break;
      case 5:
        MyComponent = Tailoring;
        break;
      case 6:
        MyComponent = Tailoring;
        break;
      case 7:
        MyComponent = DryClean;
        break;
      case 8:
        MyComponent = DryClean;
        break;
      case 9:
        MyComponent = Combine;
        break;
      case 10:
        MyComponent = Combine;
        break;
      default:
        break;
    }
    return <MyComponent />;
  };

  return (
    <div>
      <ShowForm pointer={point.orderType} />
    </div>
  );
}
