import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useDispatch } from "react-redux";
import { paymentCardsList } from "../../store/actions/authActions";
import { useNavigate } from 'react-router-dom';
const success = { '&.MuiButton-root': { background: '#2cca5c' } };
const primary = { '&.MuiButton-root': { background: '#0095E4' } };

export default function PaymentDialog({
  open,
  closeDialog,
  tryPayment,
}) {
  const axios = useAxiosPrivate();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    // setOpen(false);
    closeDialog();
    navigate('/checkout');
  };

  const handleYes = async () => {
    // detach();
    tryPayment();
  };

  const getPaymentMethods = async () => {
    try {
      const { data } = await axios.get(`/customer/payment-methods`);
      setPaymentMethods(data.cardList);
      dispatch(paymentCardsList(data));
    } catch (error) {
      // please handle errors
    }
  };

  const updateDefault = async (id) => {
    try {
      await axios.put('/customer/update-payment-method', {
        id,
      });
      getPaymentMethods();
    } catch (error) {
      // please handle errors
    }
  };

  useEffect(() => {
    getPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Payment unsuccessful'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {`Your payment was unsuccessful. Please try again. `}
          </DialogContentText>
          <Grid container mt={1} spacing={1} md={12} lg={12}>
            {paymentMethods?.map((card) => (
              <Grid item key={card.id} xs={12} md={12} lg={12}>
                <Button
                  id={card.id}
                  variant='contained'
                  sx={card.isDefault ? success : primary}
                  fullWidth={true}
                  onClick={(e) => {
                    updateDefault(e.target.id);
                  }}
                >
                  {card.isDefault ? <CheckIcon /> : ''} Use Card Ending in{' '}
                  {card.last4}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='contained' >
            Go Back
          </Button>
          <Button onClick={handleYes} autoFocus color='success' variant='contained'>
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
