import { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid } from '@mui/material';

const PaymentStatus = ({ callBack }) => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      // Inspect the SetupIntent `status` to indicate the status of the payment
      // to your customer.
      //
      // Some payment methods will [immediately succeed or fail][0] upon
      // confirmation, while others will first enter a `processing` state.
      //
      // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
      switch (setupIntent?.status) {
        case 'succeeded':
          // setMessage('Success! Your payment method has been saved.');
          // setTimeout(() => {
            navigate(`${callBack}`);
          // }, 4000);
          break;

        case 'processing':
          setMessage(
            "Processing payment details. We'll update you when processing is complete."
          );
          setTimeout(() => {
            navigate(`${callBack}`);
          }, 4000);
          break;

        case 'requires_payment_method':
          // Redirect your user back to your payment page to attempt collecting
          // payment again
          setMessage(
            'Failed to process payment details. Please try another payment method.'
          );
          setTimeout(() => {
            navigate(`${callBack}`);
          }, 4000);
          break;
        default:
          setMessage('');
          setTimeout(() => {
            navigate(`${callBack}`);
          }, 4000);
          break;
      }
    });
  }, [stripe, navigate, callBack]);

  return (
    <Grid
      container
      mt={5}
      justify='center'
      alignItems='center'
      direction='column'
    >
      <Box
        mt={5}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='h5'>{message}</Typography>
      </Box>
    </Grid>
  );
};

export default PaymentStatus;
