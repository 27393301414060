import React, { useState } from "react";
import { Grid, Card, CardContent, CardActions, Typography, CardHeader, } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import DialogAlertMain from "components/common/DialogAlertMain";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
// validation
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import SearchLocation from "./SearchLocation";
import { login, setDefaultLocation } from "../../store/actions/authActions";

import { useDispatch } from "react-redux";
import ServiceAlert from "components/common/locationNotFound";

const useStyles = makeStyles((theme) => ({
  fieldWidth: { width: "100%", },
  gridMargin: { marginTop: "10rem", marginBottom: "1rem",
    [theme.breakpoints.down("md")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("sm")]: { marginTop: "10rem", marginBottom: "0rem", },
    [theme.breakpoints.down("xs")]: { marginTop: "8rem", marginBottom: "0rem", }, },
  center: { textAlign: "center", color: "#35BFFF", fontSize: "2em", boxShadow: "5px", fontWeight: "300",
    marginTop: 0, fontFamily: '"Lato",sans-serif', },
  padding: { paddingTop: "2rem", paddingBottom: "3rem", width: "45vw", height: "auto", boxShadow: "0 0 5px 0 rgb(0 0 0 / 20%)",
    borderRadius: "6px",
    [theme.breakpoints.down("md")]: { width: "65vw", },
    [theme.breakpoints.down("sm")]: { width: "70vw", paddingLeft: "2rem", paddingRight: "2rem", },
    [theme.breakpoints.down("xs")]: { width: "90vw", paddingLeft: "0.5rem", paddingRight: "0.5rem", },  },
  button: { width: "100%", backgroundColor: "#2cca5c", color: "white",
    "&:hover": { backgroundColor: "#2cca5c", color: "white", },
  },

  caption: { textAlign: "center", paddingTop: "1rem", paddingLeft: "5rem", paddingRight: "5rem",
    [theme.breakpoints.down("md")]: { paddingLeft: "3rem", paddingRight: "3rem", },
    [theme.breakpoints.down("sm")]: { paddingLeft: "2rem", paddingRight: "2rem", }, },
  cardHeader: { textAlign: "center", fontSize: "1.2rem", color: "#356daf", backgroundColor: "#cdfff", paddingLeft: "3rem",
    paddingRight: "3rem", },
}));

let SignUpSchema = Yup.object().shape({
  address: Yup.string().required("Address is required!"),
  apartmentNo: Yup.string(),
  confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  phoneNumber: Yup.string().matches( /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s]?[\0-9]{3}[\s]?[0-9]{4}$/, "Please enter valid Phone Number" ),
  zipCode: Yup.string().required("Zip Code is required").nullable(),
});

function AddPickupLocation(props) {
    const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const classes = useStyles();
  const location = useLocation();
  const [zipCode, setZipCode] = useState("");
  const [PlacesIdforPost, setPlacesIdforPost] = useState("");
  const [servicveAlert, setServicveAlert] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  //eslint-disable-next-line
  const [initialValues, setInitialValues] = useState({ address: "", nickName: "", apartmentNo: "", zipCode: "", location: { lat: "", lng: "", }, });
  const [backendCall, setBackendCall] = useState(false);
  const [alert, setAlert] = useState({ showAlert: false, severity: "success", message: "", });
  let navigate = useNavigate();

  const checkZipCode = async (zipCode) => { await axios.post(`/user/serviceRegions/`, { zipcode: zipCode, }); };

  const submit = async (e) => {
    try {
      setBackendCall(true);
      await checkZipCode(e.zipCode);

      if (location.state?.from === "settings") {
        await axios.put(`/customer/existing/addLocation/${location.state?.id}`, { apartmentNo: e.apartmentNo, zipCode: e.zipCode, address: e.address,
          nickName: e.nickName, location: e.location, placesId: PlacesIdforPost, state: state, city:city,
        });
        
        setAlert({ showAlert: true, severity: "success", message: "Success!", });
        setTimeout(() => { navigate("/myaccount/manage"); }, 2500);
        
      } else {
        const { data } = await axios.put(`/customer/addLocation/${location.state?.id}`, { apartmentNo: e.apartmentNo, zipCode: e.zipCode, address: e.address,
          nickName: e.nickName, location: e.location, placesId: PlacesIdforPost, state: state, city: city,
        });
        if (data.location) {
          props.login( data?.accessToken, data?.id, data?.defaultZipCode, e.address, data?.firstName, data?.lastName, data?.defaultLocation, data?.phone);
          // navigate("/myaccount/upcoming");
          dispatch(setDefaultLocation(data?.defaultLocation));
        }
        
        setAlert({ showAlert: true, severity: "success", message: "Success!", });
        setTimeout(() => { navigate("/myaccount/upcoming"); }, 2500);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setAlert({ showAlert: true, severity: "error", message: "Unauthorized !", });
      } else if (error.response.status === 403) {
        setAlert({ showAlert: true, severity: "error", message: "Invalid input !", });
      } else if (error.response.status === 404) { 
      if(error.response.data.message ==="Failed Location." || error.response.data ==="Failed Location."){
        setServicveAlert(true)
      }else{
        setAlert({ showAlert: true, severity: "error", message: `${error.response.data}`, });

      }
      } else { setAlert({ showAlert: true, severity: "error", message: error.response.data ?? "Server error!", }); }
    }
    setBackendCall(false);
  };

  return (
    <Grid container className={classes.gridMargin} lg={12} md={12} sm={12} xs={12} justifyContent="center" alignItems="center" >
      <Card className={classes.padding} variant="outlined" sx={{ boxShadow: 3 }} >
        {location.state?.from === "login" && (
          <CardHeader style={{ backgroundColor: "#cdfff" }} className={classes.cardHeader}
            title="Hey there, it looks like you still need to add a pick-up address to complete your signup process." /> )}
        <Typography variant="h1" className={classes.center}> Add Pick-up Location</Typography>
        <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
          <Formik initialValues={initialValues} onSubmit={submit} validationSchema={SignUpSchema} enableReinitialize >
            {({ dirty, isValid, values, setFieldValue }) => {
              return (
                <Grid container item justifyContent="center" alignItems="center" lg={12} md={12} sm={12} xs={12} >
                  <Form style={{ width: "35rem" }}>
                    <CardContent>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Field className={classes.fieldWidth} name="nickName" label="Nickname" component={TextField}
                          variant="outlined" fullWidth margin="dense" ></Field>
                      </Grid>
                     
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <SearchLocation name="address" className={classes.fieldWidth} setFieldValue={setFieldValue} setZipCode={setZipCode}
                          setPlacesIdforPost={setPlacesIdforPost} setState={setState} setCity={setCity} dirty={dirty} isValid={isValid} />
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: "0.4rem" }} >
                        <Field className={classes.fieldWidth} name="zipCode" placeholder="Zip Code" label="Zip Code" component={TextField}
                          value={zipCode} variant="outlined" fullWidth margin="dense" disabled ></Field>
                      </Grid>
                    </CardContent>

                    <CardActions>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <LoadingButton loading={backendCall} variant="contained" className={classes.button}
                          disabled={!dirty || !isValid} type="submit" size="large" > Continue </LoadingButton>
                      </Grid>
                    </CardActions>
                  </Form>
                </Grid>
              );
            }}
          </Formik>
        </Grid>
      </Card>
      <Grid container alignItems="center" justifyContent="center" item lg={12} md={12} sm={12} style={{ marginTop: "1rem" }} >
      {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
      <ServiceAlert servicveAlert={servicveAlert} setServicveAlert={setServicveAlert}/>
      </Grid>
    </Grid>
  );
}

export default connect(null, { login })(AddPickupLocation);
