import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as DryCleaning } from "../../utils/assets/drycleaning.svg";
import { ReactComponent as Washdryfold } from "../../utils/assets/washdryfold.svg";
import { ReactComponent as Tailoring } from "../../utils/assets/tailoring.svg";
import { useStyles } from "./classes";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { orderType } from "../../store/actions/orderAction";
import Separation from "components/separation/Separation";
import PageHeader from "components/UI/PageHeader";
import Confirmbox from "components/UI/Confirmbox";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { Button } from "@material-ui/core";
import { setZipCode, setDefaultAddress, setDefaultLocation } from "../../store/actions/authActions";
import Loader from "components/UI/Loader";
import {
  DialogTitle,
  Dialog,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import ServiceAlert from "components/common/locationNotFound";

const CustomColorCheckbox = withStyles({
  root: { color: "#008EFF", "&$checked": { color: "#008EFF", }, },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


export default function Order() {
  const axios = useAxiosPrivate();
  let address = useSelector((state) => state.auth.defaultAddress);
  let zipCode = useSelector((state) => state.auth.defaultZipCode);

  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [userData, setUserData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [combinedPickupNote, setCombinedPickupNote] = useState(null);
  const [unattendedNote, setUnattendedNote] = useState(null);
  const [isAddressChangeDialogOpen, setIsAddressChangeDialogOpen] = useState(false);
  const [dryCleanData, setDryCleanData] = useState([]);
  const [tailorData, setTailorData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [servicveAlert, setServicveAlert] = useState(false);

  const [showDryCleanData, setShowDryCleanData] = useState(false);
  const [showTailorData, setShowTailorData] = useState(false);

  const handleChange1 = (event) => { setChecked1(event.target.checked); };
  const handleChangeBox1 = (val) => { setChecked1(val); };

  const handleChange2 = (event) => { setChecked2(event.target.checked); };
  const handleChangeBox2 = (val) => { setChecked2(val); };

  const handleChange3 = (event) => { setChecked3(event.target.checked); };
  const handleChangeBox3 = (val) => { setChecked3(val); };

  const [selectedValue, setSelectedValue] = useState("a");
  const handleChange = (event) => { setSelectedValue(event.target.value); };

  const controlProps = (item) => ({
    checked: selectedValue === item, onChange: handleChange, value: item,
    name: "size-radio-button-demo", inputProps: { "aria-label": item },
  });

  const addOrder = async (data) => {
    if (checked1 && !checked2 && !checked3) { data.orderType = "1"; data.servicesSelected = ["Wash/Dry/Fold Service"]; }
    else if (!checked1 && checked2 && !checked3) { data.orderType = "2"; data.servicesSelected = ["Dry Cleaning"]; }
    else if (!checked1 && !checked2 && checked3) { data.orderType = "3"; data.servicesSelected = ["Tailoring & Alterations"]; }
    else if (!checked1 && checked2 && checked3) { data.orderType = "4"; data.servicesSelected = ["Dry Cleaning", "Tailoring & Alterations"]; }
    else if (checked1 && !checked2 && checked3) {
      if (value === "false") { data.orderType = "5"; data.servicesSelected = ["Wash/Dry/Fold Service", "Tailoring & Alterations"]; data.deliveryType = "Separate"; }
      else if (value === "true") { data.orderType = "6"; data.servicesSelected = ["Wash/Dry/Fold Service", "Tailoring & Alterations"]; data.deliveryType = "Together"; }
    }
    else if (checked1 && checked2 && !checked3) {
      if (value === "false") { data.orderType = "7"; data.servicesSelected = ["Wash/Dry/Fold Service", "Dry Cleaning"]; data.deliveryType = "Separate"; }
      else if (value === "true") { data.orderType = "8"; data.servicesSelected = ["Wash/Dry/Fold Service", "Dry Cleaning"]; data.deliveryType = "Together"; }
    }
    else if (checked1 && checked2 && checked3) {
      if (value === "false") { data.orderType = "9"; data.servicesSelected = ["Wash/Dry/Fold Service", "Dry Cleaning", "Tailoring & Alterations"]; data.deliveryType = "Separate"; }
      else if (value === "true") { data.orderType = "10"; data.servicesSelected = ["Wash/Dry/Fold Service", "Dry Cleaning", "Tailoring & Alterations"]; data.deliveryType = "Together"; }
    }

    sessionStorage.setItem("combinedPickupNote", JSON.stringify(combinedPickupNote));

    setTimeout(() => {
      dispatch(orderType(data));
      navigate("/orderFlow");
    }, 0);
  };

  useEffect(() => {
    sessionStorage.setItem('couponValue', "");
    sessionStorage.setItem('setExtraAmount', "");
    sessionStorage.setItem('setRewards', JSON.stringify({ couponVal: "", promoVal: "" }));
    sessionStorage.setItem('promoValue', JSON.stringify(""));
  }, [])

  const loadData = async () => {
    try {
      const { data } = await axios.get("/customer/getData");
      setUserData(data);
    } catch (err) {
      setUserData([]);
    }
  };

  useEffect(() => {
    if (selectedValue === "c") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    loadData();
    //eslint-disable-next-line
  }, [selectedValue]);

  const getUnattendedVal = (val) => {
    setUnattendedNote(val);
  }

  useEffect(() => {
    if (selectedValue === "a") {
      setCombinedPickupNote(null);
    } else {
      setCombinedPickupNote({ note: ((userData?.pickup || userData?.pickup === "") && unattendedNote) ? (userData?.pickup + ` Unattended Note: ${unattendedNote}`) : null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [userData, unattendedNote, selectedValue])

  useEffect(() => {
    sessionStorage.removeItem('additems');
    sessionStorage.removeItem('addTitems');
  }, [])

  const handleAddressChangeDialog = () => {
    setIsAddressChangeDialogOpen(true)
  }

  const updateDefaultLocation = async (id, zipCode) => {
    try {
       await axios.post(`/user/serviceRegions/`, { zipcode: zipCode }); 
      const { data } = await axios.put(`/customer/update-default-location/${id}`);
      dispatch(setZipCode(data.defaultZipCode));
      dispatch(setDefaultAddress(data.defaultAddress));
      dispatch(setDefaultLocation(data.defaultLocation));
      loadData();
      setIsAddressChangeDialogOpen(false)

    } catch (err) {
      if (err.response.status === 404) { 
        setServicveAlert(true)
      } else{
      console.log("Default location Change Failed")
    }
    }
  };

  const isDisplayInShopTrue = (data) => {
    for (const item of data) {
      if (item?.itemId?.displayInShop === true && item?.itemId?.status === true) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const getDryCleanTaylorData = async () => {
      try {
        const { data } = await axios.post('/user/tailor-dryclean', {
          zipcode: zipCode,
        });

        setDryCleanData(data?.dryCleaning.length > 0 ? data?.dryCleaning : []);
        setTailorData(data?.tailoring.length > 0 ? data?.tailoring : []);

        if (data?.dryCleaning.length > 0 && isDisplayInShopTrue(data?.dryCleaning)) {
          setShowDryCleanData(true);
        } else {
          setShowDryCleanData(false);
        }

        if (data?.tailoring.length > 0 && isDisplayInShopTrue(data?.tailoring)) {
          setShowTailorData(true);
        } else {
          setShowTailorData(false);
        }
        
        setLoader(false);
      } catch (err) {
        console.log("Dry clean & tailor data error")
      }
    };

    getDryCleanTaylorData();

    setChecked1(false);
    setChecked2(false);
    setChecked3(false);

  }, [zipCode])

  return (
    <div>

      <Dialog onClose={() => setIsAddressChangeDialogOpen(false)} open={isAddressChangeDialogOpen}>
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "19px",
            color: "#61A8C3",
            fontFamily: "Lato",
            padding: "10px 0px 10px 0px"
          }}> Choose a Different Location </DialogTitle>
        {
          userData?.location?.map((add) =>
            <List sx={{ pt: 0, padding: 0 }} style={{ padding: "0px" }}>
              <ListItem
                disableGutters
                style={{
                  padding: "0px 20px 0px 20px"
                }}>
                <ListItemButton sx={{
                  padding: "0px",
                  '&:hover': {
                    color: 'red',
                    backgroundColor: '#bab8b8',
                  },
                  backgroundColor: add.isDefault ? '#cfcfcf' : '#eee',
                  marginTop: '2px',
                  marginBottom: '2px',
                  borderRadius: '5px'
                }}>
                  <ListItemText
                    style={{
                      textAlign: "left",
                      paddingLeft: "6px",
                      paddingRight: "6px",
                      color: "#014469",
                    }}
                    onClick={() => updateDefaultLocation(add._id, add.zipCode)}
                  >
                    <span style={{ fontSize: "14px" }}>
                      <span style={{ fontWeight: "700" }}>{`${add?.nickName + (add?.nickName && ' - ')}`}</span>{add?.address}
                    </span>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </List>
          )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px"
          }}>
          <Button
            onClick={() => setIsAddressChangeDialogOpen(false)}
            variant="outlined"
            style={{
              textTransform: "none",
              border: "2px solid #ccc",
              color: "#888"
            }}
          >Nevermind</Button>
        </div>

      </Dialog>

      <PageHeader tittle={"Let's get started with today's order!"} />
      <Confirmbox open={open} setOpen={setOpen} selectedValue={selectedValue} getUnattendedVal={getUnattendedVal} />
      <div className={classes.divroot}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12}> <p className={classes.para1}> What services would you like? </p> </Grid>
            {/* Start Show on LG to SM Devices */}
            <Grid item className={classes.hideOnSm} lg={12} md={12} sm={12} xs={12} >
              <Divider className={classes.divider} />
              <p className={classes.para2}> Pick-up {"&"} Delivery For <span style={{ color: "#008EFF", marginLeft: 5 }}> {`${address} `}<span onClick={handleAddressChangeDialog} style={{ textDecoration: "underline", fontWeight: "900", cursor: "pointer" }}>Change</span></span> </p>
            </Grid>
            {/* END Show on LG to SM Devices */}
            {/* Start Show on SM to XS Devices */}
            <Grid item className={classes.pickUpAddrshowOnSm} lg={12} md={12} sm={12} xs={12} >
              <Divider className={classes.divider} />
              <p className={classes.para2}> Pick-up {"&"} Delivery For <span style={{ color: "#008EFF", marginLeft: 5 }}> {`${address} `} <span onClick={handleAddressChangeDialog} style={{ textDecoration: "underline", fontWeight: "900", cursor: "pointer" }}>Change</span> </span> </p>
            </Grid>
            {/* Start Show on LG to SM Devices */}
            <Grid item container className={classes.hideOnSm} lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
              <RadioGroup row name="position" defaultValue="top" >
                <Grid item lg="auto" md="auto" sm={12}>
                  <FormControlLabel control={<Radio {...controlProps("a")} sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color: "#63C04F", }, }} />}
                    label={<span className={classes.spantext}> {"Attended (you will meet driver)"} </span>} />
                </Grid>
                <Grid item lg="auto" md="auto" sm={12}>
                  <FormControlLabel control={<Radio {...controlProps("c")} sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color: "#63C04F", }, }} />}
                    label={<span className={classes.spantext}> {"Unattended (you will leave laundry out)"} </span>} />
                </Grid>
              </RadioGroup>
            </Grid>
            {/* End Show on LG to SM Devices */}
            {/* Start Show on SM to XS Devices */}
            <Grid container className={classes.showOnSm} item lg={12} md={12} sm={12} xs={12} alignItems="center" justifyContent="center" >
              <RadioGroup name="position" defaultValue="top" >
                <Grid item lg="auto" md="auto" sm={12}>
                  <FormControlLabel control={<Radio {...controlProps("a")} sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color: "#63C04F", }, }} />}
                    label={<span className={classes.spantext}> {"Attended (you will meet driver)"} </span>} />
                </Grid>
                <Grid item lg="auto" md="auto" sm={12}>
                  <FormControlLabel control={<Radio {...controlProps("c")} sx={{ "& .MuiSvgIcon-root": { fontSize: 35, color: "#63C04F", }, }} />}
                    label={<span className={classes.spantext}> {"Unattended (you will leave laundry out)"} </span>} />
                </Grid>
              </RadioGroup>
            </Grid>
            {/* END Show on SM to XS Devices */}
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <div className={classes.layout} style={{ marginTop: "1rem" }}>
          {loader ?
            <Loader />
            :
            <Grid container
              style={{ justifyContent: "center" }}
            >

              <Grid item xs={12} sm={12} md={12} lg={4} style={{ padding: "0.5rem", cursor: "pointer" }} onClick={() => handleChangeBox1(!checked1)} >
                <div className={classes.maindiv}>
                  {checked1 ? (<div className={classes.subdiv1} > <Washdryfold className={classes.csvImage} /> </div>) : (
                    <div className={classes.subdiv2}> <Washdryfold className={classes.csvImage} /> </div>)}
                  <div className={classes.box} data-testid="wash-dry-fold-btn">
                    <CustomColorCheckbox checked={checked1} onChange={handleChange1} style={{ transform: "scale(1.6)", }} />
                    <p className={classes.boxpara}>Wash/Dry/Fold Service</p>
                  </div>
                </div>
              </Grid>

              {
                showDryCleanData ?
                  <Grid item xs={12} sm={12} md={12} lg={4} style={{ padding: "0.5rem", cursor: "pointer" }} onClick={() => handleChangeBox2(!checked2)}>
                    <div className={classes.maindiv}>
                      {checked2 ? (<div className={classes.subdiv1}> <DryCleaning className={classes.csvImage} /> </div>) : (
                        <div className={classes.subdiv2}> <DryCleaning className={classes.csvImage} /> </div>)}
                      <div className={classes.box} data-testid="dry-cleaning-btn">
                        <CustomColorCheckbox checked={checked2} onChange={handleChange2} style={{ transform: "scale(1.6)", }} />
                        <p className={classes.boxpara}>Dry Cleaning</p>
                      </div>
                    </div>
                  </Grid>
                  :
                  <></>
              }

              {
                showTailorData ?
                  <Grid item xs={12} sm={12} md={12} lg={4} style={{ padding: "0.5rem", cursor: "pointer" }} onClick={() => handleChangeBox3(!checked3)}>
                    <div className={classes.maindiv}>
                      {checked3 ? (<div className={classes.subdiv1}> <Tailoring className={classes.csvImage} /> </div>) : (
                        <div className={classes.subdiv2}> <Tailoring className={classes.csvImage} /> </div>)}
                      <div className={classes.box} data-testid="tailoring-btn">
                        <CustomColorCheckbox checked={checked3} onChange={handleChange3} style={{ transform: "scale(1.6)", }} />
                        <p className={classes.boxpara}>Tailoring {"&"} Alterations</p>
                      </div>
                    </div>
                  </Grid>
                  :
                  <></>
              }

            </Grid>
          }
        </div>
        {checked1 && (checked2 || checked3) && (<Separation value={value} setValue={setValue} />)}
        {checked1 && (checked2 || checked3) && value !== "" && (
          <div className={classes.continue} style={{ marginTop: "3rem" }}
            onClick={() => addOrder({
              orderType: "1", zipcode: zipCode, customer: userData, attended: selectedValue === "a" ? true : false,
              separate: value,
            })} >
            <div className={classes.ContinueBtnbox}>
              <Button data-testid="continue-btn-nxt" style={{ color: "white", height: "1rem", fontSize: "0.9rem" }} endIcon={<ArrowForwardIcon />} > Continue {"&"} Customize My Order </Button>
            </div>
          </div>
        )}
        {!checked1 && (checked2 || checked3) && (
          <div className={classes.continue} style={{ marginTop: "3rem" }}
            onClick={() => addOrder({ orderType: "1", zipcode: zipCode, customer: userData, attended: selectedValue === "a" ? true : false, separate: value, })} >
            <div className={classes.ContinueBtnbox}>
              <Button data-testid="continue-btn-nxt" style={{ color: "white", height: "1rem", fontSize: "0.9rem" }} endIcon={<ArrowForwardIcon />} > Continue {"&"} Customize My Order </Button>
            </div>
          </div>)}
        {checked1 && !checked2 && !checked3 && (
          <div className={classes.continue} style={{ marginTop: "3rem" }}
            onClick={() => addOrder({ orderType: "1", zipcode: zipCode, customer: userData, attended: selectedValue === "a" ? true : false, })} >
            <div className={classes.ContinueBtnbox}>
              <Button data-testid="continue-btn-nxt" style={{ color: "white", height: "1rem", fontSize: "0.9rem" }} endIcon={<ArrowForwardIcon />} data-testid="continue-order-btn"> Continue {"&"} Customize My Order </Button>
            </div>
          </div>)}
      </div>
      <ServiceAlert servicveAlert={servicveAlert} setServicveAlert={setServicveAlert}/>
    </div>
  );
}
