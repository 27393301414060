import React, { useState } from 'react';
import DryClean from 'screens/dryClean/DryClean';
import Tailoring from 'screens/tailoring/Tailoring';
export default function Combine() {

  const [triggerd, setTrigger] = useState(false);

  const setTriggerd = (val) => setTrigger(val)

  return (
    <div>
      <DryClean triggerd={triggerd} specialCareRemove={"combind"} />
      <Tailoring setTriggerd={setTriggerd} specialCareRemove={"combind"}/>
    </div>
  );
}
