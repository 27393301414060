import Order from "screens/order/Order";
import UpcomingServices from "components/MyAccount/Upcoming/UpcomingSevices";
import CompletedServices from "components/MyAccount/completed/CompletedServices";
import MyWallet from "components/MyAccount/myWallet/MyWallet";
import MySettings from "components/MyAccount/settings/MySettings";
import PaymentHistory from "components/MyAccount/payment/PaymentHistory";
import Rewards from "components/MyAccount/rewards/getRewards";
import ChangePassword from "components/layouts/ChangePassword";
import AddPickupLocation from "screens/signup/AddPickupLocation";
import NewStripeContainer from "components/MyAccount/myWallet/NewStripeContainer";
import OrderStatus from "components/StatusTracker/OrderStatus";
import ExternalLogout from "./store/actions/ExternalLogout";
import Flow from "./screens/flow/Flow";
import DateTimePicker from "./screens/pickup/Pickup";
import Delivery from "screens/delivery/Delivery";
import SpecialCare from "./screens/specialCare/SpecialCare";
import PlaceOrder from "screens/placeOrder/PlaceOrder";
import Complete from "screens/complete/Complete";
import OrderCardAdded from "./screens/placeOrder/OrderCardAdded";

export const routes = [
  {
    name: "MyAccount",
    element: <UpcomingServices />,
    path: "/myaccount",
  },
  {
    name: "Order",
    element: <Order />,
    path: "/order",
  },
  {
    name: "OrderFlow",
    element: <Flow />,
    path: "/orderFlow",
  },
  {
    name: "Pickup",
    element: <DateTimePicker />,
    path: "/pickup",
  },
  {
    name: "Delivery",
    element: <Delivery />,
    path: "/delivery",
  },
  {
    name: "SpecialCare",
    element: <SpecialCare />,
    path: "/specialCare",
  },
  {
    name: "PlaceOrder",
    element: <PlaceOrder />,
    path: "/checkout",
  },
  {
    name: "Complete",
    element: <Complete />,
    path: "/complete",
  },
  {
    name: "OrderCardAdded",
    element: <OrderCardAdded />,
    path: "/order/payments/setup-complete",
  },
  {
    name: "UpcomingServices",
    element: <UpcomingServices />,
    path: "/myaccount/upcoming",
  },
  {
    name: "CompletedServices",
    element: <CompletedServices />,
    path: "/myaccount/completedservices",
  },
  {
    name: "mywallet",
    element: <MyWallet />,
    path: "/myaccount/mywallet",
  },
  {
    name: "payementstatus",
    element: <NewStripeContainer />,
    path: "/account/payments/setup-complete",
  },
  {
    name: "mysettingst",
    element: <MySettings />,
    path: "/myaccount/manage",
  },
  {
    name: "paymenthistory",
    element: <PaymentHistory />,
    path: "/myaccount/paymenthistory",
  },
  {
    name: "getrewards",
    element: <Rewards />,
    path: "/myaccount/getrewards",
  },
  {
    name: "changepassword",
    element: <ChangePassword />,
    path: "/changepassword",
  },
  {
    name: "addpickuplocation",
    element: <AddPickupLocation />,
    path: "/addlocation",
  },
  {
    name: "orderStatus",
    element: <OrderStatus />,
    path: "/orderStatus",
  },
  {
    name: "logout",
    element: <ExternalLogout />,
    path: "/logout",
  },

];
