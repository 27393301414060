import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    dateStyles: {
      paddingTop: '6px',
      fontSize: '14px',
      color: '#888888',
    },
    paymentStyle: {
      fontSize: '2.4rem',
      fontWeight: '100',
      color: '#888888',
      [theme.breakpoints.down('md')]: {
        fontSize: '2.3rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
    },
    viewbtn: {
      backgroundColor: '#35BFFF',
      color: 'white',
      padding: '5px 10px',
      transition: 'background-color 0.2s',
      '&:hover': {
        backgroundColor: '#1587CD',
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalWrapper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '600px',
      height: '826px',
      overflowY: 'scroll',
      [theme.breakpoints.down('md')]: {
        maxHeight: '826px',
      },
      [theme.breakpoints.down('sm')]: {
        top: '50%',
        width: '90%',
        height: '100%',
      },
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: '10px',
      paddingLeft: '52px',
      paddingRight: '52px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '20px',
        paddingRight: '20px',
      },
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalHeading: {
      color: '#457FC2',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Lato',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: '700',
      textAlign: 'center',
      paddingTop: '30px',
    },
    table: {
      width: '500px',
      border: '1px solid #EEE',
      borderRadius: '4px',
      textAlign: 'left',
      borderCollapse: 'collapse',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    tableHeader: {
      backgroundColor: '#EEE',
      border: '1px solid #D9D9D9',
    },
    tableHeaderCell: {
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingLeft: '25px',
      color: '#1B1B1B',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '600',
    },
    tableRow: {
      borderBottom: '1px solid #EEE',
    },
    tableCell: {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      borderRight: '1px solid #EEE',
      '&:last-child': {
        borderRight: 'none',
        borderLeft: 'none',
      },
      color: '#666',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    tableSubCell: {
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '25px',
      borderRight: '1px solid #EEE',
      '&:last-child': {
        borderRight: 'none',
        borderLeft: 'none',
      },
      color: '#666',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '700',
    },
    invoiceSec: {
      marginTop: '30px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'left',
      alignItems: 'left',
      marginTop: '30px',
      width: '100%',
    },
    rowAdd: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'left',
      alignItems: 'center',
      marginTop: '29px',
      width: '100%',
      '@media (max-width: 576px)': {
        alignItems: 'flex-start',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
      marginTop: '30px',
      width: '100%',
    },
    column2: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'left',
      marginTop: '25px',
      width: '100%',
    },
    title: {
      display: 'flex',
      flexDirection: 'row',
      color: '#1B1B1B',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '600',
      width: '80px',
      '@media (max-width: 499px)': {
        width: '80px',
      },
      '@media (max-width: 414px)': {
        width: '100px',
      },
      '@media (max-width: 390px)': {
        width: '120px',
      },
    },
    icoCal: {
      marginRight: '10px',
      width: '18px',
      height: '20px',
    },
    icoClo: {
      marginRight: '10px',
      width: '20px',
      height: '20px',
      marginLeft: '15px',
      '@media (max-width: 541px)': {
        marginLeft: '0px',
      },
    },
    detail: {
      display: 'flex',
      alignItems: 'center',
      color: '#666',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      width:'262px'
    },
    detailClock: {
      display: 'flex',
      alignItems: 'center',
      color: '#666',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      '@media (max-width: 541px)': {
        marginTop: '10px',
      },
    },
  
    addressDetails: {
      display: 'flex',
      alignItems: 'center',
      color: '#666',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Lato',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    titleHolder: {
      display: 'flex',
      flexDirection: 'row',
    },
    closeBtn: {
      borderRadius: '4px',
      backgroundColor: '#2CCA5C',
      color: '#FFF',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '600',
      marginTop: '30px',
      marginBottom: '30px',
      paddingTop: '13px',
      paddingBottom: '13px',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    pickupDetail: {
      border: '1px solid #E0E0E0',
      borderRadius: '10px',
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingRight: '20px',
      paddingLeft: '20px',
      marginTop: '15px',
      color: '#A79F9C',
      leadingTrim: 'both',
      textEdge: 'cap',
      fontFamily: 'Roboto',
      fontSize:'16px',
      fontStyle: 'normal',
      fontWeight: '400',
    },
    rowBtn: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    dividerStyles:{
      Background: "#888888",
      margin:  "10px",
      [theme.breakpoints.down("sm")]: {
        margin:  "0px",
        marginTop: "10px",
    },
  
    }
  }));
  