import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import SideNav from "components/sideNav/SideNav";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from '@mui/icons-material/Person';
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "4rem 0rem 4rem 0rem",
    justifyContent: "center",
    textAlign: "start",
    [theme.breakpoints.down("sm")]: {
      padding: "1.5rem 1.5rem",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem 0.5rem",
      textAlign: "center",
    },
  },

  order: {
    paddingTop: "1rem",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  children: {
    marginTop: "1rem",
  },

  mywalletHeader: {
    color: "#101d27",
    fontSize: "1.5em",
    fontWeight: "300",
    textAlign: "left",
    borderBottom: "1px solid #ccc",
    paddingBottom: "20px",
    fontFamily: '"Lato",sans-serif',
    marginLeft: "5rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: 0,
    },
  },

}));

export default function MyAccount({ children }) {
  const classes = useStyles();
  const location = useLocation();
  let firstName = useSelector((state) => (state.auth.firstName))
  let lastName = useSelector((state) => (state.auth.lastName));


  const checkLocation = location.pathname


  // useEffect(() => {
  //   setLocation(location.pathname)
  //   /* eslint-disable */
  // }, [])


  return (
    // <Grid
    //   container
    //   spacing={1}
    //   xs={12}
    //   md={12}
    //   lg={12}
    //   className={classes.root}
    // >
    //   <Grid container item xs={12} md={4} spacing={1} justifyContent="center">
    //     <Grid item xs={12} md={12}>
    //       <SideNav />
    //     </Grid>
    //   </Grid>
    //   <Grid
    //     container
    //     spacing={5}
    //     item
    //     xs={12}
    //     md={8}
    //     className={classes.children}
    //   >
    //     {children}
    //   </Grid>
    // </Grid>
    <Container>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4} className={classes.root}>

        {checkLocation === "/myaccount/mywallet" && <Grid item xs={12} sm={12} md={12} lg={12} >
          <Typography className={classes.mywalletHeader}>
            <PersonIcon /> Welcome Back, {firstName} {lastName} </Typography>
        </Grid>}

        <Grid item xs={12} sm={12} md={3} lg={3} > <SideNav /> </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} > {children} </Grid>


      </Grid>

    </Container>


  );
}
