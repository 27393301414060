import React /*, { useState, useEffect } */ from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SetupForm from '../../components/MyAccount/myWallet/SetupForm';

export default function StripeCardContainer({
  setupIntent,
  publishableKey,
  path,
  btnVisiblility,
  addOrdersWithNewCard,
}) {
  const PUBLIC_KEY = publishableKey;
  const stripePromise = loadStripe(PUBLIC_KEY);
  const options = {
    // passing the client secret obtained from backend
    clientSecret: setupIntent,
    appearance: {
      /*...*/
    },
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <SetupForm btnVisiblility={btnVisiblility} path={path} addOrdersWithNewCard={addOrdersWithNewCard} />
    </Elements>
  );
}
