import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useDispatch,useSelector } from 'react-redux';
import { addTitems } from '../../store/actions/orderAction';

export default function CheckboxesTags({ setChange, chnage, list }) {
  const axios = useAxiosPrivate();
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
  const dispatch = useDispatch();
  const [getData, setGetData] = useState([]);

  let item = JSON.parse(sessionStorage.getItem('addTitems'));
  if (item === null) {
    item = [];
  }

  const loadData = async () => {
    try {
      const { data } = await axios.post('/user/tailor', {
        zipcode: zipCode,
      });
      const filterdItems = data?.filter(item => (item?.itemId?.displayInShop === true && item?.itemId?.status === true));
      setGetData(filterdItems);
    } catch (err) {
      setGetData([]);
    }
  };

  const addItems = async (data) => {
    await dispatch(addTitems(data));
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Autocomplete
      data-testid="selectTailItem"
        id='checkboxes-tags-demo'
        options={getData}
        getOptionLabel={(option) => option.itemId.item.name}
        onChange={(event, newValue) => {
          if (newValue) {
            if (list.length === 0) {
              list.push(newValue);
              addItems(list);
              setChange(!chnage);
            } else {
              const duplicate = list.filter(
                (item) => item.itemId.item.id === newValue.itemId.item.id
              );
              if (duplicate.length === 0) {
                list.push(newValue);
                addItems(list);
                setChange(!chnage);
              } else {
                const pure = list.findIndex(
                  (item) => item.itemId.item.id === newValue.itemId.item.id
                );
                list[pure].itemId.quantity =
                  Number(duplicate[0].itemId.quantity) + 1;
                addItems([...list]);
                setChange(!chnage);
              }
            }
          }
        }}
        renderOption={(props, option, { selected }) => (
          <div
            {...props}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontSize: '1rem',
                lineHeight: '1.37rem',
                color: '#777777',
                fontWeight: '400',
              }}
            >
              {option.itemId.item.name}
            </p>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  paddingRight: '1rem',
                  fontSize: '1rem',
                  lineHeight: '1.37rem',
                  color: '#777777',
                  fontWeight: '400',
                }}
              >
                {option.itemId.price.toFixed(2)}
              </p>
              <Button
                variant='contained'
                color='success'
                fontSize='small'
                style={{ textTransform: 'capitalize' }}
                onClick={() => selected()}
              >
                add
              </Button>
            </div>
          </div>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} placeholder='Start Searching...' />
        )}
      />
    </>
  );
}
