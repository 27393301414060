import React from 'react'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@material-ui/core';

export default function DialogAlertMain({ setAlert, alert }) {

  const closeDialog = () => {
    setAlert({
      ...alert,
      showAlert: false,
    })
  }

  return (
    <Dialog
      open={alert?.showAlert}
      onClose={() => {
        setAlert({
          ...alert,
          showAlert: false,
        })
      }
      }
      fullWidth={'sm'}
      maxWidth={'sm'}
    >
      <DialogTitle id='alert-dialog-title'
        sx={{
          textTransform: "uppercase",
          color: "red"
        }}>{alert?.severity}</DialogTitle>

      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "1rem",
          }}
          id='alert-dialog-description'>
          {alert.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}> Cancel </Button>
        <Button onClick={closeDialog}> Confirm </Button>
      </DialogActions>
    </Dialog>

  )
}
