import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import { Formik, Form, Field } from "formik";
import { Grid, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { TextField as TF } from "formik-material-ui";

import { makeStyles } from "@material-ui/core/styles";
import DialogAlertMain from "../common/DialogAlertMain";
import { IconButton, Toolbar } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";

const useStyles = makeStyles((theme) => ({
  btn: {
    background: "#35BFFF",
    color: "white",
    textTransform: "none",
    fontSize: "1.3rem",
    "&:hover": {
      background: "#35BFFF",
    },
  },
}));

export default function AlertDialog({
  open,
  setOpen,
  setDryClean,
  setWashDry,
  setAlternation,
  zipCode,
  setServicveAlert
}) {
  const classes = useStyles();
  let navigate = useNavigate();
  const axios = useAxiosPrivate();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });


  const zipCloseBtn = () => {
    setOpen(false);
    navigate('/');
  };

  const initialValues = {
    zipcode: "",
  };

  const setData = (dry, tailor) => {
    setDryClean(dry);
    setAlternation(tailor);
  };

  const onSubmit = async (e) => {
    try {
      const { data, status } = await axios.post(`/user/serviceRegions`, { zipcode: e.zipcode });
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Submitted Successfully!",
        });

        setOpen(false);
      }
      setWashDry(data.washDry);

      const dryclean = data?.items?.filter((e) => e?.serviceType === "dryCleaning" && e?.displayInShop === true && e?.status === true);
      const alterations = data?.items?.filter((e) => e?.serviceType === "tailoring" && e?.displayInShop === true && e?.status === true);
      setData(dryclean, alterations);
    } catch (e) {
      console.log("error", e.response.status);
      if (e.response.status === 404) {
        setOpen(true);
        setServicveAlert(true)
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: "Something went wrong",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 3000);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    zipcode: Yup.string()
      .required()
      .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "Invalid Zip Code"),
  });


  useEffect(() => {
    if (zipCode) {
      setOpen(false);
      onSubmit({ zipcode: zipCode })
    }
    // eslint-disable-next-line
  }, [zipCode]);

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // disableEscapeKeyDown
        onBackdropClick={zipCloseBtn}
      >

        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={zipCloseBtn}
            aria-label="close"
          >
            <CloseIcon />

          </IconButton>
        </Toolbar>


        <DialogContent>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ touched, errors, values, isValid, handleChange }) => {
              return (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h5">Enter your zip code</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} mt={2}>
                      <Field
                        name="zipcode"
                        label="Zipcode"
                        component={TF}
                        variant="outlined"
                        fullWidth
                        required
                      ></Field>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.btn}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
          {alert.showAlert && (
            // <Alert style={{ maxWidth: "100%", }} severity={alert.severity} onClose={() => setAlert({ ...alert, showAlert: false, })} >
            <DialogAlertMain setAlert={setAlert} alert={alert} />
            // {alert.message} </Alert> 
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
