import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import OrderStatus from "../StatusTracker/OrderStatus"
import { Grid } from "@mui/material";
import DialogAlertMain from "components/common/DialogAlertMain";

export default function OrderTracking() {
  const axios = useAxiosPrivate();
  const { orderId } = useParams();

  const [orders, setOrders] = React.useState();

  const [resOrder, setresOrder] = useState({
    orderId: "Loading...",
    shippedPlace: "Loading...",
    orderStatus: "Loading...",
    dateLabel: "Loading...",
    date: "Loading...",
    specialDate: "Loading...",
  })

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });


  const getData = async () => {

    try {
      const { data } = await axios.get(`/customer/track-order/${orderId}`);

      if (data) {
        setOrders(data);
      }

    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Something went wrong",
          message: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    if (orders) {
      setresOrder({
        orderId: orders?.orderId,
        shippedPlace: orders?.franchiseeId?.fullName,
        statusTracker: orders?.statusTracker,
        specialCareTracker: orders?.specialCareTracker,
        pickupDate: orders?.pickupDate,
        specialDate: orders?.specialDate,
        diliverDate: orders?.diliverDate,
        orderType: orders?.orderType
      });
    } else {
      getData();
    }
    //eslint-disable-next-line
  }, [orders])


  return (
    <div>
       {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
      <Grid container>
        <Grid
          container item
          justifyContent="center"
          alignContent="center"
        >
          <Grid item
            lg={10}
            md={10}
            sm={10}
            xs={10}

            mt={5}
          >
            {resOrder?.orderId !== "Loading..." &&
              <OrderStatus resOrder={resOrder} />}
          </Grid>

        </Grid>

      </Grid>

    </div>
  )
} 
