import React, { useEffect, useState } from "react";

import { Card, CardHeader, CardContent, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { textAlign } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Lato,sans-serif",
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: "#ffff",
  },

  card: {
    height: "25rem",
  },

  btnRoot: {
    marginTop: "3rem",
  },

  para: {
    textAlign: "start",
    height: "11rem",
    color: "#666",
  },

  paraLi: {
    lineHeight: "1.5rem",
    listStyleType: "none",
    marginTop: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },

  btn: {
    // background: "#35BFFF",
    color: "white",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    paddingTop: "0.2rem",
    paddingBottom: "0.2rem",
    marginBottom: "1rem",
    marginTop: "1rem",
    textTransform: "none",
    fontSize: "1.3rem",
    // "&:hover": {
    //   background: "#35BFFF",
    // },

    [theme.breakpoints.down("xs")]: {
      padding: "0.5rem",
    },
  },
}));




export function WashDryCard({
  setNumber,
  DryCleannumber,
  Alternationnumber,
  setDryCleannumber,
  setAlternationnumber,
  selfNumber,
  scrollFunc
}) {

  const classes = useStyles();

  const [openWhatyouGet, setOpenWhatyouGet] = useState(false)
  // function pageScroll() {
  //   window.scrollBy(0, 10);
  // }

  const setNum = (num) => {

    setNumber(oldVal=>!oldVal);
    setDryCleannumber(false)
    setAlternationnumber(false)

    setOpenWhatyouGet(oldVal => !oldVal)
    scrollFunc();
  };


  useEffect(() => { 
    if (!selfNumber) { 
      setOpenWhatyouGet(false)
    }
  },[selfNumber])



  return (
    <Card style={{ minHeight: "100%", }}>
      <CardHeader title={`Wash/Dry/Fold`} className={classes.title}
        style={{
          backgroundColor: `${openWhatyouGet ? "#656DFF" : "#35BFFF"}`
        }} />
      <CardContent>

        <Grid container
          alignItems="center"
          justifyContent="center">

          <Grid container item
            lg={12}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item lg={12}>

              <div className={classes.para}>
                <li className={classes.paraLi} >
                  For those who want the Absolute Highest Luxury in laundry.
                </li>

                <li className={classes.paraLi}>
                  {"Didn't know it existed? It does now!"}
                </li>

                <li className={classes.paraLi}>
                  {`When you get your laundry back, it will smell, look, and feel
            AMAZING! You will wonder how you ever lived without us! :-)`}
                </li>
              </div>

            </Grid>
          </Grid>


          <Grid item lg={12} className={classes.btnRoot} >
            <Button
              variant="contained"
              // disabled={DryCleannumber || Alternationnumber ? true : false}
              className={classes.btn}
              style={{backgroundColor: `${openWhatyouGet ? "#656DFF" : "#35BFFF"}`}}
              onClick={() => setNum(1)}
            >
              {openWhatyouGet ? "Hide what you get!" : " See what you get!"}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function DryCleaningCard({
  setNumber,
  WashDrynumber,
  Alternationnumber,
  setWashDrynumber,
  setAlternationnumber,
  selfNumber,
  scrollFunc
}) {
  const classes = useStyles();
  const [openPriceChart, setOpenPriceChart] = useState(false)
  // function pageScroll() {
  //   window.scrollBy(0, 1);
  //   setTimeout(pageScroll, 0.5);
  // }

  const setNum = (num) => {

    setNumber(oldVal=>!oldVal);
    setWashDrynumber(false)
    setAlternationnumber(false)

    setOpenPriceChart(oldVal => !oldVal)
    scrollFunc();
  };


  useEffect(() => { 
    if (!selfNumber) { 
      setOpenPriceChart(false)
    }
  }, [selfNumber])
  

  return (
    <Card style={{ minHeight: "100%", }}>
      <CardHeader title={`Dry Cleaning`} className={classes.title}
        style={{
          backgroundColor: `${openPriceChart ? "#656DFF" : "#35BFFF"}`
        }} />
      <CardContent>

        <Grid container
          alignItems="center"
          justifyContent="center"
        >

          <Grid container>
            <Grid item lg={12}>

              <div className={classes.para}>

                <li className={classes.paraLi} >
                  FINALLY, the same fun, convenient service you use for your laundry
                  woes, offers dry cleaning!</li>

                <li className={classes.paraLi}>
                  We use the BEST cleaning supplies money can buy and ALL of them are
                  eco-friendly.
                </li>

              </div>

            </Grid>
          </Grid>


          <Grid item lg={12} className={classes.btnRoot} >
            <Button
              variant="contained"
              // disabled={WashDrynumber || Alternationnumber ? true : false}
              className={classes.btn}
              style={{backgroundColor: `${openPriceChart ? "#656DFF" : "#35BFFF"}`}}
              onClick={() => setNum(2)}
            >
              {openPriceChart ? "Hide Dry Cleaning Price Chart" : "See Price Chart"}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export function AlterationsCard({
  setNumber,
  WashDrynumber,
  DryCleannumber,
  setDryCleannumber,
  setWashDrynumber,
  selfNumber,
  scrollFunc
}) {

  const [openPriceChart, setOpenPriceChart] = useState(false)

  const classes = useStyles();
  // function pageScroll() {
  //   window.scrollBy(0, 1);
  //   setTimeout(pageScroll, 0.5);
  // }
  const setNum = (num) => {
 
    setNumber(oldVal=>!oldVal);
    setDryCleannumber(false)
    setWashDrynumber(false)

    setOpenPriceChart(oldVal => !oldVal)

    scrollFunc();

  };

  useEffect(() => { 
    if (!selfNumber) { 
      setOpenPriceChart(false)
    }
  },[selfNumber])

  return (
    <Card style={{ minHeight: "100%", }}>
      <CardHeader title={`Alterations`} className={classes.title}
        style={{ 
          backgroundColor: `${openPriceChart ? "#656DFF" : "#35BFFF"}`}} />
      <CardContent>

        <Grid container
          alignItems="center"
          justifyContent="center"
        >

          <Grid container item>
            <Grid item lg={12} >

              <div className={classes.para}>
                <li className={classes.paraLi} >
                  And of course, we {"can’t"} leave our alterations friends behind, so
                  if you just hit puberty number two, or you wanna go for the {"‘"}70s
                  {" “"}these pants {"don’t"} look like they fit me but they really do
                  {"” "}look, we got you covered.</li>

              </div>

            </Grid>
          </Grid>


          <Grid item lg={12} className={classes.btnRoot} >
            <Button
              variant="contained"
              // disabled={WashDrynumber || DryCleannumber ? true : false}
              className={classes.btn}
              style={{backgroundColor: `${openPriceChart ? "#656DFF" : "#35BFFF"}`}}
              onClick={() => setNum(3)}
            >
              {openPriceChart ? "Hide Alterations Price Chart" : "See Price Chart"}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
