import React, { useRef, useState, useEffect } from 'react';
// import '../../utils/styles/Calendar.css';
import PageHeader from 'components/UI/PageHeader';
import { useDispatch } from 'react-redux';
import { useStyles } from './classes';
import { useNavigate } from 'react-router-dom';
import Item from './Item';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import { addTitems } from '../../store/actions/orderAction';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, TableFooter } from "@material-ui/core";

export default function Tailoring(props) {
 
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(null);
  const [list, setList] = useState([]);

  const [chnage, setChange] = useState(false);
  const [buttonDiabled, setButtonDiabled] = useState(false)
  const orderNumber = JSON.parse(sessionStorage.getItem('orderNumber'));

  const handleChange = (prop) => (event) => {

    if (event.target.value === "") {
      setButtonDiabled(true)
    } else {
      setButtonDiabled(false)
    }

    const object = { [prop]: Number(event.target.value) < 1 && event.target.value !== "" ? 1 : Math.floor(Number(event.target.value)) };

    for (const property in object) {
      const filterObject = list.findIndex((item) => item._id === property);
      list[filterObject].itemId.quantity = object[property];
      setList([...list]);
      addItems([...list]);
    }

  };

  const getSession = () => {
    const item = JSON.parse(sessionStorage.getItem('addTitems'));
    if (item === null) {
      setList([]);
    } else {
      let itemArray = [];
      for (let x in item) {
        itemArray.push(item[x]);
      }
      setList(itemArray);
    }
  };

  const addItems = async (data) => {
    await dispatch(addTitems(data));
  };

  const removeList = (id) => {
    const filterValue = list.filter((item) => item.itemId.item.id !== id);
    addItems(filterValue);
    setList(filterValue);
  };

  const Total = (list) => {
    let total = 0;
    for (let x in list) {
      total += list[x].itemId.price.toFixed(2) * list[x].itemId.quantity;
    }
    return total;
  };

  const direct = async () => {
    
    if (props.specialCareRemove === "combind") { 
      await props.setTriggerd(true)
    }
    
    const isEmpty = list.filter(item => item.itemId.quantity === "");
    const getNonEmpty = list.filter(item => item.itemId.quantity !== "");

    if (isEmpty) {
      dispatch(addTitems(getNonEmpty));
      navigate('/pickup');
    } else { 
      navigate('/pickup');
    } 

  };
  useEffect(() => {
    getSession();
  }, [chnage]);
  return (
    <div ref={myRef} style={{ marginBottom: 300 }}>
      {!(
        orderNumber.orderType === '4' ||
        orderNumber.orderType === '9' ||
        orderNumber.orderType === '10'
      ) && <PageHeader tittle={'Choose Your Services'} />}

      <p className={classes.parad}>What type of items do you need tailored?</p>

      <p className={classes.parab}>
        You can search for items like: Suit Jacket, Dress, Pants...
      </p>

      <div className={classes.divItem2}>
        <Item chnage={chnage} list={list} setChange={setChange} />
      </div>

      {list.length !== 0 && (
        <>
          <Grid container justifyContent='center'>
            <TableContainer
              sx={{
                width: 700,
                margin: "1rem",
              }}
              component={Paper}
              align="center" >

              <Table aria-label="simple table">

                <TableHead>
                  <TableCell
                    align="center"
                    colSpan={5}
                    style={{ backgroundColor: '#1891FB' }} >
                    <p className={classes.tTableOrderFlowDcOrder}>
                      Tailoring Order </p>
                  </TableCell>
                </TableHead>
                <TableBody>


                  {list.map((item, i) => (

                    <TableRow key={i}>

                      <TableCell align="center">
                        <FormControl className={classes.tTableOrderFlowQty} >
                          <FilledInput
                            InputProps={{ inputProps: { min: 1} }}
                            key={i}
                            id='filled-adornment-amount' 
                            type='number'
                            value={item.itemId.quantity}
                            onChange={handleChange(`${item._id}`)}
                            size='small' />
                        </FormControl>
                      </TableCell>

                      <TableCell align="left" style={{ textOverflow: "contain", }} >
                        <p className={classes.tTableOrderFlowItemNm} >
                          {' '}
                          {item.itemId.item.name} </p>
                      </TableCell>


                      <TableCell align="center">
                        <p className={classes.tTableOrderFlowPrice} >
                          {item.itemId.price.toFixed(2)} </p>
                      </TableCell>


                      <TableCell align="center">
                        <Button className={classes.tTableOrderFlowRmBtn}
                          variant='contained'
                          color='error'
                          style={{ textTransform: 'capitalize' }}
                          onClick={() => removeList(item.itemId.item.id)} >
                          Remove  </Button>
                      </TableCell>

                    </TableRow>

                  ))}


                </TableBody>

                <TableFooter>

                  <TableCell colspan={5} align="right"
                    className={classes.tDryCleaningSubtotal} >

                    <p style={{ color: '#69a448' }}>
                      Tailoring Subtotal: {Total(list).toFixed(2)}
                    </p>
                  </TableCell>

                </TableFooter>

              </Table>
            </TableContainer>
          </Grid>

          {!buttonDiabled && <div className={classes.divroot2} onClick={async () => await direct()}>
            <div data-testid="continue-scheduling" className={classes.continue3}>
              <div className={classes.box}>
                <Button style={{ color: "white", height: "1rem", fontSize: "0.9rem" }}>Continue To Scheduling </Button>
              </div>
            </div>
          </div>}
        </>
      )}
    </div>
  );
}
