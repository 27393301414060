import React, { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";
import { useStyles } from './classes';
import CardDuplicates from '../../components/MyAccount/myWallet/CardDuplicates';
import OrderCardElement from './OrderCardElement';
import { useNavigate } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch } from "react-redux";
import { paymentCardsList } from "../../store/actions/authActions";
import { addorder } from "../../store/actions/orderAction";

const success = { '&.MuiButton-root': { background: '#2cca5c' } };
const primary = { '&.MuiButton-root': { background: '#0095E4' } };

export default function SavedCards({ setCard, addOrdersWithNewCard }) {
  const dispatch = useDispatch();
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const classes = useStyles();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [defaultCard, setDefaultCard] = useState(null);
  const [open, setOpen] = useState(false);
  const [duplicates, setDuplicates] = useState(null);
  const [color1, setColor1] = useState(primary);
  const [color2, setColor2] = useState(primary);
  const [clicked, setClicked] = useState(false);
  const addorderDetails = JSON.parse(sessionStorage.getItem("addorder"));

  const getPaymentMethods = async () => {
    try {
      const { data } = await axios.get(`/customer/payment-methods`);
      setPaymentMethods(data.cardList);
      setDefaultCard(data.defPayment);
      const card = data.cardList.filter((card) => card.isDefault === true);
      if (card.length > 0) {
        setCard(card[0].last4);
      }
      setDuplicates(data.duplicates);
      const setTrue = data.duplicates.length ? true : false;
      setOpen(setTrue);
      dispatch(paymentCardsList(data));
    } catch (error) {
      // please handle errors
    }
  };

  const updateDefault = async (id) => {
    try {
      await axios.put('/customer/update-payment-method', {
        id,
      });
      getPaymentMethods();
    } catch (error) {
      // please handle errors
    }
  };

  const newCardFunction= async()=>{
    sessionStorage.removeItem("newCardAdded");
    const { data } = await axios.get(`/customer/payment-methods`);
   const card =  data?.cardList[data?.cardList.length - 1];
   await updateDefault(card.id);
   dispatch(addorder({...addorderDetails, card: card.last4}));
    navigate("/confirmOrder");
  }

  useEffect(() => {
    if(sessionStorage.getItem("newCardAdded")){
    return  newCardFunction();
    }
    if (paymentMethods === null || paymentMethods === undefined) {
      getPaymentMethods();
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentMethods?.length === 1 && !defaultCard) {
      updateDefault(paymentMethods[0].id);
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  return (
    <Grid container xs={12} md={12} lg={12}>
      <Grid item xs={12} md={12} lg={12}>
        {paymentMethods?.length > 0 ? (
          <Grid container item spacing={0.8} md={12} lg={12} p={2}>
            {paymentMethods?.map((card) => (
              <Grid item key={card.id} xs={12} md={12} lg={12}>
                <Button
                  id={card.id}
                  variant='contained'
                  sx={card.isDefault && !clicked ? success : color1}
                  fullWidth={true}
                  onClick={(e) => {
                    setClicked(false);
                    updateDefault(e.target.id);
                    setColor2(primary);
                  }}
                >
                  {card.isDefault ? <CheckIcon /> : ''} Use Card Ending in{' '}
                  {card.last4}
                </Button>
              </Grid>
            ))}
            <Grid item xs={12} md={12} lg={12}>
              <Button
                variant='contained'
                sx={color2}
                fullWidth={true}
                onClick={() => {
                  setClicked(true);
                  setColor1(primary);
                  setColor2(success);
                }}
              >
                Pay with a New Card
              </Button>
            </Grid>
            {clicked ? (
              <Grid item xs={12} md={12} lg={12}>
                <p className={classes.checkout__forms_h2}>
                  Payment Information
                </p>
                <OrderCardElement addOrdersWithNewCard={addOrdersWithNewCard}/>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        ) : (
          <>
            <p className={classes.para1}>
              You currently do not have any payment methods added, please
              continue below.
            </p>
            <Grid item xs={12} md={12} lg={12}>
              <p className={classes.checkout__forms_h2}>Payment Information</p>
              <OrderCardElement addOrdersWithNewCard={addOrdersWithNewCard} />
            </Grid>
          </>
        )}
        {duplicates && open && (
          <CardDuplicates
            duplicates={duplicates}
            getPaymentMethods={() => getPaymentMethods()}
            setOpen={setOpen}
            open={open}
          />
        )}
      </Grid>
    </Grid>
  );
}
