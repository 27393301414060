import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  parad: {
    fontSize: '1.3rem',
    lineHeight: '1.37rem',
    color: '#666',
    textAlign: 'center',
    fontWeight: '700',
    marginBottom: '1rem',
    marginTop: '1rem',
    
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      fontSize: '1.2rem',
    },

  },
  parab: {
    fontSize: '1.18rem',
    lineHeight: '1.37rem',
    color: '#67a7c6',
    textAlign: 'center',
    fontWeight: '400',
    marginBottom: '1rem',
    marginTop: '1rem',
    
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      fontSize: '1rem',

    },

  },
  divroot2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '1rem',
    },
  },
  continue3: {
    backgroundColor: '#3ab961',
    width: '30%',
    height: '4rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '1.2rem',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#58A846',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  box: {
    display: 'flex',
    alignItems: 'center',
  },
  flexBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  pitem: {
    fontSize: '1rem',
    lineHeight: '1.37rem',
    color: '#777777',
    fontWeight: '400',
  },
  divItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pitem1: {
    paddingRight: '1rem',
    fontSize: '1rem',
    lineHeight: '1.37rem',
    color: '#777777',
    fontWeight: '400',
  },
  divItem2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding:"1rem",
  },
  divItem3: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  divItem4: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  divItem5: {
    backgroundColor: '#1891FB',
    height: '2.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    boxShadow: '1px 1px #B2BEB5',
  },
  divItem6: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: '0 0 3px #B2BEB5',
  },
  divItem7: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.6rem',
  },
  divItem8: {
    display: 'flex',
    justifyContent: 'space-bewteen',
    alignItems: 'center',
  },

//orderFlow drop downtable text sizing on XS

tTableOrderFlowDcOrder:{
  textAlign: "center", 
  color: "white", 
  fontSize: "1rem", 
  lineHeight: "1.37rem", 
  fontWeight: "700",
},

tTableOrderFlowQty:{
  width: "4rem", 
  padding: 0, 
  height: "2.5rem",
  [theme.breakpoints.down("xs")]: {
  width: "2.5rem", 
  padding: 0, 
  height: "2.5rem",
    },
},

tTableOrderFlowItemNm:{
    lineHeight: "1.37rem",
    color: "#888888",
    fontWeight: "400",
    minWidth: "4rem",
  },
tTableOrderFlowPrice:{
    lineHeight: "1.37rem",
    color: "#69a448",
    fontWeight: "400",
  },
tTableOrderFlowRmBtn:{
    [theme.breakpoints.down("xs")]: {
      width:"1rem",
        },
  },
  tDryCleaningSubtotal:{
    borderTop:"0.2rem",
    borderLeft:"0rem",
    borderRight:"0rem",
    borderBottom:"0rem",
    borderColor: "#E0E0E0",
    borderStyle: "solid",
  },

}));
