import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import useAxiosPrivate from "utils/hooks/useAxiosPrivate";

export default function CardDuplicates({
  open,
  setOpen,
  getPaymentMethods,
  duplicates,
}) {
  const axios = useAxiosPrivate();
  const handleClose = () => {
    removeCard();
    setOpen(!open);
  };

  const handleYes = () => {
    updateCard();
    setOpen(!open);
  };

  const removeCard = async () => {
    try {
      const lastCard = duplicates[duplicates.length - 1].id;
      detachCard(lastCard);
    } catch (error) {
      // please handle errors
    }
  };

  const updateCard = async () => {
    try {
      for (let i = 0; i < duplicates.length; i++) {
        const card = duplicates[i];
        if (i !== duplicates.length - 1) {
          detachCard(card.id);
        }
      }
    } catch (error) {
      // please handle errors
    }
  };

  const detachCard = async (paymentMethodId) => {
    try {
      await axios.post('/customer/detach-payment-method', {
        id: paymentMethodId,
      });
      getPaymentMethods();
    } catch (error) {}
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {'Duplicate credit cards'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            { duplicates[0]?.isDefault ? 
            `Credit card ending in ${duplicates[0]?.last4} is already set as the default card in your wallet.`
            :
            `Credit card ending in ${duplicates[0]?.last4} is already in your wallet. Do you want to remove or update this card?` }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {
          duplicates[0]?.isDefault ?
          <Button onClick={handleClose} color='success' variant='outlined'>
          Close
        </Button>
          :
          <>
          <Button onClick={handleClose} color='success' variant='outlined'>
            Remove
          </Button>
          <Button
            onClick={handleYes}
            autoFocus
            color='error'
            variant='outlined'
          >
            Update
          </Button>
          </>
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}
