import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import DialogAlertMain from "components/common/DialogAlertMain";
import CouponsList from "./redeemedCoupons";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRewards, setPromo } from "../../../store/actions/orderAction";

export default function Upcoming({ orderType, zipcode, isListEmpty, isWashDryFoldServiceSelected, isSpecialCareItemsEmpty, isWashDataLoading }) {

  const location = useLocation();
  const axios = useAxiosPrivate();
  let dispatch = useDispatch();

  const [listRewards, setReward] = React.useState([]);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  //promocode Check
  const [promoData, setPromodata] = useState();
  const [promoUsed, setPromoUsed] = useState(false);

  const getData = async (type) => {
    try {
      const { data } = await axios.get(`/customer/redeemed-coupons/${type}`);
      setReward(data);
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: "Something went wrong",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "Something went wrong",
          message: "",
        });
      }, 3000);
    }
  };

  const getPromoCode = async (promocode) => {
    try {
      const promoData = {
        promocode,
        zipcode,
      };
      const { data } = await axios.post(
        `/customer/promo-code/${orderType}`,
        promoData
      );

      //pass data to frountend
      setPromodata(data);
      dispatch(setPromo(data));
    } catch (err) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: err.response.data.message==="Promocode not Found!" ? "Promo Code not Found!" : err.response.data.message,
      });
      setPromoUsed(true)
      if (err.response.status === 404) {
        setPromodata("");
        dispatch(setRewards({ promoVal: "", couponVal: "" }));
      }

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "",
          message: "",
        });
      }, 3000);
    }
  };

  useEffect(() => {
    getData(orderType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <CouponsList
            isListEmpty={isListEmpty}
            promoData={promoData}
            rewards={listRewards}
            getPromoCode={getPromoCode}
            isWashDryFoldServiceSelected={isWashDryFoldServiceSelected}
            isSpecialCareItemsEmpty={isSpecialCareItemsEmpty}
            setAlert={setAlert}
            isWashDataLoading={isWashDataLoading}
            setPromoUsed={setPromoUsed}
            promoUsed={promoUsed}
          />
        </Grid>
      </Grid>
      {alert.showAlert && (
        <DialogAlertMain setAlert={setAlert} alert={alert} />
      )}
    </div>
  );
}
