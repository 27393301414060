import React, { useState, useRef, useEffect, useMemo } from 'react';
import Calendar from 'react-calendar';
// import '../../utils/styles/Calendar.css';
import PageHeader from 'components/UI/PageHeader';
import Progressbar from '../../components/UI/ProgressBar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import { delivery } from '../../store/actions/orderAction';
import { useStyles } from '../order/classes';
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useNavigate } from 'react-router-dom';
import { Grid } from "@material-ui/core";

const isSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

export default function Pickup() {
  const axios = useAxiosPrivate();
  let zipCode = useSelector((state) => state.auth.defaultZipCode);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef(null);
  const isMounted = useRef(false);
  const [value, onChange] = useState('');

  const TimeGapCheckerBench = `${process.env.REACT_APP_TIME_GAP_CHECKER}`
  
  const [selectedValue, setSelectedValue] = useState('');
  const [message, setMessage] = useState("Data is loading...");
  const [getData, setGetData] = useState([]);
  const [fData, setFData] = useState([]);
  const orderNumber = JSON.parse(sessionStorage.getItem('orderNumber'));
  const [scheduleId, setScheduleId] = useState(null);
  const [timeSlotId, setTimeSlotId] = useState(null);
  const pickUpDetails = JSON.parse(sessionStorage.getItem("pickup"));

  //value splitter
  const ValueSplitter = (val, splitString, location) => {
    return val.split(splitString)[location]
  }

  //date string assemble and convert to milliseconds
  const dateToString = (date, val, splitString, location) => {
    if (date && val && splitString && location) {
      return Date.parse(new Date(`${date} ${val.split(splitString)[location]}`));
    } else if(!splitString && !location){ 
      return Date.parse(new Date(`${date} ${val}`));
    }
  }

  //next day delivery time slot logic
  const compareDates = useMemo(() => {
  const d1 = pickUpDetails.pickupDate
  const d2 = value 
    //d1 is pickup date from session storage
    //d2 is delivery date: it's getting onChange value
    if (d1 && d2) {
      //convert dates to milliseconds
      let date1 = new Date(d1)?.getTime();
      let date2 = d2?.getTime();
      if (date1 === (date2 - 86400000)) {
        // is pickup date is one days apart from delivery date

        if (fData[0]?.availableTimes[0]?.timeSlot) {
          const checkTime = TimeGapCheckerBench;
          //convert pickup date + selected start pickup Time to milliseconds
          const startPickupDateMilisecond = dateToString(d1, pickUpDetails?.pickupTimeSlot, "-", 0);
          //convert pickup date + selected end pickup Time to milliseconds
          const endPickupDateMilisecond = dateToString(d1, pickUpDetails?.pickupTimeSlot, "-", 1);
          //convert pickup date + checking value to milliseconds
          const pickupCheckingMilisecond = dateToString(d1, checkTime);
          //filter Time slots
          const filterTimeArray = fData[0]?.availableTimes.filter(item => {
            // (pickup start TIme is later than or equal) OR (end time is later than) to checking time
            if (startPickupDateMilisecond >= pickupCheckingMilisecond || endPickupDateMilisecond > pickupCheckingMilisecond) {
              //Format Delivery Date to String value (eg: Fri Feb 17 2023 00:00:00 GMT+0530 to Friday, February 17, 2023)
              const datetoString = `${ValueSplitter(d2.toString(), " ", 0)}, ${ValueSplitter(d2.toString(), " ", 1)},  ${ValueSplitter(d2.toString(), " ", 2)}, ${ValueSplitter(d2.toString(), " ", 3)}`
              //OUTPUTS
              //Convert Delivery Date + Delivery END Time slot to Milliseconds
              const endDateTimeDeliveryMilisecond = dateToString(datetoString, item.timeSlot, "-", 1);
              //Convert Delivery Date + Checking TIme to Milliseconds
              const checkingDeliveryMilisecond = dateToString(datetoString, checkTime);
              return (endDateTimeDeliveryMilisecond > checkingDeliveryMilisecond)
            }// end TIme is later than to 5
            else {
              return fData[0]?.availableTimes
            }
          })
          return filterTimeArray
        } else {
          return fData[0]?.availableTimes
        }
      } else {
        return fData[0]?.availableTimes
      }
    } else {
      return fData[0]?.availableTimes
    }

  }, [fData, pickUpDetails, value, TimeGapCheckerBench]);

  const disabledDates = useMemo(() => {
    let dates = [];
    if (getData.length !== 0) {
      dates = getData?.disabledDates.map((item) => {
        const date = item;
        const newDate = date.slice(0, -5)
          return (
            new Date(newDate)
          )
      });
    }
    return dates;
  }, [getData]);

  const executeScroll = () => myRef.current.scrollIntoView();
  // const pickup = JSON.parse(sessionStorage.getItem('pickup'));
  const pickup = useMemo(() => {
    return JSON.parse(sessionStorage.getItem('pickup'));
  }, []);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setTimeSlotId(event.target.id);
  };

  const controlProps = (item, index) => ({
    checked: selectedValue === item, onChange: handleChange, value: item, id: index,
    name: 'size-radio-button-demo', inputProps: { 'aria-label': item },
  });

  const adddelivery = async (data) => {
    await dispatch(delivery(data));
    if (orderNumber.orderType === '7' || orderNumber.orderType === '9' || orderNumber.orderType === '5') { navigate('/specialCare'); }
    else { navigate('/checkout'); }
  };

  const loadData = async () => {
    try {
      const { data } = await axios.post(
        `/datepicker/delivery/${zipCode}`,
      {attended : orderNumber?.attended});
      setGetData(data);
    } catch (err) {
      setGetData([]);
      setMessage('Schedule not available, please check again later');
    }
  };

  const tileDisabled = ({ date, view }) => {
    // Disable tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of disabled dates

        // return disabledDates?.find((dDate) => isSameDay(dDate, date));
        let new_array = [];
        // getData?.availableDates?.forEach(element => {
        //   if (element?.availableTimes?.every(item => item.limit === 0)) { new_array.push(element.date); }
        // });
        new_array.push(...disabledDates);
        new_array = [...new Set(new_array)];

        return new_array?.find((dDate) => isSameDay(new Date(dDate), new Date(date)));
    }
  }
  const getFilteredData = (data) => {
    let filteredData = getData?.availableDates?.filter((item) => {
      const date = item.date;
      const newDate = date.slice(0, -5);
      return (
        isSameDay(new Date(newDate), data)
      )
    });
    setScheduleId(filteredData[0]?.id);
    setFData(filteredData);
  };

  useEffect(() => {
    executeScroll();
    loadData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isMounted.current) { getFilteredData(value); }
    else { isMounted.current = true; }
    //eslint-disable-next-line
  }, [value]);

  if (getData.length === 0) {
    return (
      <div ref={myRef} style={{ marginBottom: 50 }}>
        {(orderNumber.orderType === '1' || orderNumber.orderType === '5' || orderNumber.orderType === '7' || orderNumber.orderType === '9') && (
          <PageHeader tittle={'Choose Your Wash/dry/fold Delivery Date and Time'} />)}
        {(orderNumber.orderType === '2') && (<PageHeader tittle={'Choose Your Dry Cleaning Delivery Date and Time'} />)}
        {(orderNumber.orderType === '3') && (<PageHeader tittle={'Choose Your Tailoring & Alterations Delivery Date and Time'} />)}
        {(orderNumber.orderType === '4') && (<PageHeader tittle={'Choose Your Special Care  Delivery Date and Time'} />)}
        {(orderNumber.orderType === '10' || orderNumber.orderType === '8' || orderNumber.orderType === '6') &&
          (<PageHeader tittle={'Choose Your Delivery Date and Time'} />)}
        <Grid container>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item lg={9} md={11} sm={12} xs={12} >
              <Progressbar bgcolor="#35D8F2" progress="35" text={"DELIVERY"} height={30} />
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.textbox}> <p style={{ wordBreak: "break-all", }} className={classes.text}>{message}</p> </div>
      </div>);
  }
  return (
    <div ref={myRef} style={{ marginBottom: 150 }}>
      {(orderNumber.orderType === '1' || orderNumber.orderType === '5' || orderNumber.orderType === '7' || orderNumber.orderType === '9') && (
        <PageHeader tittle={'Choose Your Wash/dry/fold Delivery Date and Time'} />)}
      {(orderNumber.orderType === '2') && (<PageHeader tittle={'Choose Your Dry Cleaning Delivery Date and Time'} />)}
      {(orderNumber.orderType === '3') && (<PageHeader tittle={'Choose Your Tailoring & Alterations Delivery Date and Time'} />)}
      {(orderNumber.orderType === '4') && (<PageHeader tittle={'Choose Your Special Care  Delivery Date and Time'} />)}
      {(orderNumber.orderType === '10' || orderNumber.orderType === '8' || orderNumber.orderType === '6')
        && (<PageHeader tittle={'Choose Your Delivery Date and Time'} />)}
      <Grid container>
        <Grid container item alignItems="center" justifyContent="center">
          <Grid item lg={9} md={11} sm={12} xs={12} >
            <Progressbar bgcolor="#35D8F2" progress="35" text={"DELIVERY"} height={30} />
          </Grid>
        </Grid>
      </Grid>

      {!orderNumber?.attended && (
        <Grid container>
          <Grid container item alignItems="center" justifyContent="center">
            <Grid item lg={6} md={6} sm={9} xs={11}>
              <div style={{ padding: "1.3rem", backgroundColor: "#FFF9D0", }} >
                <p style={{ color: '#987a2f' }}> Unattended deliveries will be made from a time period of &nbsp; {getData.unAttendedTime}. </p>
              </div>
            </Grid>
          </Grid>
        </Grid>)}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {' '}
        <Calendar onChange={onChange} value={value} calendarType={'US'}
          showNeighboringMonth={false}
          minDate={
            orderNumber.orderType === '4'
              ? new Date(new Date(pickup?.pickupDate?.toLocaleString('en-US', { timeZone: `${getData?.timeZone}` })).getTime() +
                (getData?.specialCareDays) * 86400000
              ) : orderNumber.orderType === '10' ||
                orderNumber.orderType === '8' ||
                orderNumber.orderType === '2' ||
                orderNumber.orderType === '3' ||
                orderNumber.orderType === '6'
                ? new Date(new Date(pickup?.pickupDate?.toLocaleString('en-US', { timeZone: `${getData?.timeZone}` })).getTime() +
                  ((getData?.specialCareDays > getData?.washDryDays) ? (getData?.specialCareDays) : (getData?.washDryDays)) * 86400000
                ) : new Date(new Date(pickup?.pickupDate?.toLocaleString('en-US', { timeZone: `${getData?.timeZone}` })).getTime() + (getData?.washDryDays) * 86400000)}
          maxDate={new Date(new Date(getData?.endDate).toUTCString().slice(0, -4))} tileDisabled={tileDisabled} />
      </div>
      {JSON.stringify()}
      {value !== '' && fData?.length !== 0 && (
        <>
          <div className={classes.flex2}>
            <div className={classes.box2}>
              <p style={{ padding: '1rem' }} > {`Select a delivery time for  ${value.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', })}`}</p>
            </div>
          </div>
          {orderNumber?.attended ? (
            <>
              <div className={classes.flex2}>
                <div className={classes.box3}>
                  <RadioGroup column name='position' defaultValue='top' >
                    {compareDates?.length > 0 ? (
                      compareDates.map((item) => (
                        <React.Fragment key={item?.id}>
                          <FormControlLabel 
                            style={{ border: '1px solid #75a8dc', margin: 2 }}
                            control={
                              <Radio 
                                {...controlProps(item?.timeSlot, item?.id)}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#00A3FF' } }}
                              />
                            }
                            label={
                              <span className={classes.spantext2}>
                                {'Between'}
                                <span className={classes.spantext3}>
                                  {' '}{item?.timeSlot}
                                </span>
                              </span>
                            } 
                          />
                        </React.Fragment>
                      ))
                    ) : (
                      <div style={{ color: 'red', fontWeight: 'bold' }}>
  Sorry, there are no timeslots available for this date due to the late pickup.
</div>

                    )}
                </RadioGroup>

                </div>
              </div>
            </>
          ) : (
            <Grid container>
              <Grid container item alignItems="center" justifyContent="center">
                <Grid item lg={6} md={6} sm={9} xs={11}>
                  <div style={{ marginTop: 10 }}>
                    <h3 style={{ marginTop: '1rem', fontSize: '1.18rem', lineHeight: '1.37rem', color: '#222', fontWeight: '700', }} >
                      {`You have selected ${value?.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', })}`}
                    </h3>
                    <p style={{ marginTop: '1rem', fontSize: '1rem', lineHeight: '1.37rem', color: '#222', fontWeight: '400', }} >
                      Unattended deliveries will be made from a time period of &nbsp;{getData?.unAttendedTime}. </p>
                  </div>

                  <div className={classes.divroot2}
                  data-testid="continue-btn"
                    onClick={() => adddelivery({
                      diliverDate: value.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', }),
                      diliverTimeSlot: getData?.unAttendedTime,
                      deliverySchedule: { id: scheduleId, timeSlotId: timeSlotId, attended: orderNumber?.attended, },
                    })} >
                    <div className={classes.continue2}> <div className={classes.box}> <p>Continue</p> </div> </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {selectedValue !== '' && (
            <div  data-testid="continue1-btn" 
            className={classes.divroot2}
              onClick={() => adddelivery({
                diliverDate: value.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', }),
                diliverTimeSlot: selectedValue,
                deliverySchedule: { id: scheduleId, timeSlotId: timeSlotId, attended: orderNumber?.attended, },
              })} >
              <div className={classes.continue2}> <div className={classes.box}> <p style={{ cursor: 'pointer' }} data-testid="continue-btn">Continue</p> </div> </div>
            </div>)}
        </>)}
    </div>);
}
