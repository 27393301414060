import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";

import PinDropIcon from '@mui/icons-material/PinDrop';
import OrderStatus from "components/StatusTracker/OrderStatus";

const useStyles = makeStyles((theme) => ({
  container: { color: "#aaa", backgroundColor: "#efefef", padding: "2rem 1rem", fontSize: "1.2rem", borderRadius: "0.5rem", },
  time: { color: "#1C4764", fontWeight: "700", fontSize: "1rem", textAlign: "left", },
  calanderDay: { color: "#1C4764", fontWeight: "700", fontSize: "1rem", textAlign: "left",
    [theme.breakpoints.down(960)]: { textAlign: "right", }, },
  to: { color: "#999", padding: "0.5rem", fontSize: "0.05rem",
    [theme.breakpoints.down(960)]: { textAlign: "right", justifyContent: "flex-end", }, },
  seeService: { color: "#317A66", cursor: "pointer", },
  items: {
    backgroundColor: "#efefef", padding: "0.3rem 0.5rem", fontSize: "0.9rem",
    borderRadius: "0.2rem", color: "#777", marginTop: "0.5rem", textAlign: "left" },
  showOnXs: { color: "#1C4764", fontWeight: "700", fontSize: "1rem", textAlign: "right",
    [theme.breakpoints.up(451)]: { display: "none", }, },
  showOnsmUp: { color: "#1C4764", fontSize: "1rem", textAlign: "left",
    [theme.breakpoints.down(451)]: { display: "none", }, },
  statusTracker: { backgroundColor: "white", padding: "0.5rem", marginTop: "0.5rem", },
  viewStatus: { width: "100%", fontSize: "0.8rem", },
}));

export default function Delivery({ delivery, normal, loadingData, isTrackOrderBtnClicked}) {
// converter integer date to string date
     function formatDate(string) {
      let diliverDate = string
       if (diliverDate?.includes("Z")) { diliverDate = (diliverDate?.split("T")[0]) }
       else { diliverDate = string }
          return `${moment(diliverDate).format("MMMM Do dddd YYYY")}`;
    }

  const classes = useStyles();

  const [resOrder, setresOrder] = useState({
    orderId: "Loading...", shippedPlace: "Loading...",
    orderStatus: "Loading...", dateLabel: "Loading...", date: "Loading...", specialDate: "Loading...",
  })

  const [Statusopen, setStatusOpen] = useState([]);
  const [filterDelivery, setFilterDelivery] = useState([]);

  const cngStatusopen = async (index, item) => {

    await isTrackOrderBtnClicked();
    
    setresOrder({
      orderId: item.orderId, shippedPlace: item.franchiseeId.fullName,
      statusTracker: item.statusTracker, specialCareTracker: item.specialCareTracker, pickupDate: item.pickupDate,
      diliverDate: item.diliverDate, specialDate: item.specialDate,
    })
    if (Statusopen.includes(index)) { setStatusOpen(Statusopen.filter((item) => item !== index)); }
    else { setStatusOpen([...Statusopen, index]); }
  }

  const [openSet, setOpenSet] = useState([]);
  const handleOpen = (index) => {
    if (openSet.includes(index)) { setOpenSet(openSet.filter((item) => item !== index)); }
    else { setOpenSet([...openSet, index]); }
  };


  useEffect(() => {
    if(delivery?.length > 0){
   const filterDeliveryData = delivery.filter(itm => (
      itm?.orderType === "1" ||
      itm?.orderType === "2" ||
      itm?.orderType === "3" || 
      itm?.orderType === "5" || 
      itm?.orderType === "6" || 
      itm?.orderType === "7" || 
      itm?.orderType === "8" ||
      itm?.orderType === "10" ||
      itm?.orderType === "4" 
      ) && (itm?.statusTracker === "setToDeliver" || itm?.statusTracker === "deliveryCancel" || itm?.statusTracker === "pickup" ||
        itm?.statusTracker === "processing" || itm?.statusTracker === "placingOrder" || itm?.statusTracker === "setToPickup"))
        setFilterDelivery(filterDeliveryData)
      }
  },[delivery])

  return (
    <div>
      {filterDelivery?.length > 0 ? (
        filterDelivery?.map((item, i) => {

          return (
            <Card key={i} sx={{ marginTop: "1rem", borderWidth: "1px", borderColor: "#ddd", borderStyle: "solid" }}>
              {/* Start CardContent for larger screens */}
              <CardContent className={classes.showOnsmUp} style={{ paddingBottom: "1rem" }}>
                <Grid container spacing={1} xs={12} md={12} lg={12}>
                  <Grid container item xs={6} sm={6} md={4} lg={3}
                    className={classes.calanderDay} alignItems="center" >
                    <Grid item lg={2} md={2} sm="auto" xs={5}>
                      <CalendarMonthIcon />
                    </Grid>
                    <Grid item lg={10} md={10} sm="auto" xs={7}>
                      <span>{formatDate(item?.diliverDate)}</span>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} sm={6} md={4} lg={3}
                    justifyContent="flex-start" alignItems="center"
                    className={classes.time} >
                    <Grid item lg="auto" md="auto" sm="auto" >
                      <AccessTimeIcon />
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} > <span>{item?.diliverTimeSlot}</span> </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}> <Divider /> </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" alignContent="flex-start" className={classes.to} spacing={2} >
                  {/* <Grid item justifyContent="flex-start" xs={6} sm={6} md="auto" lg="auto">
                    <Typography style={{ fontSize: "0.80rem" }}>To : {item?.orderAddress}</Typography>
                  </Grid> */}
                  <Grid item container justifyContent="flex-start" xs={12} sm={12} md={3} lg={2}
                    className={classes.seeService} >
                    <LocalOfferIcon style={{ fontSize: "0.95rem" }} />
                    <Typography style={{ paddingLeft: "0.1rem", fontSize: "0.80rem" }} onClick={() => handleOpen(i)} >
                    Order Details
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-start" alignContent="flex-start" style={{marginTop:"1rem"}}>
                {openSet.includes(i) && <h5>Services</h5>}
                  {(item?.servicePrice - item?.specialCarePrice) > 0
                    && openSet.includes(i) && (
                    <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} >
                      <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}> (1) Wash / Dry / Fold </span>{" "}
                      - Wash/Dry/Fold
                    </Grid> )}
                  {item?.dryItem.length > 0
                    && openSet.includes(i) && item?.dryItem.map((item, index) => {
                      return (
                        <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} mt={1} className={classes.items}>
                          <span style={{ fontWeight: "bold" }}> ({index + 1}) {item?.itemId?.item?.name} </span>
                          - Dry Cleaning
                        </Grid> );
                    })}
                  {item?.tailItem.length > 0 && openSet.includes(i) && item?.tailItem.map((item, index) => {
                      return ( <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} mt={1} >
                          <span style={{ fontWeight: "bold" }}> ({index + 1}) {item?.itemId?.item?.name} </span>{" "}
                          - Tailoring
                        </Grid>);
                    })}
                </Grid>

                {(item?.orderAddress || item?.orderNote || item?.pickupNote || item?.deliveryNote) && <Grid container item justifyContent="flex-start" alignContent="flex-start" style={{ marginTop: openSet.includes(i) ? "2rem" : 0 }}>
                {openSet.includes(i) && <h5>Order Details</h5>}
                {item?.orderAddress && openSet.includes(i) && (
                  <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} mt={1} >
                    <span style={{ fontWeight: "bold" }}> Address </span>{" "}
                    : {item?.orderAddress} </Grid>)}

                {item?.orderNote && openSet.includes(i) &&
                  (<Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} mt={1} >
                    <span style={{ fontWeight: "bold" }}> Laundry instructions </span>{" "}
                    : {item?.orderNote} </Grid>)}

                {item?.pickupNote && openSet.includes(i) && (<Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} mt={1} >
                  <span style={{ fontWeight: "bold" }}> Pickup instructions </span>{" "}
                  : {item?.pickupNote} </Grid>)}

                {item?.deliveryNote && openSet.includes(i) && (
                  <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.items} mt={1} >
                    <span style={{ fontWeight: "bold" }}> Delivery instructions </span>{" "}
                    : {item?.deliveryNote} </Grid>)}

              </Grid>}

                <Grid container justifyContent="center" alignContent="center" style={{ marginTop: "2rem" }} >
                  <Grid item>
                    <Button style={{ borderRadius: "0.5rem", borderStyle: "solid", borderWidth: "0.2rem", borderColor: "#F0F0F0" }}
                      className={classes.viewStatus} onClick={() => cngStatusopen(i, item)} endIcon={<PinDropIcon />}>
                      Track Order </Button>
                  </Grid>
                  <Grid container item justifyContent="flex-start" alignContent="flex-start" >
                    {Statusopen.includes(i) && <Grid item justifyContent="flex-start" lg={12} md={12} sm={12} xs={12}
                        className={classes.statusTracker} > <OrderStatus resOrder={resOrder} /> </Grid>}
                  </Grid>
                </Grid>
              </CardContent>
              {/* END CardContent for larger screens */}
              {/* START CardContent for XS screens */}
              <CardContent className={classes.showOnXs}>
                <Grid container spacing={1} xs={12}>
                  <Grid container item xs={6} className={classes.date} alignItems="center" >
                    {/* <Grid item xs={2} md={2} lg={2}> */}
                    <Grid item xs={2} >
                      <CalendarMonthIcon />
                    </Grid>
                    <Grid item xs={10} style={{ textAlign: "center", }} >
                      <span>{formatDate(item?.diliverDate)}</span>
                    </Grid>
                  </Grid>
                  <Grid container item xs={6} justifyContent="flex-start" className={classes.date} alignItems="center" >
                    <Grid item xs={2} > {/* <Grid item xs={2} md={2} lg={2}> */}
                      <AccessTimeIcon />
                    </Grid>
                    <Grid container item xs={10} style={{ textAlign: "center", }} > <span>{item?.diliverTimeSlot}</span> </Grid>
                  </Grid>
                  <Grid item xs={12}> <Divider /> </Grid> </Grid>
                <Grid container justifyContent="flex-start" alignContent="flex-start" className={classes.from} >
                  {/* <Grid item justifyContent="flex-start" xs={6} md={6} lg={6}>
                    <Typography style={{ textAlign: "left", fontSize: "0.80rem", color: "#999", }} >
                      To : {item?.orderAddress}</Typography>
                  </Grid> */}
                  <Grid item container justifyContent="flex-start" xs={12} className={classes.seeService} >
                    <LocalOfferIcon style={{ fontSize: "0.95rem" }} />
                    <Typography style={{ paddingLeft: "0.1rem", fontSize: "0.80rem" }} onClick={() => handleOpen(i)} >
                    Order Details
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container justifyContent="flex-start" alignContent="flex-start" style={{marginTop:"1rem"}}>
                {openSet.includes(i) && <h5>Services</h5>}
                  {(item?.servicePrice - item?.specialCarePrice) > 0
                    && openSet.includes(i) && (<Grid item justifyContent="flex-start" xs={12} className={classes.items} >
                      <span style={{ fontWeight: "bold" }}> (1) Wash / Dry / Fold </span>{" "}
                      - Wash/Dry/Fold </Grid> )}
                  {item?.dryItem.length > 0 && openSet.includes(i) &&
                    item?.dryItem.map((item, index) => {
                      return (
                        <Grid item justifyContent="flex-start" xs={12} className={classes.items} >
                          <span style={{ fontWeight: "bold" }}> ({index + 1}) {item?.itemId?.item?.name} </span>
                          - Dry Cleaning
                        </Grid> ); })}
                  {item?.tailItem.length > 0 && openSet.includes(i) &&
                    item?.tailItem.map((item, index) => {
                      return (
                        <Grid item justifyContent="flex-start" xs={12} className={classes.items} mt={1} >
                          <span style={{ fontWeight: "bold" }}> ({index + 1}) {item?.itemId?.item?.name} </span>{" "}
                          - Tailoring
                        </Grid> ); })}
                </Grid>

                {(item?.orderAddress || item?.orderNote || item?.pickupNote || item?.deliveryNote) &&
                <Grid container justifyContent="flex-start" alignContent="flex-start" style={{ marginTop: "1rem" }}>
                  {openSet.includes(i) && <h5>Order Details</h5>}
                  {item?.orderAddress && openSet.includes(i) && (
                    <Grid item justifyContent="flex-start" xs={12} className={classes.items} >
                      <span style={{ fontWeight: "bold" }}> Address </span>{" "}
                      <span style={{ fontWeight: "400" }}> : {item?.orderAddress} </span>
                    </Grid>)}
                  {item?.orderNote && openSet.includes(i) &&
                    (<Grid item justifyContent="flex-start" xs={12} className={classes.items} >
                      <span style={{ fontWeight: "bold" }}> Laundry instructions </span>
                      <span style={{ fontWeight: "400" }}> : {item?.orderNote} </span> </Grid>)}

                  {item?.pickupNote && openSet.includes(i) && (
                    <Grid item justifyContent="flex-start" xs={12} className={classes.items} >
                      <span style={{ fontWeight: "bold" }}> Pickup instructions </span>{" "}
                      <span style={{ fontWeight: "400" }}>: {item?.pickupNote}</span> </Grid>)}
                  
                      {item?.deliveryNote && openSet.includes(i) && (
                    <Grid item justifyContent="flex-start" xs={12} className={classes.items} mt={1} >
                      <span style={{ fontWeight: "bold" }}> Delivery instructions </span>{" "}
                      <span style={{ fontWeight: "400" }}>: {item?.deliveryNote}</span> </Grid>)}
              
                </Grid>}

                <Grid container justifyContent="center" alignContent="center" style={{ marginTop: "2rem" }} >
                  <Grid item>
                    <Button style={{ borderRadius: "0.5rem", borderStyle: "solid", borderWidth: "0.2rem", borderColor: "#F0F0F0" }}
                      className={classes.viewStatus} onClick={() => cngStatusopen(i, item)} endIcon={<PinDropIcon />}>
                      Track Order </Button>
                  </Grid>
                  <Grid container item justifyContent="flex-start" alignContent="flex-start" >
                    {Statusopen.includes(i) && <Grid item justifyContent="flex-start" xs={12} md={12} lg={12} className={classes.statusTracker} >
                        <OrderStatus resOrder={resOrder} /> </Grid> }
                  </Grid>
                </Grid>
              </CardContent>
              {/* END CardContent for XS screens */}
            </Card> ); })
      ) : (
        <Grid item container xs={12} md={12} lg={12} mt={5} justifyContent="center" p={5} className={classes.container} >
            {!loadingData ? <Typography variant="h6"> No upcoming deliveries scheduled. </Typography>
              : <CircularProgress sx={{ color: "#cfcccc" }} /> }
        </Grid> )}
    </div>
  );
}
