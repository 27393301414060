import React from "react";
import { Card, CardHeader, CardContent, Grid, Button, Typography, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ProductCategoryCard from "./ProductCategoryCard";
import WashDryFoldCard from "./WashDryFoldCard";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fff",
    width: "100%",
    textAlign: "center",
    paddingTop: "2rem",
    paddingBottom: "4rem",
    paddingLeft: "1rem",
    // paddingRight: "1rem",

    marginLeft: "16rem",
    marginRight: "16rem",
    marginBottom: "5rem",

    [theme.breakpoints.down(1684)]: {
      marginLeft: "10rem",
      marginRight: "10rem",
      paddingRight: "1rem",
    },

    [theme.breakpoints.down(1433)]: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },

    [theme.breakpoints.down(960)]: {
      marginLeft: "5rem",
      marginRight: "5rem",
    },

    [theme.breakpoints.down(760)]: {
      marginLeft: "4rem",
      marginRight: "4rem",
    },

    [theme.breakpoints.down(600)]: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
  },

  // bodyContents: {
  //   paddingLeft: "1rem",
  //   paddingRight: "1rem",
  //   [theme.breakpoints.down("sm")]: {
  //     paddingLeft: "0rem",
  //     paddingRight: "0rem",
  //     fontSize: "0.9rem",

  //   },
  // },
  mainTitile: {
    color: "#656DFF",
    // textShadow: "0px 1.3px 1px #000",
    fontSize: "2rem",
    textTransform: "uppercase",
    fontWeight: "800",
    letterSpacing: "0.15rem",

    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },

  heading: {
    textAlign: "center",
    textTransform: "uppercase",
    color: "#55BBB8",
    fontSize: "1.17rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },

  subHeading: {
    color: "#1AB286",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "1rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },

  body: {
    textAlign: "left",
    color: "#666",
    margin: "1rem",
    fontSize: "1rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0rem",
      paddingRight: "0rem",
      fontSize: "0.91rem",
      lineHeight: "1.1rem",
      marginLeft: "0.1rem",

    },
  },
  note: {
    textAlign: "left",
    color: "#666",
  },


}));

export function WashDryCardDisplay({ washDry }) {
  const classes = useStyles();

  const itemList = [
    {
      id: 1,
      name: 'No softener used',
    },
    {
      id: 2,
      name: 'Hot water wash please',
    },
    {
      id: 3,
      name: 'Dry clothes on low heat',
    },
    {
      id: 4,
      name: 'Use detergent I send out please',
    },
    {
      id: 5,
      name: 'And etc',
    },
  ]

  return (
    <Card className={classes.root} style={{
      border: "0.2rem dashed #656DFF", borderRadius: "0.8rem",
      boxShadow: "0px 3px 180px #A8ACFF",
    }}>
      <Typography className={classes.mainTitile} >WASH/DRY/FOLD</Typography>

      <CardContent >
        <Grid container style={{ marginBottom: "4rem" }}>
          <Grid container item alignItems="center" justifyContent="center">
            <div className={classes.bodyContents}>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.heading}>
                {/* WASH/DRY/FOLD - HERE'S WHAT YOU GET! */}
                HERE'S WHAT YOU GET!
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subHeading} >
                Free Pickup and Next Day Delivery!
              </Grid>

              <Grid container item
                 style={{ marginTop: "2rem", marginBottom: "2rem" }}
                alignItems="center" justifyContent="center"
                lg={12} md={12} sm={12} xs={12}>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography style={{
                    textTransform: "none",
                    color: "#3d3d3d",
                    // fontWeight: "bold",
                    fontSize: "1rem",
                    textAlign: "center",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                    marginBottom: "1rem",
                  }}><span
                  style={{ fontSize:"1.7rem" }}
                    >Note: </span>All the features included below can be customized to meet your exact needs!. As a example:
                  </Typography>
                </Grid>

                <Grid item lg={"auto"} md={"auto"} sm={"auto"} xs={"auto"} >
                    {itemList?.map(item => (
                      <ListItem key={item.id} style={{ padding: "0" }}>
                        <ListItemIcon>
                          <PlayCircleFilledIcon style={{ color: 'orange' }} />
                        </ListItemIcon>
                        <ListItemText primary={item?.name} />
                      </ListItem>))}
                </Grid>

                </Grid>

              <Grid container item alignItems="center" justifyContent="center">

                <Typography style={{
                  textTransform: "none",
                  color: "#3d3d3d",
                  // fontWeight: "bold",
                  fontSize: "1rem",
                  textAlign: "center",
                  paddingLeft: "0.5rem",
                  paddingRight: "0.5rem",
                }}> Just use your notes when checking out!</Typography>
              </Grid>

              <Grid item style={{ marginTop: "2rem", }}>
                <Typography
                  style={{ textTransform: "none", color: "#656DFF", fontWeight: "bold", fontSize: "1.6rem" }}
                  variant="contained"
                >{`$${washDry?.price?.toFixed(2)} per lb Min. 15 lbs Per Order`}</Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>

        <WashDryFoldCard />
      </CardContent>
    </Card>
  );
}

export function DryCleanDisplay({ dryClean }) {
  const classes = useStyles();


  const getTops = dryClean.filter((item) => item.category === "Tops");
  const getBottoms = dryClean.filter((item) => item.category === "Bottoms");
  const getFullBody = dryClean.filter((item) => item.category === "Full Body");
  const getOther = dryClean.filter((item) => item.category === "Other");

  return (
    <Card className={classes.root}
      style={{
        border: "0.2rem dashed #A8ACFF",
        borderRadius: "0.8rem",
        boxShadow: "0px 3px 180px #A8ACFF",
      }} >
      <Typography className={classes.mainTitile}
        style={{ marginBottom: "2rem", }} >
        Dry Cleaning </Typography>
      <Grid container >

        <Grid container item lg={12} justifyContent="center" spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getTops} category={"Tops"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getBottoms} category={"Bottoms"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getFullBody} category={"FullBody"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getOther} category={"Other"} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export function Alternation({ alterations }) {
  const classes = useStyles();

  const getTops = alterations.filter((item) => item.category === "Tops");
  const getBottoms = alterations.filter((item) => item.category === "Bottoms");
  const getFullBody = alterations.filter((item) => item.category === "Full Body");
  const getOther = alterations.filter((item) => item.category === "Other");

  return (
    <Card className={classes.root}
      style={{
        border: "0.2rem dashed #656DFF",
        borderRadius: "0.8rem",
        boxShadow: "0px 3px 180px #A8ACFF",
      }} >
      <Typography className={classes.mainTitile}
        style={{ marginBottom: "2rem", }} >
        Alterations </Typography>
      <Grid container >

        <Grid container item lg={12} justifyContent="center" spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getTops} category={"Tops"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getBottoms} category={"Bottoms"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getFullBody} category={"FullBody"} />
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <ProductCategoryCard dataList={getOther} category={"Other"} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
