import React, { useEffect } from "react";
import { logout } from "../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress'; 
import { Grid } from "@material-ui/core";


const Logout = () => {
    let dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(logout());
        navigate("/");
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Grid container >
                <Grid container item
                    alignItems="center"
                    justifyContent="center"
                >

                    <Grid item
                     style={{
                         marginTop: "15rem",
                         marginBottom: "15rem",
                         
                    }}
                    >
                        <CircularProgress />
                    </Grid>

                </Grid>
            </Grid>
        </>
    )
}

export default Logout