import React from "react";
import { Grid, Card, CardContent, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import styles from "../../styles/FAQ.module.scss";
import useFaqFunctions from "functions/useFaqFunctions";

const useStyles = makeStyles((theme) => ({
  button: { background: "#2482AE", width: "100%", color: "#FFFFFF", textTransform: "none", textAlign: "left", display: "block", fontSize: "0.9rem",
    "&:hover": { background: "#074f70", }, },
  title: { paddingLeft:"1rem", fontWeight: "300", fontFamily: "Lato,sans-serif", fontSize: "2rem", color: "#FFFFFF", background: "#35BFFF" },
  outsideCard: { paddingBottom:"4rem", },
  insideCard: { paddingLeft:"2.5rem", paddingRight:"2rem",
    [theme.breakpoints.down("xs")]: { paddingLeft:"0.9rem", paddingRight:"0rem", paddingBottom:0, }, },
  card: { marginTop: "5rem", maxWidth: "75%", },
}));

export default function FAQ() {
  const classes = useStyles();
  
  const { handleClickServiceZone, handleClickSendOrders, handleClickHaveGarments, handleClickDryCleaning, handleClickDryCleaner, handleClickFirstOrder,
    handleClickDeliver, handleClickTip, handleClickPickup, handleClickGift, handleClickWeddingDress, handleClickReferralProgram, handleClickAlterationsWork,
    handleClickSpecialCare, handleClickRescheduleAnOrder, handleClickHoursOfOperation, handleClickPayment, handleClickLoadWeighed, handleClickDeliveryOrPickUp,
    handleClickFabricSoftener, handleClickDamagedOrMissing, handleClickProcessingFee, handleClickDryCleaningItems, handleClickMyLaundryBag, openServiceZone,
    openSendOrders, openHaveGarments, openDryCleaning, openDryCleaner, openFirstOrder, openDeliver, openTip, openPickup, openGift, openWeddingDress, openReferralProgram,
    openAlterationsWork, openSpecialCare, openRescheduleAnOrder, openHoursOfOperation, openPayment, openLoadWeighed, openDeliveryOrPickUp, openFabricSoftener, openDamagedOrMissing,
    openProcessingFee, openDryCleaningItems, openMyLaundryBag } = useFaqFunctions(); 

  return (
    <Grid container justifyContent="center" className={classes.outsideCard} >
    <Grid container item justifyContent="center" lg={8} md={11} sm={12} xs={12} >
      <Card className={classes.card}> 
      <CardHeader titleTypographyProps={{variant:'h1' }} className={classes.title} classes={{ title: classes.title }} title="FAQ" />
        <CardContent className={classes.insideCard} >
          <Grid container spacing={2} item xs={12} md={12} xl={12}>
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickServiceZone} variant="contained" > Service Zones </Button> </Grid>
            {openServiceZone && ( <Grid item xs={12} md={12} xl={12}>
                <p className={styles.HeadParagraph}> We have recently expanded! We are currently servicing Boston and Los Angeles! See below for details on both awesome cities! </p>
                <p className={styles.HeadParagraph}>For the greater LA area:</p>
                <p className={styles.paragraph}> Baldwin Hills, Bel Air, Beverly Glen, Beverly Hills, Beverlywood, Brentwood, Burbank, Century City, Cheviot Hills,
                  Culver City, Downtown (almost all of it!), East LA, Echo Park, El Segundo, Fox Hills, Gardena, Glendale, Hermosa Beach, Hawthorne, Highland Park, Huntington Park, Hollywood, Holmby Hills, Inglewood, Koreatown, Ladera Heights, LAX, Los Feliz,
                  Loyola Marymount University, Lynwood, Manhattan Beach, Mar Vista, Marina del Rey, Maywood, Mid-City, Mid-Wilshire, Miracle Mile, Pacific Palisades, Pico-Robertson, Playa del Rey, Redondo Beach, Santa Monica, Sherman Oaks, Silver Lake,
                  Studio City, Toluca Lake, UCLA, Universal City, USC, Van Nuys, Venice, West Hollywood, West LA, Westchester, and Westwood </p>
                <p className={styles.HeadParagraph}> For the greater Boston area: </p>
                <p className={styles.paragraph}> All of the usual suspects (great movie!) - South Boston, South End, North End, Downtown, Back Bay, Beacon Hill, Fenway,
                  Mission Hill, pretty much ALL of the colleges all over the area (yay!) and all of (get ready...deep breath... its a lot....) Arlington, Allston, Babson College, Belmont, Brighton, Braintree, Brookline, Cambridge, Chelsea,
                  Charlestown, Chestnut Hill, Dedham, Dorchester, Everett, Hyde Park, Jamaica Plain, Logan Airport, Malden, Mattapan, Medfield, Medford, Melrose, Milton, Natick, Needham, Newton, Norwood, Randolph, Revere, Roslindale, Roxbury, Quincy,
                  Somerville, Walpole, Waltham, Watertown, Wellesley, West Roxbury, Westwood, Winchester, and Winthrop Massachusetts. Phew! I am out of breath!! </p>
                <p className={styles.paragraph}> When you sign up for your account, the site will automatically work its magic and let you know if we service your area. </p>
                <p className={styles.paragraph}> If not, though, email alex@LifeWithoutLaundry.com and ask if we can service you – we’re always expanding! (And sometimes our magic mapping technology may be not so magic and malfunctioning. So never hurts to ask!) </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickSendOrders} variant="contained" > Can I send more than the minimum / How does billing work? </Button> </Grid>
            {openSendOrders && ( <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}>Yes!!</p>
                <p className={styles.paragraph}> When you send out your orders, you're more than welcome to! In fact, send us your whole wardrobe! Why do it yourself? </p>
                <p className={styles.paragraph}> The minimum is just in place so that we don’t end up coming to you for just one sock because it’s your lucky sock and you
                  have a date this weekend! I mean we could only come for that lucky sock but you’re going to have to pay for AT LEAST the minimum that’s all. 🙂 </p>
                <p className={styles.paragraph}>How it works is this:</p>
                <p className={styles.paragraph}> We come and pick up your laundry, and later on we'll weigh it at our facility. </p>
                <p className={styles.paragraph}> We'll mark the weight down, and you'll get an email invoice letting you know how much your laundry weighed after order
                  charged and closed out (this can happen anytime within 24-48 hours after it’s picked up and please note you will not be notified before it is delivered.). </p>
                <p className={styles.paragraph}> Your credit card on file will get charged for the total weight multiplied by the per lb rate of 2.50 per lb. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickHaveGarments} variant="contained" > What if I have garments that need delicate washes or other special treatment (cannot be dried etc...) hung etc? </Button> </Grid>
            {openHaveGarments && ( <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> By default we wash all clothes in cold water and we dry all clothes on MED heat. </p>
                <p className={styles.paragraph}> If you have a garment that needs a delicate wash cycle, air drying, etc... Please separate out and use your notes online to dictate so when checking out. </p> 
                <p className={styles.paragraph}> It is 100% up to the customer to bag these specialty items up in their own bag so it is clear what is delicate / air dry and
                  what is normal dry. The most common note we see is simply "Please air dry the small plastic bag inside my main laundry bag."! Then you bag up the air dry stuff in their own bag and toss that right in the main laundry bag. We will open it up at base and know exactly what needs to be done! </p>
                <p className={styles.paragraph}> Please note the wash dry fold product does not include any hanging of clothes when returned. Everything is folded. If you
                  want things hung you would have to pay for the price listed under the dry cleaning tab and also make sure the items are put in a separate bag from regular wash dry fold. Any questions always feel free to ask! Thanks for your understanding! </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDryCleaning} variant="contained" > How does Dry Cleaning work? (#1 Rule: Make sure always in separate bag. Never in same bag, or in a blue bag at all, with Wash dry Fold) </Button> </Grid>
            {openDryCleaning && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Dry Cleaning works very similiar to our Wash. Dry. Fold. procedure. The only difference is the earliest we can get dry cleaning back to you is the fourth day as opposed to next day delivery for wash dry fold. </p>
                <p className={styles.paragraph}> Visit our "Services" page to view what we offer. </p> 
                <p className={styles.paragraph}> We individually check every garment in detail before dry cleaning. So you can rest assured that all your stains will be identified and treated! </p>
                <p className={styles.paragraph}> However if you are like me and a bit of a "worrywart" (great term haha) then you can feel free to note any special instructions on the confirmation page of your order under "special instructions" and we will be sure to follow them. </p>
                <p className={styles.paragraph}> When you are ready, just login, click "Start My Order Now", and you will be rocking and rolling! </p>
                <p className={styles.paragraph}> Four small notes: If you ever include dry cleaning with a laundry order but you do not put any dry cleaning your services when you place the order online, you MUST contact us immediately to let us know we are picking up dry cleaning in
                  addition to laundry and to setup a delivery for the dry cleaning as the system will think you just have laundry only. </p>
                <p className={styles.paragraph}> Also by default we use light starch on clothes. If you would like heavier starch or no starch at all just include in your "special instructions" when checking out online! </p>
                <p className={styles.paragraph}> A heads up if you are sending out leather / fur items or rugs. These items must be sent to a specialist. Only a few companies in CA/MA process these properly and we have partnered with one of the best. However due to the careful procedure needed and
                  the transit times, please be aware the turn around time may be up to two weeks and to please call us to setup a special delivery for these. The website is not smart enough to know that these items require special attention and won't know to delay your delivery accordingly. </p>
                <p className={styles.paragraph}> Lastly when sending out dry cleaning, use either the red dry cleaning bag we provide or a trash bag. Please NEVER use the teal or blue lwl bags used for laundry (to avoid confusion never put dry cleaning in a blue bag). Also NEVER put laundry and dry cleaning in the same bag. Separate bags pls! 🙂 This avoids any confusion. Thank you! </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDryCleaner} variant="contained" > What makes you a “green” dry cleaner? </Button> </Grid>
            {openDryCleaner && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> We basically don’t use any of the standard chemicals. It's a “wet” cleaning method. We identify what kind of stain / the ph level of the stain. Water-based stains, which make up the majority of the stains we see, generally come out with the standard wet-cleaning process. The remaining stains are oil-based and can be removed using specialized water-based pre-spotting solutions. </p>
                <p className={styles.paragraph}> Because wet cleaning is free of VOCs, it eliminates health and safety risks, as well as environmental risks associated with traditional dry cleaning. As an added benefit, the equipment and operating costs are lower. Thats a quick overview of what we do! Hope that helps </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}>
              <Button className={classes.button} onClick={handleClickFirstOrder} variant="contained" > What kind of bag do I use for my first order? </Button>
            </Grid>
            {openFirstOrder && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Trash bags are best for your first order! Then we will toss those and return back to you one of reusable bags for future use! Please make sure if you have both dry cleaning and laundry that they are always in separate bags. Also if you do not have trash bags, any kind of bags are fine, if they aren't disposable they will be returned to you along with our reusable bag. Trash bags are just easiest for us 🙂, </p>
              </Grid> )} 
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDeliver} variant="contained" > When do you pickup and deliver? </Button> </Grid>
            {openDeliver && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> We are open 7 days a week. We have unattended services. Also we have 10-12, 12-2, and 7-10 pm attended time slots to choose from everyday! Always no charge for pickup and delivery 🙂. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickTip} variant="contained" > What is the option when checking out to "Add a little something extra" / How do I tip? </Button> </Grid>
            {openTip && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> It is very expensive to run a business (taxes, fees, expenses, etc). This area simply gives the customer the option to give a little bit extra to the company if you are enjoying the service and if you appreciate us. Nothing required at all but
                  anything extra will always go towards the companies ability to continue doing a wonderful job. To be clear, this is not a tip for the driver, all tips for drivers must be given in cash at the time of pickup and or delivery. Thanks for being an awesome LWL'er and an awesome person! </p>
              </Grid>  )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickPickup} variant="contained" > What if I have a group pickup? </Button> </Grid>
            {openPickup && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> It is very expensive to run a business (taxes, fees, expenses, etc). This area simply gives the customer the option to give a little bit extra to the company if you are enjoying the service and if you appreciate us. Nothing required at all but anything extra will always go towards the companies ability to continue doing a wonderful job. To be clear, this is not a tip
                  for the driver, all tips for drivers must be given in cash at the time of pickup and or delivery. Thanks for being an awesome LWL'er and an awesome person! </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickGift} variant="contained" > How can I purchase a gift card for your services? </Button> </Grid>
            {openGift && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Simply call us at 617-768-7779 between the hours of 10am-10pm, 7 days a week and we will be happy to take care of that for
                  you! Gift cards cover all laundry and dry cleaning charges. Please note that the $5.95 processing fee would be separate
                  from gift card. But all laundry or dry cleaning charges are fully covered ! </p> </Grid> )} 
            <Grid item xs={12} md={12} xl={12}>
              <Button className={classes.button} onClick={handleClickWeddingDress} variant="contained" > Why is Life Without Laundry the best Wedding Dress cleaner in the world? </Button>
            </Grid>
            {openWeddingDress && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> A wedding gown is one of the most important and cherished articles of clothing ever purchased by anyone. It is the representation of a dream come true, and if you wish to pass that gown along to a daughter, sister, cousin, or friend someday, take proper care of it now. The question is, what do you do and when do you do it? </p>
                <p className={styles.paragraph}> That answer is easy. Let the experts do it! Life Without Laundry are experts! </p>
                <p className={styles.paragraph}> Gowns should be carefully cleaned with all stains being removed. Then, each article - gown, headpiece, veil, train or anything else you wish to keep- should be individually wrapped in acid free tissue and stored in an acid free box. </p>
                <p className={styles.paragraph}> This cleaning is best done as soon as possible after the wedding, but very often time, after such an exciting event, the gown cleaning gets pushed back more and more. Sometimes it is pushed back months or even many years. Don't despair! Life Without Laundry can't always work miracles, but we come pretty darn close on even the toughest, dirtiest gowns! </p>
                <p className={styles.paragraph}> After cleaning, we will preserve your gown in our exclusive 100% acid free archival chest. We also use 100% acid free tissue to keep your gown from having permanent creases and folds. The chest also has an acid free window so you can view your gown while it is safely boxed in storage. </p>
                <p className={styles.paragraph}> Let's clean and preserve that beautiful dress! And congratulations!! 🙂 </p>
                <p className={styles.paragraph}> Wedding dress processing times can vary from 2-4 weeks on average depending on how intricate dress is and how long it takes to get our custom preservation boxes ordered ! </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickReferralProgram} variant="contained" > Do you have a referral program? </Button> </Grid>
            {openReferralProgram && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}>Yes!!</p> 
                <p className={styles.paragraph}> If someone you refer places an order online, then emails alex@lifewithoutlaundry.com, letting us know that they were referred by you, you get a $10 off coupon and they get a $5 off coupon! </p>
                <p className={styles.paragraph}> It's that easy! And thanks in advance for referring people. We don't do much advertising. We just let all you awesome satisfied LWL'ers spread the word! 🙂 </p>
              </Grid> )} 
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickAlterationsWork} variant="contained" > How do Alterations work? </Button> </Grid>
            {openAlterationsWork && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}>Very simple.</p> 
                <p className={styles.paragraph}> Want a button replaced? Need your pants shortened? Just write what you would like done in the "Special Instructions" box on your order confirmation page and we will be sure to follow these to the "T". </p>
                <p className={styles.paragraph}> All alteration prices are subject to change if materials need to be special ordered. But you will always be notified before we get rolling and as a result can cancel any order if you change your mind for a full refund! </p>
              </Grid> )} 
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickSpecialCare} variant="contained" > What items are "Special Care" items for wash/dry/fold and how much extra are these items? </Button> </Grid>
            {openSpecialCare && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Here are the additional charges: (they are all per item sent out...so if you send out 2 bathmats..."hang on....carry the 1..." you will pay $5 extra) </p> 
                <p className={styles.paragraph}> Bathmats/Sweaters (heavy wool)/Jackets (heavy ones like winter) = $2.50 </p>
                <p className={styles.paragraph}> Blankets (not sheets)/Bathrobes/Pillows = $5 </p>
                <p className={styles.paragraph}> Pillows do not have a special charge. But some pillows can become deformed after being washed. So these items are done at the customers own risk. </p>
                <p className={styles.paragraph}> Lastly we separate all whites and colors of course. However if you need an item washed COMPLETELY separate from the normal clothes there will be a extra washer charge of $3.75. Again that will NOT apply to normal separation of whites and colors which requires 2 machines. This is ONLY if you request a 3rd machine because item/s need to be washed completely separate. </p>
                <p className={styles.paragraph}> You will receive a "Special Care" email notification if you send any of these items out. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickRescheduleAnOrder} variant="contained" > How do I reschedule an order I placed? </Button> </Grid>
            {openRescheduleAnOrder && (
              <Grid item xs={12} md={12} xl={12}> <p className={styles.paragraph}> To reschedule an order please call, text, or email us! Info found on contact page 🙂. ( Text preferred ! ) </p> </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickHoursOfOperation} variant="contained" > Hours of operation </Button> </Grid>
            {openHoursOfOperation && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> All service is handled through online accounts and credit card services. We don’t take phone orders, and our drivers are never allowed to handle cash intended for the payment of services. Everything is much more secure this way. </p>
                <p className={styles.paragraph}> Attended pick-ups / deliveries are in four scheduling blocks between 10:00am - 12:00pm, 12:00pm - 2:00pm, and 7:00pm - 10:00pm (for all you night owls). Unattended pick-ups / deliveries occur anytime between 9 and 9 pm. </p>
                <p className={styles.paragraph}> Note: Please if choosing “unattended” make sure your laundry is ready for pick-up by 9 am! </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickPayment} variant="contained" > Payment Options </Button> </Grid>
            {openPayment && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> All service is handled through online accounts and credit card services. We don’t take phone orders, and our drivers are never allowed to handle cash intended for the payment of services. Everything is much more secure this way. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickLoadWeighed} variant="contained" > Where and when is my load weighed? </Button> </Grid>
            {openLoadWeighed && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> As soon as your laundry is brought to our facility it is sorted and weighed. We check and test our scales daily to ensure we are providing the most accurate measurements possible. </p>
              </Grid> )} 
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDeliveryOrPickUp} variant="contained" > What if I'm not there during a delivery or a pick up? </Button> </Grid>
            {openDeliveryOrPickUp && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Well, you know how the meter maids are =) </p>
                <p className={styles.paragraph}> Really though, we gotta run a tight ship in order to keep on schedule!! </p>
                <p className={styles.paragraph}> If you schedule an attended pickup (unattended you don't need to be home!), our drivers will try buzzing (if applicable) and call at least twice when they're there. If you're not around, though, we'll have to keep moving so we avoid the crazy parking tickets! </p>
                <p className={styles.paragraph}> That said, if we do not hear from you (prior to your pickup or delivery) and are unable to get in touch with you $5 (per occurrence) will be added onto the order. So if you're not there for pickup, have to reschedule, and then not there for delivery and have to reschedule that would be a $10 extra driving fee for that specific order. </p>
                <p className={styles.paragraph}> To offer a great service we need your help! So please, for both our sanity, just show up =) </p>
              </Grid>  )}
            <Grid item xs={12} md={12} xl={12}>
              <Button className={classes.button} onClick={handleClickFabricSoftener} variant="contained" > What is "Individual Garment Stain Removal" or "Custom Detergent / Fabric Softener"? </Button>
            </Grid>
            {openFabricSoftener && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> Individual Garment Stain Removal means that our attendants will pre treat any tough stains on garments sent out. However it is the customer's responsibility to include in their "Order Special Instructions" any garments that need this type of special treatment. </p>
                <p className={styles.paragraph}> Custom Detergent / Fabric Softener means simply that you can send out your own detergent and or fabric softener and we will use it on your order. The bottle/s will then be returned with your order. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDamagedOrMissing} variant="contained" > What if my garments are damaged or missing? </Button> </Grid>
            {openDamagedOrMissing && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> If you have any claims of damaged or missing garments, or you are not 100% satisfied with our service, please contact 617-768-7779 within 24 hours of your scheduled delivery time and we will do everything possible to resolve the matter. For
                  any specific questions about our policies, please review our terms and conditions. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickProcessingFee} variant="contained" > What's this "processing fee"? </Button> </Grid>
            {openProcessingFee && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> While pickup and delivery is FREE... This fee simply helps us with a few of our “operating costs”. The most notable being our ongoing battle against the meter maids. We all LOVE the city but it does get expensive when you’re continuously parking 150+ times a day. So this helps us out a tincy wincy bit with that and a few other lovely costs! But do not fret my LWL friends, it’s only $5.95 per order and it is shared equally by ALL LWL customers! 🙂 </p>
                <p className={styles.paragraph}>PS</p>
                <p className={styles.paragraph}> So far the meter maids are winning. They are like stealthy ninjas. Maybe they are stealthy ninjas.... Darn the cities and their stealthy ninja hiring! </p>
              </Grid> )} 
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickDryCleaningItems} variant="contained" > What if some of my Dry Cleaning items have a lot of cat hair or lint on them? </Button> </Grid>
            {openDryCleaningItems && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> If a dry cleaning item has excessive hair or lint on it which would not come out during normal cleaning, then the item must be "de linted" before cleaning. This is not only so we return the item looking its best for you! But also so the hair or lint doesn't clog our machines when cleaning. </p>
                <p className={styles.paragraph}> We can do this no problem but it does take time and therefore there may be an extra charge of $5-15 (depending on how much hair or lint there is) added to the order. </p>
                <p className={styles.paragraph}> You would receive an email receipt if this was the case. </p>
              </Grid> )}
            <Grid item xs={12} md={12} xl={12}> <Button className={classes.button} onClick={handleClickMyLaundryBag} variant="contained" > What if I lose or damage my laundry bag? </Button> </Grid>
            {openMyLaundryBag && (
              <Grid item xs={12} md={12} xl={12}>
                <p className={styles.paragraph}> No problem! We just need a formal written apology, a few gold bars, and some flowers. Kidding, kidding. </p>
                <p className={styles.paragraph}> Really though, just email us at </p>
                <Button className={classes.button} variant="contained" > support@lifewithoutlaundry.com </Button>
                <p className={styles.paragraph}> , and we’ll send you out a new bag. If you become a "repeat lost bag offender" we will have to charge you $3 a bag. But the first replacement is on us! </p>
                <p className={styles.paragraph}> But if you feel like sending us flowers, you are more than welcome to! </p>
              </Grid> )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    </Grid>
  );
}
