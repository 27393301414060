import { Button, Grid, Paper } from '@material-ui/core';
import React from 'react';
import { useStyles } from '../../utils/styles/styles';
import { useNavigate } from 'react-router-dom';

export default function PermissionDenied() {
  const classes = useStyles();
  let history = useNavigate();

  return (
    <Grid className={classes.errorRoot}>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={8}>
          <Paper className={classes.paperCard} elevation={2}>
            <h2>Not Authorized.</h2>
            <h4 style={{marginTop:"10px"}}>You are not authorized to view the requested page. You can click your back button or go back to our <a href='/'>homepage</a>. <br></br>We apologize for the inconvenience.</h4>
            <Button
              style={{backgroundColor:"#0073A8"}}
              variant='contained'
              className={classes.errorButton}
              onClick={() => history('/')}
            >
              Go Back
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}
